export const curveNames = {
  PROFILE_VIEW: "PROFILE_VIEW",
  PROFILE_FOLLOW: "PROFILE_FOLLOW",
  INVITATION_SENT: "INVITATION_SENT",
  MESSAGE_SENT: "MESSAGE_SENT",
  INMAIL_SENT: "INMAIL_SENT",
  EMAIL_SENT: "EMAIL_SENT",
  INVITATION_ACCEPTED: "INVITATION_ACCEPTED",
  MESSAGE_REPLY: "MESSAGE_REPLY",
  INVITATION_ACCEPTED_RATE: "INVITATION_ACCEPTED_RATE",
  MESSAGE_REPLY_RATE: "MESSAGE_REPLY_RATE",
  EMAIL_OPENED: "EMAIL_OPENED",
  EMAIL_CLICKED: "EMAIL_CLICKED",
  EMAIL_OPEN_RATE: "EMAIL_OPEN_RATE",
  EMAIL_CLICK_RATE: "EMAIL_CLICK_RATE",
  EMAIL_VERIFIED: "EMAIL_VERIFIED",
  BOUNCE_RATE: "BOUNCE_RATE",
}

const FILTER_BY_REPORT_TYPES = tt => [
  {
    name: tt("linkedin"),
    type: "LINKEDIN",
  },
  {
    name: tt("email"),
    type: "EMAIL",
  },
  {
    name: tt("data-enrichment"),
    type: "ENRICHMENT",
  },
]

const statisticsCurves = tt => {
  const reportFilter = FILTER_BY_REPORT_TYPES(tt)
  return {
    PROFILE_VIEW: {
      ID: 1,
      color: "--text-data-01",
      label: tt("views"),
      key: "views",
      channel: [reportFilter[0].type],
    },
    PROFILE_FOLLOW: {
      ID: 2,
      color: "--text-data-02",
      label: tt("follows"),
      key: "follows",
      channel: [reportFilter[0].type],
    },
    INVITATION_SENT: {
      ID: 3,
      color: "--text-data-03",
      label: tt("connections-sent"),
      key: "connectionsSent",
      channel: [reportFilter[0].type],
    },
    MESSAGE_SENT: {
      ID: 4,
      color: "--text-data-05",
      label: tt("messages-sent"),
      key: "messagesSent",
      channel: [reportFilter[0].type],
    },
    INMAIL_SENT: {
      ID: 5,
      color: "--text-data-06",
      label: tt("in-mails-sent"),
      key: "inMailsSent",
      channel: [reportFilter[0].type],
    },
    EMAIL_SENT: {
      ID: 10,
      color: "--text-data-07",
      label: tt("emails-sent"),
      key: "emailsSent",
      channel: [reportFilter[1].type],
    },
    INVITATION_ACCEPTED: {
      ID: 6,
      color: "--text-data-08",
      label: tt("connections-accepted"),
      key: "connectionsAccepted",
      channel: [reportFilter[0].type],
    },
    MESSAGE_REPLY: {
      ID: 7,
      color: "--text-data-09",
      label: tt("replies-received"),
      key: "repliesReceived",
      channel: [reportFilter[0].type, reportFilter[1].type],
    },
    MESSAGE_REPLY_RATE: {
      ID: 9,
      color: "--text-data-10",
      label: tt("response-rate"),
      percentage: true,
      key: "responseRate",
      channel: [reportFilter[0].type, reportFilter[1].type],
    },
    INVITATION_ACCEPTED_RATE: {
      ID: 8,
      color: "--text-data-01",
      label: tt("acceptance-rate"),
      percentage: true,
      key: "acceptanceRate",
      channel: [reportFilter[0].type],
    },

    EMAIL_OPENED: {
      ID: 11,
      color: "--text-data-02",
      label: tt("email.opened"),
      key: "emailOpened",
      channel: [reportFilter[1].type],
    },
    EMAIL_CLICKED: {
      ID: 12,
      color: "--text-data-03",
      label: tt("email.clicked"),
      key: "emailClicked",
      channel: [reportFilter[1].type],
    },
    EMAIL_REPLY_RATE: {
      ID: 13,
      color: "--text-data-08",
      label: tt("response-rate"),
      percentage: true,
      hide: true,
      channel: [reportFilter[1].type],
    },
    EMAIL_OPEN_RATE: {
      ID: 14,
      color: "--text-data-04",
      label: tt("email-open-rate"),
      percentage: true,
      key: "emailOpenRate",
      channel: [reportFilter[1].type],
    },
    EMAIL_CLICK_RATE: {
      ID: 15,
      color: "--text-data-05",
      label: tt("email-click-rate"),
      percentage: true,
      key: "emailClickRate",
      channel: [reportFilter[1].type],
    },
    EMAIL_VERIFIED: {
      ID: 16,
      color: "--text-data-06",
      label: tt("email-verified"),
      key: "emailVerified",
      channel: [reportFilter[2].type],
    },
    BOUNCE_RATE: {
      ID: 17,
      color: "--text-data-07",
      label: tt("bounce-rate"),
      percentage: true,
      key: "bounceRate",
      channel: [reportFilter[1].type],
    },
  }
}
const statisticsCurveConstants = tt => ({
  statisticsCurves: statisticsCurves(tt),
  FILTER_BY_REPORT_TYPES: FILTER_BY_REPORT_TYPES(tt),
})

export default statisticsCurveConstants

export const getStatisticsObject = (tt, statisticsID) => {
  const statisticsCurvesConstant = statisticsCurves(tt)
  for (const curve in statisticsCurvesConstant) {
    if (statisticsCurvesConstant[curve] && statisticsCurvesConstant[curve].ID === +statisticsID) {
      return statisticsCurvesConstant[curve]
    }
  }
}
