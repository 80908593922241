import "./AllIconsPage.scss"

import { mainIcons, SvgIcon } from "@skylead/component-library"
import React from "react"

import LangText from "../../atoms/LangText"

const AllIconsPage = () => {
  return (
    <div className="all-icon-table">
      <h1>
        <LangText langKey="all.icons" />
      </h1>

      <h2>
        <LangText langKey="main.icons" />
      </h2>
      <table>
        <thead>
          <th>
            <LangText langKey="icon" />
          </th>
          <th>
            <LangText langKey="name" />
          </th>
        </thead>
        <tbody>
          {Object.keys(mainIcons).map(icon => (
            <tr>
              <td>
                <SvgIcon key={icon} icon={icon} style={{ width: 30, height: 30, margin: 10 }} />
              </td>

              <td>{icon}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AllIconsPage
