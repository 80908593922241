import { FooterVariant, PromptVariant } from "@skylead/component-library"
import moment from "moment-timezone"
import queryString from "query-string"
import React from "react"

import { parse, stringify } from "../../components/pages/CreateCampaignInfoPage/linkedin-url-parser"
import {
  hasFirstConnections,
  hasGroupConnections,
  hasSecondConnections,
  hasThirdConnections,
  returnConnectionDegree,
  setFirstConnections,
  setGroupConnections,
  setSecondConnections,
  setThirdConnections,
} from "../../components/pages/CreateCampaignInfoPage/query-manipulator"
import {
  AB_TESTING_ELEMENTS,
  CAMPAIGN_DASHBOARD,
  CAMPAIGN_TYPE,
  defaultAutoReuseInterval,
  defaultHoursIfEmailOpened,
} from "../../constants/campaign-constants"
import { basicLinkedinLink } from "../../constants/linkedin-link-constants"
import * as actions from "../../redux/actions"
import { store } from "../../redux/store"
import dataUtils from "../data/data-utils"
import {
  determineCampaignType,
  recognizedBasicConnection,
} from "../linkedin-url/linkedin-url-utils"
import loggerUtils from "../logger-utils"
import {
  checkAndChangeTagReplacements,
  complexStepsSubmit,
  createCampaignSteps,
  hasBigChangeInLink,
  parseComplexReplyToSameThread,
  parseSimpleReplyToSameThread,
} from "../step/step-utils"
import {
  validateFileSize,
  validateManageCampaign,
  validateScheduleCampaign,
} from "../validation/validation-utils"
// eslint-disable-next-line import/no-self-import
import * as selfImport from "./campaign-utils"

const getInitialStateData = (step = {}, hideAdditionalData) => {
  const { activeAccountID } = store.getState().account
  const teamAccounts = store.getState().teamManagement.teamAccounts.items

  const {
    onlyConnectViaEmail, // onlyConnectViaEmail flag is disabled on FE, make changes to source code accordingly
    inviteToConnectViaEmail,
    boostConnectViaEmail,
    ultraConnectBoost,
    allowPaidInMails,
  } = store.getState().forms.formData

  const days = [""]
  const hours = step.data?.conditionType === "ifEmailOpened" ? [defaultHoursIfEmailOpened] : [""]
  let isOpenedInviteViaEmailAccordion
  let isOpenedInviteToConnectAccordion
  let isActiveSwitchInviteViaEmailAccordion

  if (step.doAfterPreviousStep !== undefined) {
    days[0] = Math.trunc((step.doAfterPreviousStep || 0) / (24 * 60 * 60 * 1000))
    hours[0] = (step.doAfterPreviousStep - days * (24 * 60 * 60 * 1000) || 0) / (60 * 60 * 1000)
  }

  if (step.data && step.data.onlyConnectViaEmail) {
    isOpenedInviteToConnectAccordion = !step.data.onlyConnectViaEmail
  }

  // This is disabled on the FE part (invitation via email with a message).
  if (
    step.data &&
    step.data.connectMessageViaEmail &&
    step.data.connectMessageViaEmail.length > 1 &&
    !isOpenedInviteViaEmailAccordion &&
    !isOpenedInviteToConnectAccordion &&
    !isActiveSwitchInviteViaEmailAccordion
  ) {
    isOpenedInviteViaEmailAccordion = true
    isOpenedInviteToConnectAccordion = true
    isActiveSwitchInviteViaEmailAccordion = true
  }

  let data = {
    ...step.data,
    tagInfos: (step.data && step.data.tagInfo) || [[]],
    personalizedImageIds: (step.data && step.data.personalizedImageId) || [null],
    messages: (step.data && step.data.message) || [""],
    message: "" /** needed for backend validation */,
    subjects: (step.data && step.data.subject) || [""],
    signatures: (step.data && step.data.signature) || [""],
    signatureIds: (step.data && step.data.signatureId) || [""],
    useDefaultSignatures: (step.data && step.data.useDefaultSignature) || [true],
    emailType: (step.data && step.data.emailType) || "",
    sentEmailIfNotConnected: (step.data && step.data.sentEmailIfNotConnected) || false,
    discoverBusinessEmail: (step.data && step.data.discoverBusinessEmail) || undefined,
    outputs: step.outputs || [],
    businessEmails: (step.data && step.data.businessEmails) || true,
    personalEmails: (step.data && step.data.personalEmails) || true,
    inviteMessageOption: (step.data && step.data.personalEmails) || "option_a",
    connectMessageViaEmail: "",
    connectMessageViaEmails: (step.data && step.data.connectMessageViaEmail) || [""],
    onlyConnectViaEmail:
      onlyConnectViaEmail || (step.data && step.data.onlyConnectViaEmail) || false,
    inviteToConnectViaEmail:
      inviteToConnectViaEmail ||
      isActiveSwitchInviteViaEmailAccordion ||
      (step.data && step.data.inviteToConnectViaEmail) ||
      false,
    boostConnectViaEmail:
      boostConnectViaEmail || (step.data && step.data.boostConnectViaEmail) || false,
    ultraConnectBoost: ultraConnectBoost || (step.data && step.data.ultraConnectBoost) || false,
    allowPaidInMails: allowPaidInMails || (step.data && step.data.allowPaidInMails) || false,
    step: step.step || undefined,
  }

  if (!hideAdditionalData) {
    data = {
      ...data,
      action: step.action || "view",
      days,
      hours,
      selectedTabId: "1",
      editing: false,
      openImagePersonalizationModal: false,
      personalizedImageData: step.personalizedImageData || [{}],
      loadingMyTemplates: false,
      loadingDefaultTemplate: false,
      uploadLoading: false,
      isEmailSubjectWarningShawn: false,
      imagePersonalizationData: {
        items: [],
        selectedLayerIndex: null,
        currentMessage: "",
        tagInfo: [],
      },
      userImage:
        (
          (
            teamAccounts.find(user => user.id === Number(activeAccountID)) || {
              linkedinUser: {},
            }
          ).linkedinUser || {}
        ).picture ||
        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/600px-Circle-icons-profile.svg.png",
      customEmailError: false,
    }
  }

  return data
}

const getTime = (doAfterPreviousStep, field) => {
  const time = { days: 0, hours: 0 }
  if (doAfterPreviousStep !== undefined) {
    time.days = [Math.trunc((doAfterPreviousStep || 0) / (24 * 60 * 60 * 1000))]
    time.hours = [(doAfterPreviousStep - time.days * (24 * 60 * 60 * 1000) || 0) / (60 * 60 * 1000)]
  }
  return time[field]
}

const parseIncomingSteps = steps => {
  const ABGroupElements = []
  let n = 0
  let ABGroup = 0
  while (n < steps.length) {
    /**
     * Case not covered by any test.
     * Used for legacy campaigns.
     */
    if (typeof steps[n].id === "string" && steps[n].id?.includes("reactflow__edge")) {
      ABGroupElements.push(steps[n])
      n++
      // eslint-disable-next-line
      continue
    }

    if (steps[n].nextSteps && steps[n].nextSteps.length > 1) {
      /** If there is AB element after condition or AB element, multiple same nextSteps needs to be removed. */
      const nextStepsValues = [0]
      const newNextSteps = []
      for (let i = 0; i < steps[n].nextSteps.length; i++) {
        const currentStep = steps[n].nextSteps[i].step

        if (!nextStepsValues.includes(currentStep)) {
          nextStepsValues.push(currentStep)
          if (steps[n].nextSteps[i]?.step) {
            newNextSteps.push(steps[n].nextSteps[i])
          }
        }
      }

      steps[n].nextSteps = newNextSteps
    }

    if (steps[n].ABGroup && ABGroup === steps[n].ABGroup) {
      // eslint-disable-next-line
      const ABGroupElementIndex = ABGroupElements.findIndex(el => el.ABGroup === steps[n].ABGroup)
      ABGroupElements[ABGroupElementIndex] = {
        ...(ABGroupElements[ABGroupElementIndex] || {}),
        data: {
          ...getInitialStateData(steps[n]),
          ...(ABGroupElements[ABGroupElementIndex]?.data || {}),
          messages: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.messages || []),
            steps[n]?.data?.message,
          ],
          connectMessageViaEmails: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.connectMessageViaEmails || []),
            steps[n]?.data?.connectMessageViaEmail,
          ],
          signatures: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.signatures || []),
            steps[n]?.data?.signature,
          ],
          subjects: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.subjects || []),
            steps[n]?.data?.subject,
          ],
          signatureIds: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.signatureIds || []),
            steps[n]?.data?.signatureId,
          ],
          useDefaultSignatures: [
            ...(ABGroupElements[ABGroupElementIndex]?.data?.useDefaultSignatures || []),
            steps[n]?.data?.useDefaultSignature,
          ],
          tagInfos: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.tagInfos || []),
            steps[n]?.data?.tagInfo || [],
          ],
          personalizedImageIds: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.personalizedImageIds || []),
            steps[n]?.data?.personalizedImageId,
          ],
          personalizedImageData: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.personalizedImageData || []),
            steps[n]?.data?.personalizedImageData,
          ],
          hours: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.hours || []),
            getTime(steps[n]?.doAfterPreviousStep, "hours"),
          ],
          days: [
            ...(ABGroupElements[ABGroupElementIndex]?.data.days || []),
            getTime(steps[n]?.doAfterPreviousStep, "days"),
          ],
          message: undefined,
          connectMessageViaEmail: undefined,
          signature: undefined,
          subject: undefined,
          signatureId: undefined,
          useDefaultSignature: undefined,
          tagInfo: undefined,
          personalizedImageId: undefined,
        },
        replyToStep: null,
        ABTestingIds: [...(ABGroupElements[ABGroupElementIndex]?.ABTestingIds || []), steps[n].id],
      }
    } else {
      ABGroupElements.push({
        ...steps[n],
        data: {
          ...getInitialStateData(steps[n]),
          ...steps[n].data,
          messages: [steps[n].data?.message],
          signatures: [steps[n].data?.signature],
          subjects: [steps[n].data?.subject],
          signatureIds: [steps[n].data?.signatureId],
          useDefaultSignatures: [steps[n].data?.useDefaultSignature],
          connectMessageViaEmails: [steps[n].data?.connectMessageViaEmail],
          tagInfos: [steps[n].data?.tagInfo],
          personalizedImageIds: [steps[n].data?.personalizedImageId],
          personalizedImageData: [steps[n].data?.personalizedImageData],
          days: [getTime(steps[n]?.doAfterPreviousStep, "days")],
          hours: [getTime(steps[n]?.doAfterPreviousStep, "hours")],
          message: undefined,
          signature: undefined,
          subject: undefined,
          signatureId: undefined,
          useDefaultSignature: undefined,
          tagInfo: undefined,
          personalizedImageId: undefined,
          connectMessageViaEmail: undefined,
        },
        replyToStep: null,
        ABTestingIds: [steps[n].id],
      })
      if (steps[n].ABGroup) {
        ABGroup = steps[n].ABGroup
      }
    }
    n++
  }

  return ABGroupElements
}

const parseCampaignSteps = (campaignSteps = [], isStepsTree) => {
  const filteredCampaignSteps = campaignSteps.filter(step => Object.keys(step).length > 0)
  const newSteps = []
  let ABGroupCount = 0
  filteredCampaignSteps.forEach(campaignStep => {
    let overWriteID = true
    if (typeof campaignStep.id === "string" && campaignStep.id.includes("dndnode_")) {
      overWriteID = false
    }

    if (AB_TESTING_ELEMENTS.includes(campaignStep.action)) {
      if (campaignStep.data.messages.length > 1) {
        ABGroupCount += 1
      }
      campaignStep.data.messages.forEach((message, messageIndex) => {
        if (message.includes("{{personalizedImage}}")) {
          campaignStep.data.tagInfos[messageIndex].push({
            tag: "personalizedImage",
            replaceWith: "personalizedImage",
          })
        }

        let useDefaultSignature = campaignStep.data.useDefaultSignatures[messageIndex]
        let signatureId = campaignStep.data.signatureIds[messageIndex]
        if (useDefaultSignature === null) {
          useDefaultSignature = false
        }
        if (useDefaultSignature && signatureId) {
          signatureId = ""
        }

        newSteps.push({
          ...campaignStep,
          ABGroup: campaignStep.data.messages.length > 1 ? ABGroupCount : null,
          data: {
            ...campaignStep.data,
            message,
            connectMessageViaEmail: campaignStep.data.connectMessageViaEmails[0],
            signature: campaignStep.data.signatures[messageIndex],
            subject: campaignStep.data.subjects[messageIndex],
            signatureId,
            useDefaultSignature,
            tagInfo: campaignStep.data.tagInfos[messageIndex] || [],
            personalizedImageId: campaignStep.data.personalizedImageIds[messageIndex],
            messages: undefined,
            signatures: undefined,
            subjects: undefined,
            signatureIds: undefined,
            useDefaultSignatures: undefined,
            tagInfos: undefined,
            personalizedImageIds: undefined,
            days: isStepsTree ? campaignStep.data.days[messageIndex] : undefined,
            hours: isStepsTree ? campaignStep.data.hours[messageIndex] : undefined,
            personalizedImageData: undefined,
            connectMessageViaEmails: undefined,
          },
          id:
            overWriteID &&
            campaignStep.ABTestingIds &&
            campaignStep.ABTestingIds[
              messageIndex
            ] /** AB Steps merge multiple steps to one, this is necessary to save their ids */
              ? campaignStep.ABTestingIds[messageIndex]
              : campaignStep.id,
          doAfterPreviousStep: campaignStep.doAfterPreviousStep[messageIndex],
          days: undefined,
          hours: undefined,
          ABTestingIds: undefined,
          replyToStep: campaignStep.replyToStep ? campaignStep.replyToStep[messageIndex] : null,
        })
      })
    } else {
      newSteps.push({
        ...campaignStep,
        doAfterPreviousStep:
          campaignStep.doAfterPreviousStep && campaignStep.doAfterPreviousStep[0],
        data: {
          ...campaignStep.data,
          personalizedImageData: undefined,
          message: "",
          tagInfo: [],
          messages: undefined,
          signatures: undefined,
          subjects: undefined,
          signatureIds: undefined,
          useDefaultSignatures: undefined,
          tagInfos: undefined,
          personalizedImageIds: undefined,
          days: isStepsTree ? campaignStep.data.days[0] : undefined,
          hours: isStepsTree ? campaignStep.data.hours[0] : undefined,
          connectMessageViaEmail: "",
          connectMessageViaEmails: undefined,
        },
      })
    }
  })

  return newSteps
}

const getCreateCampaignRequestData = (additionalData = {}, getState) => {
  const { additionalVariables } = getState().campaign
  const { formData } = getState().forms

  const startAtTime = formData.startUTCTime
    ? moment
        .tz(
          `${formData?.startDate.format("DD MM YYYY")} ${formData?.startUTCTime}`,
          "DD MM YYYY HH:mm",
          moment.tz.guess(),
        )
        .utc()
        .valueOf()
    : 0
  const startAt = moment.unix(startAtTime).utc().valueOf() / 1000

  const data = {
    name: formData.name,
    checkTimestamp: formData.sendPrevious ? moment.utc().valueOf() : 0,
    campaignSteps: parseCampaignSteps(formData.campaignSteps),
    isFirstConnection: formData.isFirstConnection,
    getPersonalInfo: formData.getPersonalInfo,
    enableClickRate: formData.trackEmailsClicks,
    enableOpenRate: formData.trackEmailsOpens,
    onlyPremium: formData.dashboard === "import" ? undefined : formData.onlyPremium,
    onlyOpenInmail: formData.onlyOpenInmail,
    onlyDiscoverLeads: formData.onlyDiscoverLeads,
    onlyUniqueLeads: formData.onlyUniqueLeads,
    noPendingConnections: formData.noPendingConnections,
    onlyConnectViaEmail: formData.onlyConnectViaEmail,
    includeGloballyTargetedLeads: formData.includeGloballyTargetedLeads,
    boostConnectViaEmail: formData.boostConnectViaEmail,
    ultraConnectBoost: formData.ultraConnectBoost,
    allowPaidInMails: formData.allowPaidInMails,
    inviteToConnectViaEmail: formData.inviteToConnectViaEmail,
    textOnlyEmails: formData.textOnlyEmails,
    leadSourceTypes: [formData.campaignType],
    additionalFields: additionalVariables.map(tag => {
      return tag.tag
    }),
    startAt,
    ...additionalData,
  }

  if (formData.scheduleId) {
    data.scheduleId = formData.scheduleId
  }

  return data
}

const addLeadSources = data => {
  const { formData } = store.getState().forms
  const { draftCampaignId } = store.getState().campaign

  const leadSourceUrl =
    formData.dashboard && formData.dashboard !== "import" ? formData.campaignUrl : ""

  const leadSources = [
    {
      campaignId: draftCampaignId,
      leadSourceUrl,
      dashboard: Number(formData.realDashboard),
      leadSourceType: formData.campaignType,
      autoReuse: formData.autoReuse,
      autoReuseInterval: formData.autoReuseInterval,
    },
  ]

  let newData = { ...data, leadSources }

  if (formData.dashboard && formData.dashboard === "import") {
    const file = formData.file || new File([""], "no_csv_uploaded.csv")
    const fileName = file?.name ? file?.name.replace(/\.csv$/, "") : "file"
    newData = { ...newData, [fileName]: file }
  }

  return newData
}

const objectToFormData = (obj, formData = new FormData(), parentKey = "") => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key] === undefined ? false : obj[key]
      const formKey = parentKey ? `${parentKey}[${key}]` : key

      if (value instanceof File) {
        formData.append(formKey, value)
      } else if (typeof value === "object" || Array.isArray(value)) {
        formData.append(formKey, JSON.stringify(value))
      } else {
        formData.append(formKey, value)
      }
    }
  }
  return formData
}

const prepareForCampaignSubmit = (
  additionalData = {},
  campaignStepsType,
  prepareForCreate = false,
  getState,
) => {
  const data = getCreateCampaignRequestData(additionalData, getState)
  let newData = data

  if (campaignStepsType === "simple") {
    newData = parseSimpleReplyToSameThread(data)
  }

  if (prepareForCreate) {
    newData = addLeadSources(newData)
  }

  return newData
}

const parseDoAfterPreviousStep = (hours, days) => {
  if (typeof hours !== "object") {
    hours = [hours]
    days = [days]
  }
  return (hours || []).map(
    (time, index) =>
      (Number(days[index]) || 0) * 24 * 60 * 60 * 1000 +
      (Number(hours[index]) || 0) * 60 * 60 * 1000,
  )
}

const filterTagInfos = (tagInfos = [], messages, subjects) => {
  return tagInfos.map((tagInfo, index) =>
    tagInfo?.filter(({ tag }) => {
      const parsedTag = tag.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
      return messages[index].includes(`{{${parsedTag}}}`) || subjects[index].includes(`{{${tag}}}`)
    }),
  )
}

const getABTestingField = (field, data) => {
  return {
    messages: { field: "messages", data, emptyValue: "" },
    subjects: { field: "subjects", data, emptyValue: "" },
    signatures: { field: "signatures", data, emptyValue: "" },
    tagInfos: { field: "tagInfos", data, emptyValue: [] },
    personalizedImageIds: {
      field: "personalizedImageIds",
      data,
      emptyValue: null,
    },
    personalizedImageData: {
      field: "personalizedImageData",
      data,
      emptyValue: {},
    },
    hours: { field: "hours", data, emptyValue: "" },
    days: { field: "days", data, emptyValue: "" },
    signatureIds: { field: "signatureIds", data, emptyValue: "" },
    useDefaultSignatures: { field: "useDefaultSignatures", data, emptyValue: true },
    connectMessageViaEmails: { field: "connectMessageViaEmails", data, emptyValue: "" },
  }[field]
}
const getABTestingFields = fields =>
  fields.map(field => {
    return getABTestingField(Object.keys(field)[0], field[Object.keys(field)[0]])
  })

const CREATE_CAMPAIGN_PAGE = {
  INFO: "INFO",
  SETTINGS: "SETTINGS",
}

const getCampaignType = dashboard => {
  const recruiterIndex = 0
  const recruiterPipelineIndex = 1
  switch (dashboard) {
    case CAMPAIGN_DASHBOARD.BASIC:
      return CAMPAIGN_TYPE.BASIC
    case CAMPAIGN_DASHBOARD.SALES_NAVIGATOR:
      return CAMPAIGN_TYPE.SALES_NAVIGATOR
    case CAMPAIGN_DASHBOARD.CSV:
      return CAMPAIGN_TYPE.CSV
    case CAMPAIGN_DASHBOARD.POST_ENGAGEMENT:
      return CAMPAIGN_TYPE.POST_ENGAGEMENT
    case CAMPAIGN_DASHBOARD.LEADS_LIST:
      return CAMPAIGN_TYPE.LEADS_LIST
    case CAMPAIGN_DASHBOARD.RECRUITER[recruiterIndex]:
    case CAMPAIGN_DASHBOARD.RECRUITER[recruiterPipelineIndex]:
      return CAMPAIGN_TYPE.RECRUITER
    default:
      return ""
  }
}

const getRealDashboard = dashboard => {
  const recruiterIndex = 0
  const recruiterPipelineIndex = 1
  switch (dashboard) {
    case CAMPAIGN_DASHBOARD.CSV:
      return "1"
    case CAMPAIGN_DASHBOARD.POST_ENGAGEMENT:
      return "1"
    case CAMPAIGN_DASHBOARD.RECRUITER[recruiterIndex]:
    case CAMPAIGN_DASHBOARD.RECRUITER[recruiterPipelineIndex]:
      return "3"
    case CAMPAIGN_DASHBOARD.LEADS_LIST:
      return "2"
    default:
      return dashboard
  }
}

const getCreateCampaignInitialStateData = (pageType, formData) => {
  const campaignUrl = formData.campaignUrl || ""

  const searchParams = campaignUrl.substring(campaignUrl.search("\\?"))
  const searchString = queryString.parse(searchParams)

  const dashboard = formData.dashboard || "1"
  const { firstConnection, secondConnection, thirdConnection, groupConnection } = formData
  const connections = {
    firstConnection: firstConnection || false,
    secondConnection: secondConnection || false,
    thirdConnection: thirdConnection || false,
    groupConnection: groupConnection || false,
  }

  if (dashboard === "1") {
    // TODO MJ@Any: This is useless now fix or delete this!!!
    // let selectedFacetNetworks = []
    // if (searchString.facetNetwork) {
    //   selectedFacetNetworks = JSON.parse(searchString.facetNetwork)
    // }
    if (searchString.network) {
      // JSON.parse always returns object... This code is valid
      // const network = JSON.parse(searchString.network)
      // const selectedFacetNetworks = typeof network === "string" ? [network] : network
      const selectedFacetNetworks = JSON.parse(searchString.network)

      selectedFacetNetworks.forEach(facetNetwork => {
        switch (facetNetwork) {
          case "F":
            connections.firstConnection = true
            break
          case "S":
            connections.secondConnection = true
            break
          case "O":
            connections.thirdConnection = true
            break
        }
      })
    }
  } else if (dashboard === "2") {
    if (searchString.relationship) {
      const selectedRelationship = searchString.relationship
      selectedRelationship.split(",").forEach(relationship => {
        switch (relationship) {
          case "F":
            connections.firstConnection = true
            break
          case "S":
            connections.secondConnection = true
            break
          case "O":
            connections.thirdConnection = true
            break
          case "A":
            connections.groupConnection = true
            break
        }
      })
    }

    const parsedCampaignURL = parse(campaignUrl)

    if (searchString.query) {
      if (parsedCampaignURL.type === "NEW_QUERY_PARAM_URL") {
        connections.firstConnection = hasFirstConnections(parsedCampaignURL.queryObject)
        connections.secondConnection = hasSecondConnections(parsedCampaignURL.queryObject)
        connections.thirdConnection = hasThirdConnections(parsedCampaignURL.queryObject)
        connections.groupConnection = hasGroupConnections(parsedCampaignURL.queryObject)
      }
    }
  }

  const onlyUniqueLeads =
    typeof formData.onlyUniqueLeads !== "undefined" ? formData.onlyUniqueLeads : true

  const isServicesLink = formData.campaignUrl?.includes("/results/services")

  const noPendingConnections =
    typeof formData.noPendingConnections !== "undefined"
      ? isServicesLink
        ? false
        : formData.noPendingConnections
      : true

  const state = {
    [CREATE_CAMPAIGN_PAGE.INFO]: {
      dashboard,
      campaignType: getCampaignType(formData.dashboard || "1"),
      ...connections,
      onlyPremium: formData.onlyPremium || false,
      onlyOpenInmail: formData.onlyOpenInmail || false,
      sendPrevious: formData.sendPrevious || false,
      getPersonalInfo: formData.getPersonalInfo || false,
      textOnlyEmails: formData.textOnlyEmails || false,
      onlyUniqueLeads,
      noPendingConnections,
      trackEmailsClicks: formData.trackEmailsClicks || false,
      trackEmailsOpens: formData.trackEmailsOpens || false,
      includeGloballyTargetedLeads: formData.includeGloballyTargetedLeads || false,
      autoReuse: formData.autoReuse || false,
      autoReuseInterval: formData.autoReuseInterval || defaultAutoReuseInterval,
      loading: false,
      campaignUrl,
      hideJailMessage: false,
      recentSearchIdWarningShown: false,
      startAt: formData.startAt,
      mailboxes: formData.mailboxes || [],
      scheduleId: formData.scheduleId,
      startUTCTime: formData.startUTCTime || undefined,
      startDate: formData.startDate || undefined,
      newUrl: !!searchString.query,
    },
    [CREATE_CAMPAIGN_PAGE.SETTINGS]: {
      dashboard,
      campaignType: getCampaignType(formData.dashboard || "1"),
      ...connections,
      onlyPremium: formData.onlyPremium || false,
      onlyOpenInmail: formData.onlyOpenInmail || false,
      sendPrevious: formData.sendPrevious || false,
      getPersonalInfo: formData.getPersonalInfo || false,
      textOnlyEmails: formData.textOnlyEmails || false,
      onlyUniqueLeads,
      noPendingConnections,
      trackEmailsClicks:
        typeof formData.trackEmailsClicks !== "undefined" ? formData.trackEmailsClicks : false,
      trackEmailsOpens:
        typeof formData.trackEmailsOpens !== "undefined" ? formData.trackEmailsOpens : false,
      includeGloballyTargetedLeads: formData.includeGloballyTargetedLeads || false,
      autoReuse: formData.autoReuse || false,
      autoReuseInterval: formData.autoReuseInterval || defaultAutoReuseInterval,
      loading: false,
      campaignUrl,
      hideJailMessage: false,
      recentSearchIdWarningShown: false,
      startAt: formData.startAt,
      mailboxes: formData.mailboxes || [],
      scheduleId: formData.scheduleId,
      startUTCTime: formData.startUTCTime || "",
      startDate: formData.startDate || "",
    },
  }

  return state[pageType]
}

const inviteViaEmailStepsValidation = campaignSteps => {
  let onlyConnectViaEmail = false
  let boostConnectViaEmail = false

  if (campaignSteps) {
    campaignSteps.forEach(step => {
      if (step.data && step.data.onlyConnectViaEmail) {
        onlyConnectViaEmail = step.data.onlyConnectViaEmail
      }

      if (step.data && step.data.boostConnectViaEmail) {
        boostConnectViaEmail = step.data.boostConnectViaEmail
      }
      return step
    })
  }

  return { onlyConnectViaEmail, boostConnectViaEmail }
}

const inviteViaEmailCsvValidation = csvInfo => {
  let hasColumns = false
  if (csvInfo && csvInfo.items) {
    hasColumns =
      csvInfo.items.includes("profileUrl") && csvInfo.items.includes("linkedinPersonalEmail")
  }
  return hasColumns
}

const checkInviteViaEmailSteps = (allStepsData, type) => {
  if (allStepsData.length) {
    let hasView = false
    let hasFollow = false
    let hasInMail = false
    let hasFindAndVerifyEmailByLinkedin = false
    let hasFindAndVerifyBusinessEmailByYourSource = false
    let hasInviteViaEmail = false

    allStepsData.forEach(step => {
      const stepType = type ? step.type : step.action
      switch (stepType) {
        case "view":
          hasView = true
          break
        case "follow":
          hasFollow = true
          break
        case "inMail":
          hasInMail = true
          break
        case "findAndVerifyEmailByLinkedin":
          hasFindAndVerifyEmailByLinkedin = true
          break
        case "findAndVerifyBusinessEmailByYourSource":
          hasFindAndVerifyBusinessEmailByYourSource = true
          break
        case "connect":
          if (step.data || step.data.boostConnectViaEmail || step.data.onlyConnectViaEmail) {
            hasInviteViaEmail = step.data.boostConnectViaEmail || step.data.onlyConnectViaEmail
          }
          break
        default:
          break
      }
    })
    const allConnectSteps = hasView || hasFollow || hasInMail
    const allVerifySteps =
      hasFindAndVerifyEmailByLinkedin || hasFindAndVerifyBusinessEmailByYourSource

    return { hasInviteViaEmail, allConnectSteps, allVerifySteps }
  }
}

const validateStartTimeAndDateCampaign = async (startDate, startUTCTime, timezone) => {
  actions.clearFormErrors()
  const allErrors = validateScheduleCampaign({ startDate, startUTCTime, timezone })
  actions.setFormErrors(allErrors)
  return !allErrors
}

const createSequence = async (
  tt,
  accountData,
  formData,
  dashboard,
  getCampaignJSONData,
  userID,
  accountID,
  teamID,
  createCampaignFlow,
  sequence,
  callback,
  isSavedStepTemplate,
  navigate,
) => {
  let isCallbackValid = true

  if (callback) {
    isCallbackValid = await callback()
  }

  if (isCallbackValid) {
    const { startDate, startUTCTime } = formData
    const isValidateStartTimeAndDateCampaign = await validateStartTimeAndDateCampaign(
      startDate,
      startUTCTime,
      moment.tz.guess(),
    )

    if (isValidateStartTimeAndDateCampaign) {
      if (+accountData.linkedinSubscriptionId !== 2 && ["2", "leads-list"].includes(dashboard)) {
        store.dispatch(
          actions.showPrompt(
            PromptVariant.ERROR,
            tt("warning"),
            tt("campaign-util.create-sequence.msg"),
          ),
        )
      } else {
        const formFields = { ...getCampaignJSONData, ...{ saveDraftLoading: true } }
        store.dispatch(actions.updateFormFields(formFields))

        if (dashboard !== "import") {
          await store.dispatch(actions.setAdditionalVariables(tt, []))
        }

        await store.dispatch(actions.updateDraftCampaign(tt))
        store.dispatch(actions.updateFormField("saveDraftLoading", false))
        if (createCampaignFlow) {
          await createCampaignSteps(sequence, [], isSavedStepTemplate)
          await store.dispatch(actions.hideInfoModal())
        }
        navigate(
          `/users/${userID}/teams/${teamID}/accounts/${accountID}/create-campaign/smart-steps`,
        )
      }
    }
  }
}

const createCampaign = async (success, allStepsData, callback, stepTemplate) => {
  if (success) {
    const allStepsDataParsed = allStepsData.map(item => {
      delete item.id
      delete item.campaignId

      if (item.action === "email") {
        return {
          ...item,
          data: {
            ...item.data,
            message: dataUtils.createHTMLEmailTemplate(item.data.message),
          },
        }
      }

      return item
    })

    const allStepsWithTags = allStepsDataParsed.map(step => checkAndChangeTagReplacements(step))

    if (!stepTemplate) {
      store.dispatch(actions.updateFormField("campaignSteps", allStepsWithTags))
    } else {
      store.dispatch(actions.updateFormField("stepTemplate", allStepsWithTags))
    }

    if (callback) {
      await callback()
    }
  }
}

const renderInviteStepsMessage = (tt, addingInviteStepsFlow) => {
  return (
    <div>
      <p className="pb-2">{tt("campaign-util.invite-steps.msg.1")}</p>
      <ul className="invite-connect-message">
        <li className="pb-2 invite-connect-list-message">
          {tt("campaign-util.invite-steps.msg.2")}{" "}
          <span className="invite-connect-list-message__word">
            {tt("campaign-util.invite-steps.msg.3")}
          </span>
          ,
          <span className="invite-connect-list-message__word">
            {" "}
            {tt("campaign-util.invite-steps.msg.4")}
          </span>
          {tt("campaign-util.invite-steps.msg.5")}&nbsp;
          <span className="invite-connect-list-message__word">
            {tt("campaign-util.invite-steps.msg.6")}
          </span>
          ,&nbsp; {tt("campaign-util.invite-steps.msg.7")}&nbsp;
          <span className="invite-connect-list-message__word">
            {tt("campaign-util.invite-steps.msg.8")}
          </span>
        </li>
        <li className="pb-2 invite-connect-list-message">
          {tt("campaign-util.invite-steps.msg.9")}&nbsp;
          <span className="invite-connect-list-message__word">
            {tt("campaign-util.invite-steps.msg.10")}
          </span>
        </li>
      </ul>
      {addingInviteStepsFlow ? (
        <p className="pb-2">{tt("campaign-util.invite-steps.msg.11")} </p>
      ) : (
        <p className="pb-2">{tt("campaign-util.invite-steps.msg.12")}</p>
      )}
    </div>
  )
}

const onSubmit = async (
  tt,
  allElements,
  callback,
  setLoading = () => {},
  idSelector,
  stepTemplate,
) => {
  let success = false
  let allStepsData = []
  let notConnectedSteps = []
  let edges = []
  let newAllStepsData = []
  let hasSquareBrackets = false

  try {
    const complexStepData = await complexStepsSubmit(
      allElements,
      true,
      idSelector,
      undefined,
      stepTemplate,
    )
    success = complexStepData.success
    allStepsData = complexStepData.allStepsData
    notConnectedSteps = complexStepData.notConnectedSteps
    edges = complexStepData.edges
    hasSquareBrackets = complexStepData.hasSquareBrackets

    newAllStepsData = parseComplexReplyToSameThread([...allStepsData, ...edges])
    if (success) {
      if (notConnectedSteps.length && !stepTemplate) {
        store.dispatch(
          actions.showPrompt(
            PromptVariant.WARNING,
            tt("error"),
            tt("campaign-util.on-submit.no-step.msg"),
            async () => {
              await createCampaign(success, [...newAllStepsData], callback)
            },
            tt("confirm"),
            () => {
              setLoading(false)
              store.dispatch(actions.closePrompt())
            },
          ),
        )
      } else if (hasSquareBrackets && !stepTemplate) {
        store.dispatch(
          actions.showPrompt(
            PromptVariant.ERROR,
            tt("error"),
            tt("campaign-util.on-submit.square-bracket.msg"),
          ),
        )
        setLoading(false)
      } else if (allStepsData.length && !stepTemplate) {
        const { hasInviteViaEmail, allConnectSteps, allVerifySteps } = checkInviteViaEmailSteps(
          allStepsData,
          true,
        )
        if (hasInviteViaEmail) {
          if (allConnectSteps && allVerifySteps) {
            await createCampaign(success, newAllStepsData, callback)
          } else {
            store.dispatch(
              actions.showPrompt(PromptVariant.INFO, tt("warning"), renderInviteStepsMessage(tt)),
            )
            setLoading(false)
          }
        } else {
          await createCampaign(success, newAllStepsData, callback)
          setLoading(false)
        }
      } else {
        await createCampaign(success, newAllStepsData, callback, stepTemplate)
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  } catch (error) {
    loggerUtils.sendErrors(error?.message, allElements)
    store.dispatch(
      actions.showPrompt(PromptVariant.ERROR, tt("error"), tt("campaign-util.on-submit.error")),
    )
    setLoading(false)
  }
}

const getInputPlaceholder = (tt, dashboard) => {
  switch (dashboard) {
    case "1":
      return { placeholder: tt("linkedin-search-url"), testId: "linkedin-search-URL" }
    case "2":
      return {
        placeholder: tt("linkedin-sales-search-url"),
        testId: "linkedin-sales-search-URL",
      }
    case "post-engagement":
      return { placeholder: tt("linkedin-post-engagement-url"), testId: "linkedin-post-search-URL" }
    case "recruiter":
    case "recruiter-pipeline":
      return { placeholder: tt("recruiter-search-url"), testId: "linkedin-recruiter-search-URL" }
    case "leads-list":
      return { placeholder: tt("linkedin-lead-list-url"), testId: "linkedin-lead-list-search-URL" }
    default:
      return ""
  }
}

const getIconAndTooltip = (tt, leadSourceType) => {
  switch (leadSourceType) {
    case "CSV":
      return { icon: "file-type-csv", tooltip: tt("imported-csv") }
    case "RECRUITER":
    case "TALENT_LIST":
      return { icon: "user-search", tooltip: tt("recruiter-search-url") }
    case "POST_ENGAGEMENT":
      return { icon: "file-like", tooltip: tt("post-engagement-url") }
    case "LEADS_LIST":
      return { icon: "list-details", tooltip: tt("leads-list-url") }
    case "BASIC":
      return { icon: "brand-linkedin-36", tooltip: tt("linkedin-search-url") }
    case "SALES_NAVIGATOR":
      return { icon: "compass", tooltip: tt("sales-search-url") }
    default:
      return { icon: null, tooltip: "" }
  }
}

const parsedSearchString = value => {
  return queryString.parse(
    value.includes("?") ? value.substring(value.search(/\?/)) : value.substring(value.search(/#/)),
  )
}

const setAllConnections = (queryObject, newState) => {
  let query
  query = setFirstConnections(queryObject, newState.firstConnection)
  query = setSecondConnections(query, newState.secondConnection)
  query = setThirdConnections(query, newState.thirdConnection)
  query = setGroupConnections(query, newState.groupConnection)

  return query
}

const recognizedTypeAndConnectionsFromLink = async (
  newSaleNavUrl,
  value,
  dashboard,
  selectedFacetNetworks,
  formData,
) => {
  let newState = {}
  store.dispatch(actions.updateFormField("campaignUrl", value))
  const linkedinSubscriptionId = +(
    formData["linkedin-subscription"] || store.getState().account.accountData.linkedinSubscriptionId
  )

  if (newSaleNavUrl) {
    try {
      const parsedState = parse(value)
      const hasFirstConnection = hasFirstConnections(parsedState.queryObject)
      const hasSecondConnection = hasSecondConnections(parsedState.queryObject)
      const hasThirdConnection = hasThirdConnections(parsedState.queryObject)
      const hasGroupConnection = hasGroupConnections(parsedState.queryObject)
      newState = {
        ...newState,
        campaignUrl: value,
        firstConnection: hasFirstConnection,
        secondConnection: hasSecondConnection,
        thirdConnection: hasThirdConnection,
        groupConnection: hasGroupConnection,
        ...determineCampaignType(value, dashboard, linkedinSubscriptionId, newState),
      }

      if (hasFirstConnection && (hasSecondConnection || hasThirdConnection)) {
        newState = {
          ...newState,
          campaignUrl: value,
          firstConnection: false,
          secondConnection: false,
          thirdConnection: false,
          groupConnection: false,
        }

        parsedState.queryObject = setAllConnections(parsedState.queryObject, newState)
        const stringifiedUrl = stringify(parsedState)

        store.dispatch(actions.updateFormField("campaignUrl", stringifiedUrl))
        newState = {
          ...newState,
          campaignUrl: stringifiedUrl,
        }
      }
    } catch {}
  } else {
    newState = {
      campaignUrl: value,
      firstConnection: false,
      secondConnection: false,
      thirdConnection: false,
      groupConnection: false,
      ...recognizedBasicConnection(selectedFacetNetworks, newState),
      ...determineCampaignType(value, dashboard, linkedinSubscriptionId, newState),
    }
  }
  return newState
}

const getSelectedFacetNetworks = searchString => {
  let selectedFacetNetworks = []
  let newSaleNavUrl = false
  if (searchString.facetNetwork) {
    selectedFacetNetworks = JSON.parse(searchString.facetNetwork)
  } else if (searchString.network) {
    const network = JSON.parse(searchString.network)
    selectedFacetNetworks = typeof network === "string" ? [network] : network
  } else if (searchString.query) {
    newSaleNavUrl = true
    selectedFacetNetworks = searchString.query
  } else if (searchString.relationship) {
    selectedFacetNetworks = searchString.relationship.split(",")
  }

  return { selectedFacetNetworks, newSaleNavUrl }
}

const linkedinOnChange = async (tt, value, state, setState) => {
  setState({ newSaleNavUrl: false })

  const previousFirstConnection = state.firstConnection
  const { recentSearchIdWarningShown, dashboard } = state

  const searchString = parsedSearchString(value)
  const parsedCampaignURL = parse(value)
  store.dispatch(actions.clearFormError())

  if (searchString.recentSearchId && !recentSearchIdWarningShown) {
    store.dispatch(
      actions.showPrompt(
        PromptVariant.WARNING,
        tt("warning"),
        tt("linkedin-on-change.search-id.msg"),
      ),
    )
    setState({ recentSearchIdWarningShown: true })
  }

  const { selectedFacetNetworks, newSaleNavUrl } = getSelectedFacetNetworks(searchString)
  let firstConnections = "F"
  if (newSaleNavUrl) {
    setState({ newSaleNavUrl })
    firstConnections = decodeURIComponent(
      returnConnectionDegree(parsedCampaignURL.queryObject, "F"),
    )
  }

  const { formData } = store.getState().forms
  await hasBigChangeInLink(
    tt,
    previousFirstConnection,
    selectedFacetNetworks.includes(firstConnections),
    formData.campaignSteps,
    async () => {
      const newState = await recognizedTypeAndConnectionsFromLink(
        newSaleNavUrl,
        value,
        dashboard,
        selectedFacetNetworks,
        formData,
      )
      setState(newState)
    },
  )
}

const checkboxChanges = async (tt, name, state, formData) => {
  const { newSaleNavUrl } = state
  const value = !state[name]
  const previousFirstConnection = state.firstConnection
  const newFirstConnection = name === "firstConnection" && value

  let newState = {}
  await hasBigChangeInLink(
    tt,
    previousFirstConnection,
    newFirstConnection,
    formData.campaignSteps,
    async () => {
      if (newFirstConnection) {
        newState = {
          ...newState,
          ...{
            firstConnection: true,
            secondConnection: false,
            thirdConnection: false,
          },
        }
      } else if (name === "groupConnection") {
        newState = {
          ...newState,
          ...{
            [name]: value,
          },
        }
      } else {
        newState = {
          ...newState,
          ...{
            firstConnection: false,
            [name]: value,
          },
        }
      }

      const nextState = { ...state, ...newState }
      let newCampaignLink = state.campaignUrl

      if (newCampaignLink) {
        const searchParams = newCampaignLink.includes("?")
          ? newCampaignLink.substring(newCampaignLink.search("\\?"))
          : newCampaignLink.substring(newCampaignLink.search("\\#"))
        let searchKeywords

        if (searchParams) {
          const searchString = queryString.parse(searchParams)
          const { firstConnection, secondConnection, thirdConnection, groupConnection, dashboard } =
            nextState

          const salesNavigatorLink = dashboard === "2"
          const basicLink = dashboard === "1"

          if (basicLink) {
            const facetNetworks = []
            if (firstConnection) {
              facetNetworks.push(basicLinkedinLink.firstConnection)
            } else {
              if (secondConnection) facetNetworks.push(basicLinkedinLink.secondConnection)
              if (thirdConnection) facetNetworks.push(basicLinkedinLink.thirdConnection)
            }

            if (searchString.facetNetwork) {
              searchString.facetNetwork = JSON.stringify(facetNetworks)
            } else {
              searchString.network = JSON.stringify(facetNetworks)
            }
          } else if (salesNavigatorLink) {
            if (newSaleNavUrl) {
              try {
                const val = parse(newCampaignLink)
                setFirstConnections(val.queryObject, firstConnection)
                setSecondConnections(val.queryObject, secondConnection)
                setThirdConnections(val.queryObject, thirdConnection)
                setGroupConnections(val.queryObject, groupConnection)

                const stringifiedUrl = stringify(val)

                store.dispatch(actions.updateFormField("campaignUrl", stringifiedUrl))
                newState = {
                  ...nextState,
                  campaignUrl: stringifiedUrl,
                }

                return newState
              } catch {}
            } else {
              let relationship = ""
              if (firstConnection) {
                relationship += relationship.length > 0 ? ",F" : "F"
              } else {
                if (secondConnection) relationship += relationship.length > 0 ? ",S" : "S"
                if (thirdConnection) relationship += relationship.length > 0 ? ",O" : "O"
              }

              if (groupConnection) relationship += relationship.length > 0 ? ",A" : "A"

              searchString.relationship = relationship
            }
          } else if (dashboard === "recruiter") {
            if ((name === "secondConnection" || name === "thirdConnection") && value) {
              store.dispatch(actions.updateFormFields({ [name]: value, firstConnection: false }))
            } else if (name === "firstConnection" && value) {
              store.dispatch(
                actions.updateFormFields({
                  firstConnection: true,
                  secondConnection: false,
                  thirdConnection: false,
                }),
              )
            } else {
              store.dispatch(actions.updateFormField(`${name}`, value))
            }
          }

          const queryStringData = queryString.stringify(searchString)
          const newFilters = decodeURI(queryStringData)
            .replaceAll(" ", "%2520")
            .replaceAll("%2B", "%252B")
            .replaceAll("%20", "%2520")
            .replaceAll("%C5", "%25C5")
            .replaceAll("%25C5%8", "%25C5%258")
            .replaceAll("%22%2520", "%2522%2520")
            .replaceAll("%22", "%2522")
            .replaceAll("%29", "%2529")
            .replaceAll("%28", "%2528")
            .replaceAll("%22", "%2522")
            .replaceAll("'", "%2527")
            .replaceAll("%C3%", "%25C3%25")
            .replaceAll("%2C%2520", "$252C%2520")
            .replaceAll("%26", "%2526")
            .replaceAll("%27", "%2527")
            .replaceAll("%E2", "%25E22")
            .replaceAll("%80", "%2580")
            .replaceAll("%8B", "%258B")
            .replaceAll("%24252C%2520", "%252C%2520")
            .replaceAll("$252C", "%252C")
            .replaceAll("%25E22", "%25E2")

          const firstPartSearchParam = newFilters.substring(0, newFilters.indexOf("keywords") - 5)
          const sessionPartUrl = newFilters.substring(newFilters.indexOf("&sessionId"))
          let finalUrl

          if (!!newSaleNavUrl && newFilters.includes("keywords")) {
            finalUrl = `${firstPartSearchParam}${encodeURI(searchKeywords)}${sessionPartUrl}`
          } else {
            finalUrl = `${newFilters}${sessionPartUrl}`
          }

          const baseLink = newCampaignLink.includes("?")
            ? newCampaignLink.substring(0, newCampaignLink.search("\\?"))
            : newCampaignLink.substring(0, newCampaignLink.search("\\#"))

          newCampaignLink = `${baseLink}${newCampaignLink.includes("?") ? "?" : "#"}${
            newSaleNavUrl ? (firstPartSearchParam ? finalUrl : newFilters) : queryStringData
          }`

          store.dispatch(actions.updateFormField("campaignUrl", newCampaignLink))
          newState = {
            ...nextState,
            ...{
              campaignUrl: newCampaignLink,
            },
          }

          return newState
        }
      }
    },
  )
  return newState
}

const validateFields = async (tt, campaignState, formData, campaignName) => {
  const {
    campaignUrl,
    dashboard,
    firstConnection,
    secondConnection,
    thirdConnection,
    groupConnection,
    newSaleNavUrl,
  } = campaignState

  const { file } = formData
  const name = campaignName || formData.name

  const isAnyOfConnectionDegreeChecked =
    firstConnection || secondConnection || thirdConnection || groupConnection

  store.dispatch(actions.clearFormErrors())
  const allErrors = validateManageCampaign({
    name,
    campaignUrl,
    dashboard,
    file,
    isAnyOfConnectionDegreeChecked,
    newSaleNavUrl,
  })
  const fileErrors = validateFileSize(file)
  const errors = { ...allErrors, ...fileErrors }
  store.dispatch(actions.setFormErrors(errors))
  if (Object.keys(errors).length !== 0) {
    return false
  }

  if (dashboard === "import" && file) {
    const importValidationResult = await store.dispatch(actions.importValidation(tt, file))
    return importValidationResult
  }

  return true
}

const validateNewURL = (newState, previousConnections) => {
  const {
    campaignType: prevCampaignType,
    dashboard: prevDashboard,
    firstConnection: prevFirstConnection,
    secondConnection: prevSecondConnection,
    thirdConnection: prevThirdConnection,
  } = previousConnections
  const { campaignType, dashboard, firstConnection, secondConnection, thirdConnection } = newState

  if (
    (firstConnection && (prevSecondConnection || prevThirdConnection)) ||
    (prevFirstConnection && (secondConnection || thirdConnection))
  ) {
    return false
  }

  return !(dashboard !== prevDashboard || campaignType !== prevCampaignType)
}

const submitURL = async (tt, campaignID, campaignState, campaignDetails, accountData, formData) => {
  const { dashboard, campaignUrl } = campaignState
  const { name } = campaignDetails
  const isValidated = await validateFields(campaignState, formData, name)
  const { autoReuse, autoReuseInterval } = formData

  if (isValidated) {
    if (+accountData.linkedinSubscriptionId !== 2 && ["2", "leads-list"].includes(dashboard)) {
      store.dispatch(
        actions.showPrompt(PromptVariant.ERROR, tt("warning"), tt("submit-url-warning-msg")),
      )
    } else if (dashboard !== "import" && !campaignUrl.startsWith("https://")) {
      store.dispatch(
        actions.showPrompt(PromptVariant.ERROR, tt("error"), tt("submit-url-url-error-msg")),
      )
    } else if (dashboard !== "import" && campaignUrl.includes("savedSearchId")) {
      store.dispatch(
        actions.showPrompt(PromptVariant.ERROR, tt("error"), tt("submit-url-search-id-error-msg")),
      )
    } else {
      const campaignType = getCampaignType(dashboard)
      return store.dispatch(
        actions.createLeadSource(tt, {
          campaignId: +campaignID,
          dashboard,
          campaignType,
          autoReuse,
          autoReuseInterval,
        }),
      )
    }
  }
  return false
}

const getPreviousConnections = async (campaignDetails, formData) => {
  const { leadSources } = campaignDetails
  const leadSource = leadSources?.find(item => item.leadSourceUrl !== "")
  const searchString = selfImport.parsedSearchString(leadSource?.leadSourceUrl || "")

  const { selectedFacetNetworks, newSaleNavUrl } = selfImport.getSelectedFacetNetworks(searchString)

  const result = await selfImport.recognizedTypeAndConnectionsFromLink(
    newSaleNavUrl,
    leadSource?.leadSourceUrl || "",
    leadSource?.dashboard,
    selectedFacetNetworks,
    formData,
  )

  return result
}

const hasEmailInSteps = allElements => {
  if (allElements.length) {
    let hasEmail = false

    allElements.forEach(step => {
      switch (step.type) {
        case "email":
          hasEmail = true
          break

        default:
          break
      }
    })
    return hasEmail
  }
}

const findLatestLeadSourceTypeOfCampaign = sources => {
  if (!sources?.length) {
    return null
  }

  const latestLeadSource = sources.reduce((latest, current) => {
    const latestCreatedAt = new Date(latest.createdAt)
    const currentCreatedAt = new Date(current.createdAt)

    if (currentCreatedAt > latestCreatedAt) {
      return current
    }
    return latest
  })

  return latestLeadSource.leadSourceType
}

const getCampaignJSONData = (state, formData) => {
  const {
    dashboard,
    campaignType,
    firstConnection,
    onlyPremium,
    onlyOpenInmail,
    sendPrevious,
    getPersonalInfo,
    onlyUniqueLeads,
    noPendingConnections,
    trackEmailsClicks,
    trackEmailsOpens,
    textOnlyEmails,
    includeGloballyTargetedLeads,
    autoReuse,
    autoReuseInterval,
  } = state

  const { name, campaignUrl, file } = formData

  const realDashboard = getRealDashboard(dashboard)

  return {
    campaignUrl: (campaignUrl || "").replace("&viewAllFilters=true", ""),
    name,
    dashboard,
    realDashboard,
    file,
    isFirstConnection: ["1", "2"].includes(dashboard) ? firstConnection : false,
    campaignType,
    onlyPremium,
    onlyOpenInmail,
    sendPrevious,
    getPersonalInfo,
    onlyUniqueLeads: ["leads-list"].includes(dashboard) ? false : onlyUniqueLeads,
    noPendingConnections,
    trackEmailsClicks,
    trackEmailsOpens,
    textOnlyEmails,
    includeGloballyTargetedLeads,
    autoReuse,
    autoReuseInterval,
  }
}

const navigateOnCancelCreateCampaign = (userID, selectedTeam, accountID, navigate) => {
  navigate(`/users/${userID}/teams/${selectedTeam?.teamId}/accounts/${accountID}`, {
    replace: true,
  })
}

const cancelCreatingCampaignPrompt = (
  tt,
  userID,
  selectedTeam,
  accountID,
  saveDraftData,
  navigate,
) => {
  store.dispatch(
    actions.showPrompt(
      PromptVariant.WARNING,
      tt("cancel-creating-campaign"),
      tt("cancel.creating.campaign.prompt.msg"),
      () => {
        if (typeof saveDraftData === "function") {
          saveDraftData()
        }
        navigateOnCancelCreateCampaign(userID, selectedTeam, accountID, navigate)
      },
      tt("yes"),
      undefined,
      tt("no"),
      undefined,
      FooterVariant.TRANSACTIONAL,
    ),
  )
}

export {
  CREATE_CAMPAIGN_PAGE,
  addLeadSources,
  checkInviteViaEmailSteps,
  checkboxChanges,
  createSequence,
  filterTagInfos,
  findLatestLeadSourceTypeOfCampaign,
  getABTestingField,
  getABTestingFields,
  getInitialStateData,
  getTime,
  getCampaignType,
  getCreateCampaignInitialStateData,
  getCreateCampaignRequestData,
  getIconAndTooltip,
  getInputPlaceholder,
  getPreviousConnections,
  getRealDashboard,
  hasEmailInSteps,
  getCampaignJSONData,
  parsedSearchString,
  getSelectedFacetNetworks,
  recognizedTypeAndConnectionsFromLink,
  inviteViaEmailCsvValidation,
  inviteViaEmailStepsValidation,
  linkedinOnChange,
  objectToFormData,
  onSubmit,
  parseCampaignSteps,
  parseDoAfterPreviousStep,
  parseIncomingSteps,
  prepareForCampaignSubmit,
  renderInviteStepsMessage,
  submitURL,
  validateFields,
  validateNewURL,
  validateStartTimeAndDateCampaign,
  navigateOnCancelCreateCampaign,
  cancelCreatingCampaignPrompt,
}
