import "./ModalWrapper.scss"

import { Footer, LoadingPage } from "@skylead/component-library"
import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { closeModal, showModal } from "../../../redux/actions"
import { useGlobalTranslation } from "../../../utils/hook"

const ModalWrapper = ({
  children,
  footerVariant,
  primaryBtn,
  secondaryBtn,
  tertiaryBtn,
  noHideOnSubmit = false,
  showPreviuos = false,
}) => {
  const dispatch = useDispatch()
  const { tt } = useGlobalTranslation()
  const { previousData = {} } = useSelector(state => state.app.modalData)
  const { loading } = useSelector(state => state.app.modalLoader)

  const showPreviuosModal = () => {
    dispatch(
      showModal(
        previousData.type,
        previousData.title,
        previousData.content,
        previousData.footerVariant,
        previousData.primaryBtn,
        previousData.secondaryBtn,
        previousData.tertiaryBtn,
        previousData.noHideOnSubmit,
        previousData.showPreviuos,
        previousData.headerChildren,
      ),
    )
  }

  const onClickPrimaryBtn = event => {
    if (primaryBtn.onClick) {
      primaryBtn.onClick(event)
    }
    if (!noHideOnSubmit) {
      dispatch(closeModal())
    }
  }

  const onClickSecondaryBtn = () => {
    if (secondaryBtn.onClick) {
      secondaryBtn.onClick()
    }

    if (showPreviuos) {
      showPreviuosModal()
    } else {
      dispatch(closeModal())
    }
  }

  const onClickTertiaryBtn = () => {
    if (tertiaryBtn.onClick) {
      tertiaryBtn.onClick()
    } else {
      dispatch(closeModal())
    }
  }

  const buttons = useMemo(() => {
    const buttonsArray = []

    if (tertiaryBtn) {
      buttonsArray.push({
        label: tertiaryBtn.label || tt("cancel"),
        onClick: onClickTertiaryBtn,
        disabled: tertiaryBtn.disabled || false,
      })
    }
    if (secondaryBtn) {
      buttonsArray.push({
        label: secondaryBtn.label || tt("cancel"),
        onClick: onClickSecondaryBtn,
        disabled: secondaryBtn.disabled || false,
      })
    }
    if (primaryBtn) {
      buttonsArray.push({
        label: primaryBtn.label || tt("cancel"),
        onClick: onClickPrimaryBtn,
        disabled: primaryBtn.disabled || false,
      })
    }
    return buttonsArray
  }, [primaryBtn, secondaryBtn, tertiaryBtn])

  return (
    <div className="modal-wrapper-container">
      {loading && (
        <div>
          <LoadingPage />
        </div>
      )}
      <div className="content">{children}</div>

      <Footer type={footerVariant} buttons={buttons} bottomRadius />
    </div>
  )
}

// ModalWrapper.propTypes = {
//   children: PropTypes.node.isRequired,
//   footerVariant: PropTypes.oneOf(Object.values(FooterVariant)),
//   primaryBtn: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       onClick: PropTypes.func,
//       disabled: PropTypes.bool,
//     }),
//   ),
//   secondaryBtn: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       onClick: PropTypes.func,
//       disabled: PropTypes.bool,
//     }),
//   ),
//   tertiaryBtn: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       onClick: PropTypes.func,
//       disabled: PropTypes.bool,
//     }),
//   ),
//   noHideOnSubmit: PropTypes.bool,
//   showPreviuos: PropTypes.bool,
// }

export default ModalWrapper
