import { InputGroup as HorizonInputGroup } from "@skylead/component-library"
import { CardElement } from "@stripe/react-stripe-js"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { clearFormError, closeModal, showModal, updateFormField } from "../../../redux/actions"
import { useGlobalTranslation } from "../../../utils/hook"

const InputGroup = ({
  accept = "",
  after = null,
  append = "",
  ariaLabel = "",
  autoComplete = "off",
  autoFocus = false,
  before = null,
  bordered = false,
  children,
  customError,
  customValue = null,
  description,
  disabled = false,
  expandable,
  extraInput,
  field,
  gray = false,
  hideMaxLength,
  iconClassName,
  iconName = "",
  inputClassName,
  inputGroupClassName = "",
  inputLabel = "",
  inputProps = {},
  isConnectMessage = false,
  label = "",
  labelColor,
  margin = "",
  maxLength,
  multiplePasswordType,
  noFormError = false,
  noRedux = false,
  onChange,
  onEnter,
  onIconClick,
  onKeyUp,
  outerErrorMessage,
  passwordType,
  placeholder = "",
  prepend,
  preview,
  required,
  rightLabel,
  setCurrentEvent,
  setMultiplePasswordType,
  setPasswordType,
  siblingComponent,
  style = {},
  transparent = false,
  type = "text",
  useDebounce = false,
  useEnterAsDefault,
  variant = "default",
  tooltipText,
  tooltipLeft,
  tooltipTop,
  caret,
  iconWrapperClassName = "",
  readOnly = false,
  canCopyText = false,
  message = "",
  numberInputLabel = "",
  actionBar,
  imageActionBar,
  signatureActionBar,
  previewActionBar,
  showExpandActionBar = false,
  onClickExpandActionBar,
  customToolbar,
  isDropdownMenu = false,
  dropdownSelectedItem = "",
  extend = false,
  tabIndex,
  addEmojiActionBar,
  savedRepliesActionBar,
}) => {
  const dispatch = useDispatch()
  const { tt } = useGlobalTranslation()
  const { formData, formError } = useSelector(state => ({
    formData: state.forms.formData,
    formError: state.forms.formErrors,
  }))

  const clearFormErrorHorizon = fieldToUpdate => {
    dispatch(clearFormError(fieldToUpdate))
  }

  const updateFormFieldHorizon = (fieldToUpdate, valueToUpdate) => {
    dispatch(updateFormField(fieldToUpdate, valueToUpdate))
  }

  const closeModalHorizon = () => {
    dispatch(closeModal())
  }

  const showModalHorizon = (
    modalType,
    title,
    content,
    footerVariant,
    primaryBtn,
    secondaryBtn,
    tertiaryBtn,
    noHideOnSubmit,
    showPreviuos,
    headerChildren,
    headerClass,
  ) => {
    dispatch(
      showModal(
        modalType,
        title,
        content,
        footerVariant,
        primaryBtn,
        secondaryBtn,
        tertiaryBtn,
        noHideOnSubmit,
        showPreviuos,
        headerChildren,
        headerClass,
      ),
    )
  }

  return (
    <HorizonInputGroup
      accept={accept}
      after={after}
      append={append}
      ariaLabel={ariaLabel}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      before={before}
      bordered={bordered}
      customError={customError}
      customValue={customValue}
      description={description}
      disabled={disabled}
      expandable={expandable}
      extraInput={extraInput}
      field={field}
      gray={gray}
      hideMaxLength={hideMaxLength}
      iconClassName={iconClassName}
      iconName={iconName}
      inputClassName={inputClassName}
      inputGroupClassName={inputGroupClassName}
      inputLabel={inputLabel}
      inputProps={inputProps}
      isConnectMessage={isConnectMessage}
      label={label}
      labelColor={labelColor}
      margin={margin}
      maxLength={maxLength}
      multiplePasswordType={multiplePasswordType}
      noFormError={noFormError}
      noRedux={noRedux}
      onChange={onChange}
      onEnter={onEnter}
      onIconClick={onIconClick}
      onKeyUp={onKeyUp}
      outerErrorMessage={outerErrorMessage}
      passwordType={passwordType}
      placeholder={placeholder}
      prepend={prepend}
      preview={preview}
      required={required}
      rightLabel={rightLabel}
      setCurrentEvent={setCurrentEvent}
      setMultiplePasswordType={setMultiplePasswordType}
      setPasswordType={setPasswordType}
      siblingComponent={siblingComponent}
      style={style}
      transparent={transparent}
      type={type}
      useDebounce={useDebounce}
      useEnterAsDefault={useEnterAsDefault}
      variant={variant}
      tooltipText={tooltipText}
      tooltipLeft={tooltipLeft}
      tooltipTop={tooltipTop}
      caret={caret}
      iconWrapperClassName={iconWrapperClassName}
      readOnly={readOnly}
      canCopyText={canCopyText}
      message={message}
      numberInputLabel={numberInputLabel}
      actionBar={actionBar}
      imageActionBar={imageActionBar}
      signatureActionBar={signatureActionBar}
      previewActionBar={previewActionBar}
      showExpandActionBar={showExpandActionBar}
      onClickExpandActionBar={onClickExpandActionBar}
      customToolbar={customToolbar}
      isDropdownMenu={isDropdownMenu}
      dropdownSelectedItem={dropdownSelectedItem}
      extend={extend}
      tabIndex={tabIndex}
      addEmojiActionBar={addEmojiActionBar}
      savedRepliesActionBar={savedRepliesActionBar}
      formData={formData}
      formError={formError}
      updateFormField={updateFormFieldHorizon}
      clearFormError={clearFormErrorHorizon}
      closeModal={closeModalHorizon}
      showModal={showModalHorizon}
      CardElement={CardElement}
      tt={tt}
    >
      {children}
    </HorizonInputGroup>
  )
}

// InputGroup.propTypes = {
//   accept: PropTypes.string,
//   append: PropTypes.string,
//   ariaLabel: PropTypes.string,
//   autoComplete: PropTypes.oneOf(["on", "off"]),
//   autoFocus: PropTypes.bool,
//   bordered: PropTypes.bool,
//   children: PropTypes.node,
//   after: PropTypes.node,
//   before: PropTypes.node,
//   disabled: PropTypes.bool,
//   field: PropTypes.string,
//   gray: PropTypes.bool,
//   iconName: PropTypes.string,
//   inputGroupClassName: PropTypes.string,
//   inputLabel: PropTypes.string,
//   inputProps: PropTypes.instanceOf(Object),
//   isConnectMessage: PropTypes.bool,
//   label: PropTypes.string,
//   labelColor: PropTypes.string,
//   margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   noFormError: PropTypes.bool,
//   noRedux: PropTypes.bool,
//   onChange: PropTypes.func,
//   onKeyUp: PropTypes.func,
//   transparent: PropTypes.bool,
//   placeholder: PropTypes.string,
//   prepend: PropTypes.oneOf([PropTypes.instanceOf(Object), PropTypes.string]),
//   setCurrentEvent: PropTypes.func,
//   siblingComponent: PropTypes.element,
//   style: PropTypes.instanceOf(Object),
//   type: PropTypes.oneOf([
//     "text",
//     "email",
//     "number",
//     "file",
//     "card",
//     "select",
//     "timezone",
//     "timepicker",
//     "textarea",
//     "datepicker",
//     "password",
//     "html",
//   ]),
//   useDebounce: PropTypes.bool,
//   variant: PropTypes.oneOf(["default", "backgrounded"]),
//   customValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
//   iconWrapperClassName: PropTypes.string,
//   readOnly: PropTypes.bool,
//   canCopyText: PropTypes.bool,
//   message: PropTypes.string,
//   numberInputLabel: PropTypes.string,
//   actionBar: PropTypes.element,
//   showExpandActionBar: PropTypes.bool,
//   customToolbar: PropTypes.element,
//   onClickExpandActionBar: PropTypes.func,
//   isDropdownMenu: PropTypes.bool,
//   dropdownSelectedItem: PropTypes.string,
//   extend: PropTypes.bool,
// }

export default React.memo(InputGroup)
