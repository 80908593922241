import i18n from "i18next"
import { useEffect } from "react"
import { initReactI18next } from "react-i18next"
import { useDispatch } from "react-redux"

import * as actions from "../redux/actions"

const loadTranslations = async (dispatch, language) => {
  try {
    const response = await dispatch(actions.getWhitelabelTranslations(language))
    return response
  } catch (error) {
    return {}
  }
}

const initializeI18n = async (dispatch, language) => {
  const translations = await loadTranslations(dispatch, language)

  i18n.use(initReactI18next).init({
    resources: {
      [language]: {
        translation: translations,
      },
    },
    lng: language,
    interpolation: {
      escapeValue: false,
    },
  })
}

const I18nInitializer = ({ language = "en" }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchTranslations = async () => {
      await initializeI18n(dispatch, language)
    }

    fetchTranslations()
  }, [dispatch, language])

  return null
}

export default I18nInitializer
