const existingImageId = 2548
const newImageId = 2540

const existingImageObject = {
  id: existingImageId,
  data: {
    id: existingImageId,
    publicId: "c3821c2d-8603-4ce5-b3b7-14881fa5cd59",
    transformation:
      '[{"data":{"draggable":true,"url":"https://img-store-ln.s3.us-east-2.amazonaws.com/cde84fcf-61dc-4918-b9e6-ee611a54c1ce","x":14,"y":24,"rotation":0,"width":57,"height":57,"isDragging":false},"meta":{"type":"CUSTOMER_PROFILE_IMAGE","fontSize":0}},{"data":{"draggable":true,"url":"https://cdn4.iconfinder.com/data/icons/political-elections/50/48-512.png","x":147,"y":30,"rotation":0,"width":57,"height":57,"isDragging":false},"meta":{"type":"LEAD_PROFILE_IMAGE","fontSize":0}},{"data":{"draggable":true,"text":"{{firstName}}","tags":[{"tag":"firstName","replaceWith":""}],"width":102,"height":18,"x":49,"y":116,"color":"black","rotation":0,"isDragging":false,"fontSize":18,"hovered":false},"meta":{"type":"TEXT","fontSize":18,"fontFamily":"Arial","textAlign":"center"}}]',
    exampleUrl: "https://www.image.guru/209ea363-2769-463c-9b4f-af56b78ce803",
    userId: 248,
    cloudinaryResponse: null,
    isSavedSequence: false,
    createdAt: "2024-05-23T12:58:09.000Z",
    updatedAt: "2024-05-23T12:58:09.000Z",
  },
}

export { existingImageId, newImageId, existingImageObject }
