import { useRef } from "react"

const asyncTimeout = timeout => {
  return new Promise(resolve => {
    setTimeout(resolve, timeout)
  })
}

const isObjEmpty = obj => (obj ? !Object.keys(obj).length : false)

const truncateString = (text, maxLength, defaultString) => {
  if (text && text.length >= maxLength) {
    return `${text.substring(0, maxLength)}...`
  }
  if (!text && defaultString) {
    return defaultString
  }
  return text
}

const getAcronym = str => (str ? str.match(/\b(\w)/g).join("") : "N/A")

const generateInputRefs = numRefs => Array.from({ length: numRefs }, () => useRef(null))

const checkCSVFileExists = async filePath => {
  const response = await window.fetch(filePath, { method: "HEAD" })

  if (response?.ok) {
    return true
  }
  return false
}

export {
  asyncTimeout,
  isObjEmpty,
  truncateString,
  getAcronym,
  checkCSVFileExists,
  generateInputRefs,
}
