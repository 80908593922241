/* eslint-disable react/no-unused-prop-types */
import "./Modal.scss"

import { Modal as SLModal } from "@skylead/component-library"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { closeModal, showModal } from "../../../redux/actions"
import { useGlobalTranslation } from "../../../utils/hook"

export const ModalVariant = {
  SMALL: "small",
  LARGE: "large",
}

const Modal = modalDataProps => {
  const dispatch = useDispatch()
  const { tt } = useGlobalTranslation()
  const {
    show: reduxShow,
    type = modalDataProps.type || ModalVariant.SMALL,
    title = modalDataProps.title || "",
    content = modalDataProps.content || null,
    footerVariant = modalDataProps.footerVariant,
    primaryBtn = modalDataProps.primaryBtn,
    secondaryBtn = modalDataProps.secondaryBtn,
    tertiaryBtn = modalDataProps.tertiaryBtn,
    noHideOnSubmit = modalDataProps.noHideOnSubmit || false,
    showPreviuos = modalDataProps.showPreviuos || false,
    previousData = modalDataProps.previousData || {},
    headerChildren = modalDataProps.headerChildren || "",
    headerClass = modalDataProps.headerClass,
  } = useSelector(state => state.app.modalData)

  const { loading = modalDataProps.loading } = useSelector(state => state.app.modalLoader)
  const {
    primaryBtnRedux,
    secondaryBtnRedux,
    tertiaryBtnRedux,
    headerClassRedux,
    titleRedux,
    footerVariantRedux,
    noHideOnSubmitRedux,
  } = useSelector(state => state.forms.formData)

  const show = typeof modalDataProps.show !== "undefined" ? modalDataProps.show : reduxShow || false

  const showPreviuosModal = () => {
    dispatch(
      showModal(
        previousData.type,
        previousData.title,
        previousData.content,
        previousData.footerVariant,
        previousData.primaryBtn,
        previousData.secondaryBtn,
        previousData.tertiaryBtn,
        previousData.noHideOnSubmit,
        previousData.showPreviuos,
        previousData.headerChildren,
        previousData.headerClass,
      ),
    )
  }

  const closeModalHorizon = () => {
    dispatch(closeModal())
  }

  return (
    <SLModal
      show={show}
      tt={tt}
      type={type}
      title={title}
      content={content}
      footerVariant={footerVariant}
      primaryBtn={primaryBtn}
      secondaryBtn={secondaryBtn}
      tertiaryBtn={tertiaryBtn}
      noHideOnSubmit={noHideOnSubmit}
      showPreviuos={showPreviuos}
      headerChildren={headerChildren}
      headerClass={headerClass}
      loading={loading}
      primaryBtnRedux={primaryBtnRedux}
      secondaryBtnRedux={secondaryBtnRedux}
      tertiaryBtnRedux={tertiaryBtnRedux}
      headerClassRedux={headerClassRedux}
      titleRedux={titleRedux}
      footerVariantRedux={footerVariantRedux}
      noHideOnSubmitRedux={noHideOnSubmitRedux}
      showPreviuosModal={showPreviuosModal}
      closeModal={closeModalHorizon}
    />
  )
}

// Modal.propTypes = {
//   show: PropTypes.bool,
//   type: PropTypes.oneOf(Object.values(ModalVariant)),
//   title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//   content: PropTypes.node,
//   footerVariant: PropTypes.oneOf(Object.values(FooterVariant)),
//   primaryBtn: PropTypes.shape({
//     label: PropTypes.string,
//     onClick: PropTypes.func,
//     disabled: PropTypes.bool,
//   }),
//   secondaryBtn: PropTypes.shape({
//     label: PropTypes.string,
//     onClick: PropTypes.func,
//     disabled: PropTypes.bool,
//   }),
//   tertiaryBtn: PropTypes.shape({
//     label: PropTypes.string,
//     onClick: PropTypes.func,
//     disabled: PropTypes.bool,
//   }),
//   noHideOnSubmit: PropTypes.bool,
//   showPreviuos: PropTypes.bool,
//   previousData: PropTypes.instanceOf(Object),
//   headerClass: PropTypes.string,
// }

export default Modal
