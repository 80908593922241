import React from "react"

import { useGlobalTranslation } from "../../../utils/hook"

const LangText = ({ langKey, values }) => {
  const { tt } = useGlobalTranslation()

  return <>{tt(langKey, { ...values })}</>
}

export default LangText
