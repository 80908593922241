import queryString from "query-string"

import chatConstants from "../constants/chat-constants"

export function checkForReturnToCampaign(messages, doTaskAt) {
  const returnedToCampaignIndex = messages.findIndex(message => message.returnedToCampaign)
  if (returnedToCampaignIndex > -1) {
    messages.splice(returnedToCampaignIndex, 1)
  }
  messages.push({
    createdAt: doTaskAt,
    seenAt: Date.now(),
    returnedToCampaign: true,
  })
}

export function mapNewBelongedLeadWhenGotBackToCampaign(
  lead,
  campaignId,
  nextStepId,
  doTaskAt,
  campaignName,
) {
  return {
    ...lead,
    campaignId,
    currentStepId: nextStepId,
    nextStepId,
    campaignBack: doTaskAt,
    campaign: {
      ...lead.campaign,
      name: campaignName,
    },
  }
}

export function getNewBelongedLeadsWhenLeadGotBackToCampaign(
  leads,
  currentCampaignId,
  campaignId,
  nextStepId,
  doTaskAt,
  campaignName,
) {
  return leads.map(lead => {
    if (lead.campaignId === +currentCampaignId) {
      return mapNewBelongedLeadWhenGotBackToCampaign(
        lead,
        campaignId,
        nextStepId,
        doTaskAt,
        campaignName,
      )
    }
    return lead
  })
}

export function mapNewLeadForChatWhenLeadGotBackToCampaign(
  lead,
  campaignId,
  backToCampaignTimestamp,
  nextStepId,
) {
  return {
    ...lead,
    campaignId,
    lead: {
      ...lead.lead,
      backToCampaignTimestamp,
      nextStepId,
    },
  }
}

export function mapNewLeadsForChatWhenLeadGotBackToCampaign(
  leads,
  leadID,
  campaignId,
  backToCampaignTimestamp,
  nextStepId,
) {
  return leads.map(lead => {
    if (lead.lead.id === leadID) {
      return mapNewLeadForChatWhenLeadGotBackToCampaign(
        lead,
        campaignId,
        backToCampaignTimestamp,
        nextStepId,
      )
    }
    return lead
  })
}

export function checkIfLeadReturnedToTheStepInSameCampaign(
  tt,
  selectedCampaign,
  newLeadsForChat,
  leadID,
  currentCampaignId,
) {
  const { CHAT_TYPE_FILTER_DEFAULT_OPTIONS } = chatConstants(tt)
  let returnedToStepInSameCampaign = false
  let filteredLeads = newLeadsForChat
  if (
    ![
      CHAT_TYPE_FILTER_DEFAULT_OPTIONS.ALL.value,
      CHAT_TYPE_FILTER_DEFAULT_OPTIONS.ARCHIVED.value,
      CHAT_TYPE_FILTER_DEFAULT_OPTIONS.CONNECTED_NOT_REPLIED.value,
      CHAT_TYPE_FILTER_DEFAULT_OPTIONS.OTHER.value,
      CHAT_TYPE_FILTER_DEFAULT_OPTIONS.UNREAD.value,
      CHAT_TYPE_FILTER_DEFAULT_OPTIONS.UNREPLIED.value,
    ].includes(selectedCampaign)
  ) {
    filteredLeads = newLeadsForChat.filter(lead => {
      if (lead.lead.id === leadID) {
        if (+lead.campaignId === +currentCampaignId) {
          returnedToStepInSameCampaign = true
          return true
        }
        return false
      }
      return true
    })
  }
  return {
    returnedToStepInSameCampaign,
    filteredLeads,
  }
}

export const getRedirectObject = (
  selectedCampaignID,
  search,
  allLabels,
  linkedinUserId,
  userID,
  selectedTeam,
  accountID,
  filterConnectedNotReplied,
  filterUnassigned,
  selectedMessageTypes,
  selectedMailboxes,
) => {
  const redirectURL = {
    pathname: `/users/${userID}/teams/${selectedTeam?.teamId}/accounts/${accountID}/chat/${selectedCampaignID}`,
  }

  const query = {}

  if (search) {
    query.search = search
  }

  if (allLabels && allLabels.length > 0) {
    query.labels = allLabels
  }

  if (linkedinUserId) {
    query.linkedinUserId = linkedinUserId
  }

  if (selectedMessageTypes && selectedMessageTypes.length) {
    query.type = selectedMessageTypes
  }

  if (selectedMailboxes && selectedMailboxes.length > 0) {
    query.mailboxIds = selectedMailboxes
  }

  if (filterConnectedNotReplied) {
    query.filterConnectedNotReplied = filterConnectedNotReplied
  }

  if (filterUnassigned) {
    query.filterUnassigned = filterUnassigned
  }

  redirectURL.search = queryString.stringify(query, { encode: false })

  return redirectURL
}

export const getChannelMessagesAndLastMessage = (linkedinUserMessages, channel, thread) => {
  const channelMessages = linkedinUserMessages?.channels?.[channel]?.threads?.[thread]
  const lastMessage = channelMessages?.length > 0 ? channelMessages[channelMessages.length - 1] : {}
  return { channelMessages, lastMessage }
}

export const sliceAttachments = attachmentsString => {
  try {
    const lastIndex = attachmentsString.lastIndexOf("}")
    if (lastIndex > -1) {
      return JSON.parse(`${attachmentsString.slice(0, lastIndex + 1)}]`)
    }
    return []
  } catch (error) {
    return []
  }
}
