import { getNodeMetaData, PromptVariant } from "@skylead/component-library"
import { addEdge } from "react-flow-renderer"

import { smartSequencesConditions } from "../../constants/validation-constants"
import {
  clearComplexStepsError,
  clearFormErrors,
  getPersonalizedImageData,
  setComplexFormErrors,
  setSelectedNodeHandler,
  setSelectedTabHandler,
  showInfoModal,
  showPrompt,
  updateFormField,
  updateFormFields,
} from "../../redux/actions"
import { store } from "../../redux/store"
import { resetABTestingData } from "../ab-utils"
import {
  filterTagInfos,
  getInitialStateData,
  parseDoAfterPreviousStep,
  parseIncomingSteps,
} from "../campaign/campaign-utils"
import dataUtils from "../data/data-utils"
import { stepValidation, validateCardStep } from "../validation/validation-utils"
// eslint-disable-next-line import/no-self-import
import * as selfImport from "./step-utils"

const getStepInitialOutputs = type => {
  switch (type) {
    case "ifCustom":
      return [{ id: "1", label: "1" }]

    default:
      return [
        {
          id: "1",
          label: "condition-node-yes",
          color: "#2B9B25",
        },
        {
          id: "2",
          label: "condition-node-no",
          color: "#CB3C43",
        },
      ]
  }
}

const getTreeFromConnectionUp = (allElements, connection) => {
  const tree = []
  let currentConnection = { ...connection }

  const findCurrentFrom = element => element.id === currentConnection.source
  const findNextEdge = element =>
    element.id.includes("reactflow__edge") && element.target === currentConnection.source

  while (currentConnection) {
    const currentElement = allElements.find(findCurrentFrom)
    if (currentElement) {
      tree.unshift(currentElement)
    }

    currentConnection = allElements.find(findNextEdge)
  }

  return tree
}

const getAllStepIndexes = allElements => {
  const allViewSteps = []
  const allConnectSteps = []
  const allConnectStepsWithUltraBoost = []
  const allFollowSteps = []
  const allInMailSteps = []
  const allMessageSteps = []
  const allEmailSteps = []
  const allIfEmailOpenedSteps = []
  const allIfEmailClickedSteps = []
  const allEmailVerificationSteps = []
  const allEmailVerificationStepsYourSource = []
  const allIfOpenInMailSteps = []
  const allIfConnectedSteps = []
  const allIfCustom = []
  const allIfHasEmail = []
  const allIfHasVerifiedEmail = []

  allElements.forEach((element, index) => {
    switch (element.type) {
      case "view":
        allViewSteps.push(index)
        break
      case "connect":
        allConnectSteps.push(index)
        if (element.data && element.data.ultraConnectBoost) {
          allConnectStepsWithUltraBoost.push(index)
        }
        break
      case "follow":
        allFollowSteps.push(index)
        break
      case "inMail":
        allInMailSteps.push(index)
        break
      case "message":
        allMessageSteps.push(index)
        break
      case "email":
        allEmailSteps.push(index)
        break
      case "ifEmailOpened":
        allIfEmailOpenedSteps.push(index)
        break
      case "ifEmailClicked":
        allIfEmailClickedSteps.push(index)
        break
      case "findAndVerifyEmailByLinkedin":
        allEmailVerificationSteps.push(index)
        break
      case "findAndVerifyBusinessEmailByYourSource":
        allEmailVerificationStepsYourSource.push(index)
        break
      case "ifOpenInMail":
        allIfOpenInMailSteps.push(index)
        break
      case "ifConnected":
        allIfConnectedSteps.push(index)
        break
      case "ifCustom":
        allIfCustom.push(index)
        break
      case "ifHasEmail":
        allIfHasEmail.push(index)
        break
      case "ifHasVerifiedEmail":
        allIfHasVerifiedEmail.push(index)
        break
    }
  })

  return {
    allViewSteps,
    allConnectSteps,
    allConnectStepsWithUltraBoost,
    allFollowSteps,
    allInMailSteps,
    allMessageSteps,
    allEmailSteps,
    allIfEmailOpenedSteps,
    allIfEmailClickedSteps,
    allEmailVerificationSteps,
    allIfOpenInMailSteps,
    allIfConnectedSteps,
    allIfCustom,
    allIfHasEmail,
    allIfHasVerifiedEmail,
    allEmailVerificationStepsYourSource,
  }
}

const validateSteps = async (
  newElements,
  stepIndex,
  hasError,
  validateSingleStep,
  newAllElements,
) => {
  const allErrorsArray = []
  if (hasError) {
    store.dispatch(clearComplexStepsError(stepIndex))
  }
  if (!validateSingleStep) {
    store.dispatch(clearFormErrors())
  }
  newElements.forEach(element => {
    if (element.id !== "init" && !element.id.includes("reactflow__edge")) {
      const { type: action, id } = element
      const {
        messages,
        tagInfos,
        subjects,
        signatures,
        signatureIds,
        useDefaultSignatures,
        emailType,
        days,
        hours,
      } = element.data
      let allEmailSteps = []
      if (element.type === "email") {
        const elements = validateSingleStep ? newAllElements : newElements
        const elementsAbove = getTreeFromConnectionUp(
          elements,
          elements.find(el => el.target === element.id),
        )

        allEmailSteps = selfImport.getAllStepIndexes(elementsAbove).allEmailSteps
      }

      const allSupportedTags = store
        .getState()
        .campaign.additionalVariables.concat(store.getState().app.allSupportedTags)

      const allErrors = validateCardStep({
        action,
        messages,
        tagInfos,
        subjects,
        signatures,
        signatureIds,
        useDefaultSignatures,
        days,
        hours,
        allSupportedTags,
        id,
        dontValidateSubject: allEmailSteps.length > 0,
        emailType,
      })
      if (allErrors) {
        allErrorsArray.push({ index: element.id, errors: allErrors })
      }
    }
  })
  if (allErrorsArray.length) {
    store.dispatch(setComplexFormErrors(allErrorsArray))
  }
  return allErrorsArray
}

const validateStep = (selectedNode, newAllElements) => {
  const { complexStepsError } = store.getState().forms.formErrors
  let hasError
  if (complexStepsError) {
    hasError = complexStepsError.findIndex(s => s.index === selectedNode.id) !== -1
  }

  validateSteps([selectedNode], selectedNode.id, hasError, true, newAllElements)
}

const changeSelectedNodeDataHandler = (newData, setElements, selectedNode, setSelectedNode) => {
  const { activeMessageIndex } = store.getState().forms.formData
  let newElements = []
  setElements(allElements => {
    newElements = allElements.map(element => {
      if (element.id === selectedNode.id) {
        const newElement = {
          ...element,
          data: {
            ...element.data,
            ...newData,
          },
        }
        setSelectedNode(newElement, undefined, activeMessageIndex)

        return { ...newElement }
      }
      return { ...element }
    })
    return newElements
  })
}

const changeSelectedNodePositionHandler = (newData, setElements, nodeId) => {
  setElements(allElements =>
    (allElements || []).map(element => {
      if (element.id === nodeId) {
        const newElement = {
          ...element,
          position: {
            ...element.position,
            ...newData,
          },
        }

        return newElement
      }

      return element
    }),
  )
}

const getTreeFromConnectionDown = (allElements, currentElement, allTrees, error) => {
  if (currentElement) {
    const currentIndex = allElements.findIndex(el => el.id === currentElement.id)
    if (!allElements[currentIndex].visited) {
      allElements[currentIndex] = { ...allElements[currentIndex], visited: true }
      for (let i = 0; i < currentElement.nextSteps?.length; i++) {
        const nextStep = currentElement.nextSteps[i]
        const nextElem = allElements.find(el => el.id === nextStep.step)
        getTreeFromConnectionDown(allElements, nextElem, allTrees, error)
      }
      if (!currentElement.nextSteps?.length) {
        const allPrev = getTreeFromConnectionUp(
          allElements,
          allElements.find(el => el.target === currentElement.id),
        )

        allTrees.push([...allPrev, currentElement])
      }
    } else {
      error.duplicate = true
    }
  }
}

const createTreeFromConnection = (allElements, connection, error) => {
  const tree = []
  const newAllElements = [...allElements, { ...connection, id: "reactflow__edge--" }]

  getTreeFromConnectionDown(
    newAllElements.map(el => ({ ...el, visited: false })),
    allElements.find(el => el.id === connection.target),
    tree,
    error,
  )
  return tree
}

const showInfoModalForDeleteAllSteps = (tt, message, callback) => {
  const initCampaignComplexSteps = [
    { id: "init", type: "init", position: { x: 250, y: 0 }, nextSteps: [] },
  ]

  store.dispatch(
    showInfoModal(
      "warning",
      tt("warning"),
      message,
      async () => {
        store.dispatch(
          updateFormFields({
            campaignSteps: undefined,
            campaignComplexStep: initCampaignComplexSteps,
          }),
        )
        callback()
      },
      tt("ok"),
    ),
  )
}

const hasBigChangeInLink = async (
  tt,
  previousFirstConnection,
  newFirstConnection,
  campaignSteps = [],
  callback,
) => {
  let hasChanged = false

  const hasMessageStep = campaignSteps.some(campStep => campStep.action === "message")
  const hasConnectStep = campaignSteps.some(campStep => campStep.action === "connect")

  const hasFirstConnection = previousFirstConnection && hasMessageStep && !hasConnectStep

  if (hasFirstConnection !== newFirstConnection && campaignSteps.length > 0) {
    const hasFollowStep = campaignSteps.some(campStep => campStep.action === "follow")
    const hasInMailStep = campaignSteps.some(campStep => campStep.action === "inMail")

    if (hasMessageStep || hasConnectStep || hasFollowStep || hasInMailStep) {
      if (hasFirstConnection && hasMessageStep) {
        hasChanged = true
        showInfoModalForDeleteAllSteps(tt, tt("big.change.in.link.msg.1"), callback)
      } else {
        if (hasConnectStep) {
          hasChanged = true
          showInfoModalForDeleteAllSteps(tt, tt("big.change.in.link.msg.2"), callback)
        }
        if (hasFollowStep) {
          hasChanged = true
          showInfoModalForDeleteAllSteps(tt, tt("big.change.in.link.msg.3"), callback)
        }
      }
      if (hasInMailStep && newFirstConnection) {
        hasChanged = true
        showInfoModalForDeleteAllSteps(tt, tt("big.change.in.link.msg.4"), callback)
      }
    }
  }
  if (!hasChanged) {
    callback()
  }
}

const hasActionBeforeAction = (actions1, actions2) => {
  if (actions2.length > 0) {
    for (let i = 0; i < actions1.length; i++) {
      const action1 = actions1[i]
      for (let j = 0; j < actions2.length; j++) {
        const action2 = actions2[j]
        if (action1 < action2) {
          return true
        }
      }
    }
  }

  return false
}

const actionsHasCondition = (allElements, actions1, condition) => {
  const allConds = []
  for (let i = 0; i < actions1.length; i++) {
    const action1 = actions1[i]
    if (allElements[action1].conditions && allElements[action1].conditions[condition]) {
      allConds.push(true)
    } else {
      allConds.push(false)
    }
  }

  return allConds
}

const getMinIndexFromAllArrays = arrays => {
  const allMinIndexes = []
  arrays.forEach(arr => {
    if (arr.length) {
      allMinIndexes.push(Math.min(arr))
    }
  })

  return allMinIndexes.length ? Math.min(allMinIndexes) : -1
}

const isTrueCondition = sourceHandle => sourceHandle?.split("/")[0] === "1"

const getElementMainConditions = step => {
  switch (step.type) {
    case "connect":
      return () => ({ isConnected: true })
    case "ifCustom":
      return sourceHandle => {
        const output = step.data.outputs?.find(el => +el.id === +sourceHandle?.split("/")[0]) || {}

        return output.conditions || {}
      }
    case "ifConnected":
      return sourceHandle => ({ isConnected: selfImport.isTrueCondition(sourceHandle) })
    case "ifEmailOpened":
      return sourceHandle => ({ isOpenedEmail: selfImport.isTrueCondition(sourceHandle) })
    case "ifHasEmail":
      return sourceHandle => ({ hasEmail: selfImport.isTrueCondition(sourceHandle) })
    case "ifHasVerifiedEmail":
      return sourceHandle => ({ hasVerifiedEmail: selfImport.isTrueCondition(sourceHandle) })
    case "ifOpenInMail":
      return sourceHandle => ({ isOpenInMail: selfImport.isTrueCondition(sourceHandle) })
    case "ifEmailClicked":
      return sourceHandle => ({ isClickedEmail: selfImport.isTrueCondition(sourceHandle) })

    default:
      return () => ({})
  }
}

const getElementConditions = (conditions, step) => {
  return {
    ...conditions,
    getConditions: selfImport.getElementMainConditions(step),
  }
}

const parseElementData = element => {
  const { type, position, sourceHandle } = element

  return {
    ...element.data,
    conditionType: smartSequencesConditions.includes(type) ? type : undefined,
    position,
    sourceHandle,
  }
}

const getAction = element => {
  return smartSequencesConditions.includes(element.type) ? "condition" : element.type
}

const getParsedTreeElement = (currentElement, conditions = {}, step) => {
  let { doAfterPreviousStep } = currentElement
  if (currentElement.data.hours || currentElement.data.days) {
    doAfterPreviousStep = parseDoAfterPreviousStep(
      currentElement.data.hours,
      currentElement.data.days,
      getAction(currentElement),
    )
  }

  return {
    ...currentElement,
    nextSteps: currentElement.nextSteps?.filter(nextStep => nextStep.step),
    data: parseElementData(JSON.parse(JSON.stringify(currentElement))),
    action: getAction(currentElement),
    step,
    conditions,
    doAfterPreviousStep,
    requiredStatuses: ["ANY"],
  }
}

const parseTreeElement = (
  parsedAllElements,
  previousElement = {},
  currentElement,
  allElements,
  conditions,
  noStepIncrement,
  idSelector,
) => {
  if (currentElement.id === "init") {
    return { newConditions: {} }
  }

  const connection = parsedAllElements.find(
    el => el.source === previousElement.id && el.target === currentElement.id,
  )

  const moreCond =
    connection && conditions.getConditions ? conditions.getConditions(connection.sourceHandle) : {}

  const allConditions = {
    ...{ isConnected: conditions.isConnected },
    ...moreCond,
  }

  const removedCondition = {}
  if (smartSequencesConditions.includes(currentElement.type) && "isConnected" in allConditions) {
    removedCondition.isConnected = allConditions.isConnected
    delete allConditions.isConnected
  }

  const createCampaignFlow = noStepIncrement ? allElements.length : allElements.length + 1
  const editCampaignFlow = currentElement.step
  const step = idSelector === "step" ? editCampaignFlow : createCampaignFlow
  allElements.push(selfImport.getParsedTreeElement(currentElement, allConditions, step))
  return getElementConditions({ ...allConditions, ...removedCondition }, currentElement)
}

const parseTreeForBackend = (
  parsedAllElements,
  previousElement,
  currentElement,
  allElements,
  conditions,
  idSelector = "id",
  noStepIncrement,
) => {
  if (currentElement) {
    const newConditions = selfImport.parseTreeElement(
      parsedAllElements,
      previousElement,
      currentElement,
      allElements,
      conditions,
      noStepIncrement,
      idSelector,
    )

    for (let i = 0; i < currentElement.nextSteps?.length; i++) {
      const nextStep = currentElement.nextSteps[i]
      const nextElem = parsedAllElements.find(el => el[idSelector] === nextStep.step)

      if (nextElem) {
        selfImport.parseTreeForBackend(
          parsedAllElements,
          currentElement,
          nextElem,
          allElements,
          newConditions,
          idSelector,
          noStepIncrement,
        )
      }
    }
  }
}

const parseDiscoverBusinessEmail = allElements => {
  let parsedAllElements = []

  if (allElements) {
    parsedAllElements = [...allElements]
  }

  const allEmailVerificationIndexes = []

  for (let index = 0; index < parsedAllElements.length; index++) {
    const element = parsedAllElements[index]

    if (parsedAllElements[index].data?.discoverBusinessEmail) {
      parsedAllElements[index].data.discoverBusinessEmail = undefined
    }

    if (
      element.type === "findAndVerifyEmailByLinkedin" ||
      element.type === "findAndVerifyBusinessEmailByYourSource"
    ) {
      allEmailVerificationIndexes.push(index)
    }
  }

  allEmailVerificationIndexes.forEach(index => {
    const selectedNode = parsedAllElements[index]
    const line =
      selectedNode.id && parsedAllElements.find(element => element.target === selectedNode.id)

    let tree = []
    if (line) {
      tree = selfImport.getTreeFromConnectionUp(parsedAllElements, line)
    }

    const allDiscoverBusinessStepIds = []
    for (let j = tree.length - 1; j >= 0; j--) {
      const element = tree[j]
      if (["view", "connect", "inMail", "follow"].includes(element.type)) {
        allDiscoverBusinessStepIds.push(element.id)
      }
    }

    allDiscoverBusinessStepIds.forEach(discoverBusinessStepId => {
      parsedAllElements = parsedAllElements.map(element => {
        if (element.id === discoverBusinessStepId) {
          return {
            ...element,
            data: {
              ...element.data,
              discoverBusinessEmail: true,
            },
          }
        }

        return element
      })
    })
  })

  return parsedAllElements
}

const handleStepTagChanges = async (event, activeDataIndex, newAllConnectTags = [], setNewTags) => {
  const clonedNewAllConnectTags = JSON.parse(JSON.stringify(newAllConnectTags))
  let isChanged = false
  if (clonedNewAllConnectTags && Array.isArray(clonedNewAllConnectTags)) {
    clonedNewAllConnectTags.forEach((connectTags, indexI) => {
      if (connectTags && Array.isArray(connectTags)) {
        connectTags.forEach((connectTag, indexJ) => {
          if (connectTag && connectTag.tag === event.target.id && indexI === activeDataIndex) {
            clonedNewAllConnectTags[activeDataIndex][indexJ] = {
              tag: event.target.id,
              replaceWith: event.target.value,
            }
            isChanged = true
          }
        })
      }
    })
  }

  if (!isChanged) {
    clonedNewAllConnectTags[activeDataIndex] = [
      ...(clonedNewAllConnectTags[activeDataIndex] || []),
      {
        tag: event.target.id,
        replaceWith: event.target.value,
      },
    ]
  }
  store.dispatch(updateFormField("tagInfos", clonedNewAllConnectTags))
  await setNewTags(clonedNewAllConnectTags)
}

const addSourceHandles = parsedAllElements => {
  parsedAllElements.forEach(step => {
    if (step.id.includes("reactflow__edge")) {
      const elIndex = parsedAllElements.findIndex(el => el.id === step.target)
      if (elIndex !== -1) {
        parsedAllElements[elIndex] = {
          ...parsedAllElements[elIndex],
          sourceHandle: step.sourceHandle,
        }
      }
    }
  })
}

const setSelectedNode = (elements, node = {}, noValidation, selectedABTab = 0) => {
  const { selectedNode } = store.getState().campaign

  if (node.id !== selectedNode.id && Object.keys(selectedNode).length > 0) {
    selfImport.validateStep(selectedNode, elements)
  }

  store.dispatch(setSelectedNodeHandler(node))
  resetABTestingData()

  store.dispatch(
    updateFormField("activeMessageIndex", Number.isInteger(selectedABTab) ? selectedABTab : 0),
  )
}

const getFirstElementNextStep = parsedAllElements => {
  if (
    parsedAllElements.length > 0 &&
    parsedAllElements[0].nextSteps &&
    parsedAllElements[0].nextSteps.length > 0
  ) {
    return parsedAllElements[0].nextSteps[0].step
  }
}

const complexStepsSubmit = async (
  allElements,
  focusOnErrors,
  idSelector = "id",
  noValidation = false,
  stepTemplate,
) => {
  const parsedAllElements = selfImport.parseDiscoverBusinessEmail(allElements)

  selfImport.addSourceHandles(parsedAllElements)

  let allStepsData = []
  let stepErrors = []
  if (!noValidation) {
    stepErrors = await selfImport.validateSteps(parsedAllElements)
  }

  if (stepErrors.length) {
    if (focusOnErrors) {
      let parseErrors = ""
      Object.values(stepErrors[0].errors).forEach(err => {
        parseErrors += `${parseErrors.length ? ". " : ""}${err}`
      })
      store.dispatch(showPrompt(PromptVariant.ERROR, "Error", parseErrors))

      selfImport.setSelectedNode(
        allElements,
        parsedAllElements.find(e => e.id === stepErrors[0].index),
        undefined,
        +Object.keys(stepErrors[0].errors)[0].split("-")[1],
      )
      store.dispatch(setSelectedTabHandler("2"))
    }
  } else {
    const firstElementNextStep = selfImport.getFirstElementNextStep(parsedAllElements)

    let firstElem = parsedAllElements.find(el => el[idSelector] === firstElementNextStep)

    if (stepTemplate && !firstElem) {
      firstElem = stepTemplate
    }

    if (firstElem) {
      selfImport.parseTreeForBackend(
        parsedAllElements,
        undefined,
        firstElem,
        allStepsData,
        {},
        idSelector,
      )
    }

    allStepsData = allStepsData.map(el => {
      const filteredTags = filterTagInfos(el.data.tagInfos, el.data.messages, el.data.subjects)
      if (el.action === "email" && el.data.messages) {
        el.data.messages.forEach((message, index) => {
          const { textOnlyEmails } = store.getState().forms.formData
          el.data.messages[index] = dataUtils.createHTMLEmailTemplate(message, textOnlyEmails)
        })
      }

      return {
        ...el,
        data: {
          ...el.data,
          tagInfos: filteredTags,
        },
        nextSteps: el.nextSteps
          ?.map(ns => ({
            step: allStepsData.find(elT => elT[idSelector] === ns.step)?.step,
          }))
          .filter(nextStep => nextStep.step),
      }
    })
  }

  const notConnectedSteps = parsedAllElements.filter(el => {
    if (!el.id.includes("reactflow__edge") && el.id !== "init") {
      const elem = allStepsData.find(el1 => el1.id === el.id)
      return !elem
    }

    return false
  })

  const hasSquareBrackets = parsedAllElements.filter(element => {
    const messages = element.data?.messages
    let messagesValidation = false
    if (messages) {
      messagesValidation = !!messages.filter(message => !!message?.match(/[[\]]/g)).length
    }

    const subjects = element.data?.subjects
    let subjectsValidation = false
    if (subjects) {
      subjectsValidation = !!subjects.filter(subject => subject && !!subject.match(/[[\]]/g)).length
    }

    return messagesValidation || subjectsValidation
  })

  return {
    success: stepErrors.length === 0,
    errors: stepErrors,
    parsedAllElements,
    edges: parsedAllElements.filter(el => el.id.includes("reactflow__edge")),
    allStepsData,
    hasSquareBrackets: !!hasSquareBrackets.length,
    notConnectedSteps: notConnectedSteps.map((currentElement, index) => {
      return getParsedTreeElement(currentElement, {}, allStepsData.length + index + 1)
    }),
  }
}

const addNewNode = (tt, type, position, context, newData) => {
  const newNode = {
    id: context.getNextId(),
    type,
    position,
    data: !newData
      ? getInitialStateData({ action: type, outputs: getStepInitialOutputs(type) }, false)
      : newData?.data,
    nextSteps: [],
  }

  const { isFirstConnection, campaignType } = store.getState().forms.formData
  const { csvInfo } = store.getState().campaign

  if (
    ["ifEmailOpened", "ifEmailClicked"].includes(newNode.type) &&
    store.getState().email.emailAccounts.active === 0
  ) {
    store.dispatch(showPrompt(PromptVariant.ERROR, tt("warning"), tt("add.new.node.warn.msg")))
    if (context?.errorCallback) {
      context.errorCallback(false)
    }
  } else if (
    ["connect", "follow", "inMail", "ifConnected"].includes(newNode.type) &&
    isFirstConnection
  ) {
    store.dispatch(
      showPrompt(
        PromptVariant.ERROR,
        tt("error"),
        `${tt("add.new.node.error.msg.1")} ${type} ${tt("add.new.node.error.msg.2")}`,
      ),
    )
    if (context?.errorCallback) {
      context.errorCallback(false)
    }
  } else if (
    campaignType === "CSV" &&
    !(csvInfo && csvInfo?.items?.includes("profileUrl")) &&
    ["findAndVerifyEmailByLinkedin"].includes(newNode.type)
  ) {
    store.dispatch(
      showPrompt(
        PromptVariant.ERROR,
        tt("error"),
        tt("step.flow.sidebar.csv-campaign-hover.error"),
      ),
    )
  } else {
    const { selected, nodes } = context
    const [, setSelectedNodeFromContext] = selected || []
    const [, setElements] = nodes

    setElements(es => [...es, newNode])

    if (setSelectedNodeFromContext) {
      setSelectedNodeFromContext(newNode, newNode.id)
      store.dispatch(setSelectedTabHandler("2"))
    }
  }
}

const hasAlreadyConnection = (params, els) => {
  const targetConnectionsMap = {}
  const sourceConnectionsMap = {}
  const allElements = [...els, params]

  allElements.forEach(el => {
    if (el.target) {
      const sourceIndex = `${el.source}-${el.sourceHandle}`
      targetConnectionsMap[el.target] = (targetConnectionsMap[el.target] || 0) + 1
      sourceConnectionsMap[sourceIndex] = (sourceConnectionsMap[sourceIndex] || 0) + 1
    }
  })

  const maxSourceConnectionCount = Math.max(...Object.values(sourceConnectionsMap))
  const maxTargetConnectionCount = Math.max(...Object.values(targetConnectionsMap))

  return maxSourceConnectionCount > 1 || maxTargetConnectionCount > 1
}

const onConnectHandler = (params, els) => {
  if (hasAlreadyConnection(params, els)) {
    return false
  }

  return addEdge(params, els).map(el => {
    if (params.source === el.id) {
      return {
        ...el,
        nextSteps: [...el.nextSteps, { step: params.target }],
      }
    }

    return el
  })
}

const onHandleConnect = (tt, allElements, connection) => {
  if (connection.source !== connection.target) {
    const newElements = onConnectHandler(connection, allElements) || []
    const error = {}
    const tree = createTreeFromConnection(newElements, connection, error)
    if (error.duplicate) {
      return tt("on-handle-connect.msg")
    }

    const parsedTree = []

    tree.forEach(el => {
      const allIds = el.map(el1 => el1.id)
      const filteredElements = newElements.filter(
        elm =>
          allIds.includes(elm.id) ||
          (allIds.includes(elm.source) && allIds.includes(elm.target)) ||
          (allIds.includes(elm.target) && allIds.includes(elm.source)),
      )

      const allStepsData = []

      const firstFoundConnection = filteredElements.find(
        elems => elems.target === filteredElements[0].id,
      )
      const allUpElems = getTreeFromConnectionUp(filteredElements, firstFoundConnection)

      let firstElem = filteredElements[0]
      if (allUpElems.length) {
        firstElem = { ...allUpElems[0] }
      }

      parseTreeForBackend(filteredElements, undefined, firstElem, allStepsData, {})

      parsedTree.push(allStepsData.filter(step => !step.id.includes("reactflow__edge")))
    })
    return stepValidation(parsedTree)
  }

  return false
}

const convertSimpleStepsToComplex = (steps = [], setState, isSavedStepTemplate = false) => {
  const complexSteps = []

  if (steps.length > 0) {
    const initStep = steps.find(step => step.id === "init")

    if (!initStep) {
      const nextSteps = []
      if (steps[0] && !isSavedStepTemplate) {
        nextSteps.push({ step: steps[0].step })
      }
      let stepsMinY = steps[0]?.data?.position?.y

      for (let i = 1; i < steps.length; i++) {
        if (steps[i].data?.position?.y < stepsMinY) {
          stepsMinY = steps[i].data?.position?.y
        }
      }

      if (!Number.isSafeInteger(Math.round(stepsMinY))) {
        stepsMinY = 0
      } else {
        stepsMinY -= 200
        if (setState) {
          const initialYCoord = stepsMinY > 0 ? -stepsMinY : Math.abs(stepsMinY)
          setState({
            reactFlowDefaultPosition: [0, initialYCoord + 80],
          })
        }
      }

      complexSteps.push({
        id: "init",
        nextSteps,
        position: { x: 250, y: stepsMinY },
        type: "init",
      })

      if (steps[0]) {
        complexSteps.push({
          id: `reactflow__edge-initnull-${steps[0]?.id}null`,
          source: "init",
          sourceHandle: null,
          target: isSavedStepTemplate ? "" : `${steps[0]?.id}`,
          targetHandle: null,
          type: "custom",
        })
      }
    }

    steps.forEach((step, index) => {
      const actionType = step.type || step.action

      complexSteps.push({
        ...step,
        id: `${step.id}`,
        data: {
          ...step.data,
        },
        stepId: step.id,
        position: {
          x: 250,
          y: 150 * (index + (!initStep ? 1 : 0)),
          ...step.data?.position,
        },

        type: actionType === "condition" ? step.data?.conditionType || "ifCustom" : actionType,
      })

      if (step.nextSteps && step.nextSteps.length > 0) {
        step.nextSteps.forEach(nextStep => {
          const targetStep = steps.find(step1 => step1.step === nextStep.step)
          if (targetStep && targetStep.data) {
            complexSteps.push({
              id: `reactflow__edge-${step.id}${targetStep.data.sourceHandle || null}-${
                targetStep.id
              }null`,
              source: `${step.id}`,
              sourceHandle: targetStep.data.sourceHandle || null,
              target: `${targetStep.id}`,
              targetHandle: null,
              type: "custom",
            })
          }
        })
      }
    })
  }
  return complexSteps
}

const calculateCoordinateX = (tt, step, lastCoordinatesOfX) => {
  const nodeMetaData = getNodeMetaData(tt, step.type)
  const upperRowWidth = nodeMetaData?.title?.length * 6
  const initialLastCoordinatesOfX = 90
  const initialOffsert = 100
  const offset = 140

  if (lastCoordinatesOfX === initialLastCoordinatesOfX) {
    return upperRowWidth + initialOffsert + lastCoordinatesOfX
  }

  return upperRowWidth + offset + lastCoordinatesOfX
}

const getLeadListSteps = (tt, row, stepsTree) => {
  const foundLastStep =
    stepsTree.find(nextStep => {
      if (row.nextStepId && row.nextStep !== "Finished") {
        return (nextStep.ABTestingIds || [nextStep.stepId]).includes(row.nextStepId)
      }

      return nextStep.step === row.currentStep
    }) || {}

  const foundStep = stepsTree.find(steps => foundLastStep.step === steps.step) || {}

  const newTree = getTreeFromConnectionUp(
    stepsTree,
    stepsTree.find(step => step.target === foundStep.id),
  )

  let horizontalSteps = []
  if (newTree && newTree[0]) {
    const allIds = [...newTree.map(tree => tree.id), foundStep.id]

    const allSteps = stepsTree.filter(step => allIds.includes(step.id))
    const connections = stepsTree.filter(
      step => allIds.includes(step.source) && allIds.includes(step.target),
    )

    const finalTreeSteps = [...allSteps, ...connections]

    let lastCoordinatesOfX = 90
    horizontalSteps = finalTreeSteps.map(horizontalStep => {
      if (horizontalStep.position) {
        const calculatedX = calculateCoordinateX(tt, horizontalStep, lastCoordinatesOfX)
        horizontalStep = {
          ...horizontalStep,
          position: {
            x: lastCoordinatesOfX,
            y: 10,
          },
        }
        lastCoordinatesOfX = calculatedX
      }

      return horizontalStep
    })
  }

  const { currentStep } = row

  return { horizontalSteps, foundStep, currentStep }
}

const parseEmailStep = async step => {
  const doc = new DOMParser().parseFromString(step.data.message, "text/html")
  const rawEmail = doc.getElementsByClassName("ql-editor")
  /** check if we have already retrieved image */
  const fetchedImages = store.getState().imagePersonalization.images
  let fetchedImage
  if (step.data.personalizedImageId) {
    fetchedImage = fetchedImages.find(img => img.id === step.data.personalizedImageId)
    if (!fetchedImage) {
      await store.dispatch(getPersonalizedImageData(step.data.personalizedImageId))
      /** get fetched image data from redux after finished request */
      fetchedImage = store
        .getState()
        .imagePersonalization.images.find(img => img.id === step.data.personalizedImageId)
    }
  }

  step.data.personalizedImageData = fetchedImage?.data
  /**
   * This is used for legacy campaign that use email in diferent format.
   * Whole email is wrapper with className="ql-editor"
   */
  if (rawEmail && rawEmail.item(0) && rawEmail.item(0).innerHTML) {
    step.data.message = dataUtils.getEditCampaignHTML(rawEmail.item(0))
  } else {
    step.data.message = dataUtils.getEditCampaignHTML(doc.getElementsByTagName("body").item(0))
  }
  if (fetchedImage) {
    step.data.message = step.data.message.replace(
      "<span>{{personalizedImage}}</span>",
      `<div class="image-template" contenteditable="false"><img src="${fetchedImage.data.exampleUrl}"><div class="image-template__delete"></div></div>`,
    )
  }

  store.dispatch(updateFormField("emailType", step.data.emailType))
}

const parseEmail = async campaignSteps => {
  for (let i = 0; i < campaignSteps.length; i++) {
    if (campaignSteps[i].action === "email") {
      // eslint-disable-next-line
      await parseEmailStep(campaignSteps[i])
    }
  }
}

// Should be deleted after testing is done
// const deprecatedStepsTreeParsing = (stepsTree, campaignSteps) => {
//   if (stepsTree && stepsTree.length > 0) {
//     const newAllStepsData = []
//     const firstElem = stepsTree[0]

//     if (firstElem && firstElem.nextSteps) {
//       newAllStepsData.push(firstElem)
//       parseTreeForBackend(stepsTree, undefined, firstElem, newAllStepsData, {}, undefined, true)
//     }

//     return [
//       ...parseIncomingSteps([
//         ...newAllStepsData.map(step => {
//           if (step.action === "email") {
//             parseEmailStep(step)
//           }

//           const stepId = (campaignSteps.find(campStep => campStep.step === step.step) || {}).id
//           let useDefaultSignature = step.data?.useDefaultSignature
//           let signatureId = step.data?.signatureId || step.data?.emailSignatureId
//           if (useDefaultSignature === null) {
//             useDefaultSignature = false
//           }
//           if (useDefaultSignature && signatureId) {
//             signatureId = ""
//           }

//           return {
//             ...step,
//             action:
//               step.action === "emailVerification" ? "findAndVerifyEmailByLinkedin" : step.action,
//             type: step.type === "emailVerification" ? "findAndVerifyEmailByLinkedin" : step.type,
//             data: {
//               ...step.data,
//               message:
//                 step.data?.message ||
//                 step.data?.inMail ||
//                 step.data?.email ||
//                 step.data?.connectMessage,
//               subject: step.data?.subject || step.data?.inMailSubject || step.data?.emailSubject,
//               signature:
//                 step.data?.signature || step.data?.inMailSignature || step.data?.emailSignature,
//               signatureId,
//               useDefaultSignature,
//               tagInfo: step.data?.tagInfo || step.data?.allTags || [],
//               connectMessageViaEmail: step.data?.connectMessageViaEmail,
//               onlyConnectViaEmail: step.data?.onlyConnectViaEmail,
//               boostConnectViaEmail: step.data?.boostConnectViaEmail,
//               ultraConnectBoost: step.data?.ultraConnectBoost,
//             },
//             nextSteps:
//               step.nextSteps
//                 ?.map(ns => ({
//                   step: newAllStepsData.find(elT => elT.id === ns.step)?.step,
//                 }))
//                 .filter(nextStep => nextStep.step) || [],
//             stepId,
//           }
//         }),
//         ...stepsTree.filter(
//           step => typeof step.id === "string" && step.id.includes("reactflow__edge"),
//         ),
//       ]),
//     ]
//   }
// }

/**
 * Parses steps from backend response
 * it can accept simple steps, old complex steps and new complex steps
 * and parses to simple steps
 *
 * @param {array} stepsTree - Old complex steps
 * @param {array} campaignSteps - simple or new complex steps
 * @returns {array} - Parsed simple steps
 */
const getParsedComplexSteps = async (
  stepsTree,
  campaignSteps = [],
  setState,
  isSavedStepTemplate,
) => {
  /**
   * TODO NM@Any
   * Check this (deprecatedStepsTreeParsing) should be deleted after testing is done
   * Because on the backend (stepsTree) is returned as null.
   */

  // deprecatedStepsTreeParsing(stepsTree, campaignSteps)

  const campaignStepsCopy = JSON.parse(JSON.stringify(campaignSteps))

  await selfImport.parseEmail(campaignStepsCopy)

  return selfImport.convertSimpleStepsToComplex(
    parseIncomingSteps(campaignStepsCopy),
    setState,
    isSavedStepTemplate,
  )
}

const changeTagReplacements = (parsedStep, value, index) => {
  const { allSupportedTags } = store.getState().app

  const allSupportedTagsWithAdditional = [...allSupportedTags, { tag: "unsubscribe" }]

  allSupportedTagsWithAdditional.forEach(({ tag }) => {
    const tagToReplace = `{{${tag}}}`
    const shouldReplaceTag = value.includes(tagToReplace)

    const shouldAddTagInfo =
      !dataUtils.showTagReplacement(tag) &&
      parsedStep.data.tagInfos[index] &&
      !parsedStep.data.tagInfos[index].some(tagInfo => tagInfo?.tag === tag)

    if (shouldReplaceTag && shouldAddTagInfo) {
      const newTagInfo = { tag, replaceWith: tag === "unsubscribe" ? "{{unsubscribe}}" : "" }
      parsedStep.data.tagInfos[index] = [...(parsedStep.data.tagInfos[index] || []), newTagInfo]
    }
  })
}

const checkAndChangeTagReplacements = step => {
  const parsedStep = { ...step }
  for (let i = 0; i < parsedStep.data?.messages?.length; i++) {
    if (parsedStep.data?.messages[i]) {
      changeTagReplacements(parsedStep, parsedStep.data?.messages[i], i)
    }
    if (parsedStep.data?.subjects[i]) {
      changeTagReplacements(parsedStep, parsedStep.data?.subjects[i], i)
    }
  }

  return parsedStep
}

const parseSimpleSteps = async stepData => {
  let allStepsData = []
  let connectStepId = stepData.length
  let hasPersonalEmail = -1
  let hasBusinessEmail = -1
  let hasVerifyEmailByLinkedin = -1
  let hasVerifyEmailByYourSource = -1

  let countNextStepNulls = 0

  for (let i = 0; i < stepData.length; i++) {
    const newStepData = stepData[i]
    if (newStepData.action === "email") {
      if (hasBusinessEmail !== -1) {
        newStepData.data.emailType = "BUSINESS_EMAIL"
      }
      if (hasPersonalEmail !== -1) {
        newStepData.data.emailType = "PERSONAL_EMAIL"
      }

      if (newStepData.data.emailType === "BUSINESS_EMAIL" && hasBusinessEmail === -1) {
        hasBusinessEmail = i
      }
      if (newStepData.data.emailType === "PERSONAL_EMAIL" && hasPersonalEmail === -1) {
        hasPersonalEmail = i
      }

      newStepData.data.messages.forEach((message, index) => {
        newStepData.data.messages[index] = dataUtils.createHTMLEmailTemplate(message)
      })
    }
    if (newStepData.action === "connect") {
      newStepData.data.tagInfos = filterTagInfos(
        newStepData.data.tagInfos,
        newStepData.data.messages,
        newStepData.data.subjects,
      )
      connectStepId = i
    }

    if (newStepData.action === "findAndVerifyEmailByLinkedin" && hasVerifyEmailByLinkedin === -1) {
      hasVerifyEmailByLinkedin = i
    }

    if (
      newStepData.action === "findAndVerifyBusinessEmailByYourSource" &&
      hasVerifyEmailByYourSource === -1
    ) {
      hasVerifyEmailByYourSource = i
    }

    const parsedStepData = {
      ...checkAndChangeTagReplacements(newStepData),
      step: i + 1,
      requiredStatuses: ["ANY"],
    }

    const hasConnectBefore = connectStepId < i

    if (store.getState().forms.formData.isDuplicateCampaign) {
      if (!parsedStepData.nextSteps) {
        countNextStepNulls++
      } else {
        parsedStepData.nextSteps = [{ step: parsedStepData.nextSteps[0].step }]
      }
    } else if (i < stepData.length - 1) {
      parsedStepData.nextSteps = [{ step: i + 2 }]
    }

    if (hasConnectBefore) {
      parsedStepData.conditions = { isConnected: true }
    }

    if (hasConnectBefore && parsedStepData.data && !parsedStepData.data.sentEmailIfNotConnected) {
      parsedStepData.requiredStatuses = ["CONNECTED"]
    }

    allStepsData.push(parsedStepData)
  }

  let discoverBusinessEmail = false
  const hasVerifyEmail =
    hasVerifyEmailByLinkedin === 1 ? hasVerifyEmailByLinkedin : hasVerifyEmailByYourSource
  let i = 0
  while (i < hasVerifyEmail && !discoverBusinessEmail) {
    if (
      allStepsData[i] &&
      ["view", "connect", "inMail", "follow"].includes(allStepsData[i].action)
    ) {
      allStepsData[i] = {
        ...allStepsData[i],
        data: { ...allStepsData[i].data, discoverBusinessEmail: true },
      }

      discoverBusinessEmail = true
    }
    i++
  }

  if (countNextStepNulls > 1) {
    allStepsData = allStepsData.map((stepDataTemp, index) => {
      return {
        ...stepDataTemp,
        nextSteps: allStepsData.length - 1 > index ? [{ step: index + 2 }] : null,
      }
    })
  }
  store.dispatch(updateFormField("campaignSteps", allStepsData))
}

/**
 *  This function adjusts information in campaignSteps and adds complex campaign steps, which include more
 *  info, and defines step connections
 */
const createCampaignSteps = async (campaignSteps, stepsTree = [], isSavedStepTemplate) => {
  const setReactFlowDefaultPosition = async ({ reactFlowDefaultPosition }) => {
    let newReactFlowDefaultPosition = [0, 0]
    newReactFlowDefaultPosition = reactFlowDefaultPosition
    store.dispatch(updateFormField("reactFlowDefaultPosition", newReactFlowDefaultPosition))
  }

  /** This is the main parsing function */
  const parsedStepsTree = await getParsedComplexSteps(
    stepsTree,
    campaignSteps,
    setReactFlowDefaultPosition,
    isSavedStepTemplate,
  )

  const newParsedStepsTree = []
  for (let i = 0; i < parsedStepsTree?.length; i++) {
    const step = parsedStepsTree[i]

    newParsedStepsTree.push({
      ...step,
      nextSteps: step.nextSteps?.map(nextStep => ({
        step: (parsedStepsTree.find(ps => ps.step === nextStep.step) || {}).id,
      })),
    })
  }

  /** This function validates steps and adds certain data into the steps object */
  const data = await selfImport.complexStepsSubmit(newParsedStepsTree, false, undefined, true)

  /**
   * This represents the new campaign steps object, which consists of adjusted simple campaign steps
   *  and added complex campaign steps
   */
  const newSteps = {
    campaignSteps: [...data.allStepsData, ...data.notConnectedSteps],
    campaignComplexStep: newParsedStepsTree,
  }

  store.dispatch(updateFormFields(newSteps))

  return newSteps
}

const parseComplexReplyToSameThread = allElements => {
  const newElements = JSON.parse(JSON.stringify(allElements))
  let allEmailSteps = []

  newElements.forEach((parsedEl, index) => {
    if (parsedEl.type === "email") {
      const elementsAbove = getTreeFromConnectionUp(
        newElements,
        newElements.find(el => el.target === parsedEl.id),
      )
      allEmailSteps = getAllStepIndexes(elementsAbove).allEmailSteps

      allEmailSteps = allEmailSteps.map(emailStep =>
        newElements.findIndex(newEl => elementsAbove[emailStep].id === newEl.id),
      )

      let emailAboveWithEmptySubject
      for (let i = 0; i < allEmailSteps.length; i++) {
        if (newElements[allEmailSteps[i]].data?.subjects.some(subj => subj !== "")) {
          emailAboveWithEmptySubject = allEmailSteps[i]
        }
      }
      if (typeof emailAboveWithEmptySubject !== "undefined") {
        newElements[index].replyToStep = newElements[index].data?.subjects.map(subject => {
          if (subject === "") {
            return newElements[emailAboveWithEmptySubject].step
          }
          return undefined
        })
      }
    }
  })

  return newElements.filter(el => !el.id.includes("reactflow__edge"))
}

const parseSimpleReplyToSameThread = allElements => {
  const newElements = JSON.parse(JSON.stringify(allElements))
  const { campaignSteps = [] } = newElements

  campaignSteps.forEach((el, index) => {
    if (el.action !== "email") {
      return
    }

    if (el.data.subject === "") {
      for (let i = index - 1; i >= 0; i--) {
        if (campaignSteps[i].action === "email" && campaignSteps[i].data?.subject !== "") {
          campaignSteps[index].replyToStep = campaignSteps[i].step
          break
        }
      }
    }
  })
  return newElements
}
export {
  actionsHasCondition,
  addNewNode,
  addSourceHandles,
  calculateCoordinateX,
  changeSelectedNodeDataHandler,
  changeSelectedNodePositionHandler,
  checkAndChangeTagReplacements,
  complexStepsSubmit,
  convertSimpleStepsToComplex,
  createCampaignSteps,
  createTreeFromConnection,
  getAction,
  getAllStepIndexes,
  getElementMainConditions,
  getFirstElementNextStep,
  getLeadListSteps,
  getMinIndexFromAllArrays,
  getParsedComplexSteps,
  getParsedTreeElement,
  getStepInitialOutputs,
  getTreeFromConnectionDown,
  getTreeFromConnectionUp,
  handleStepTagChanges,
  hasActionBeforeAction,
  hasAlreadyConnection,
  hasBigChangeInLink,
  isTrueCondition,
  onConnectHandler,
  onHandleConnect,
  parseComplexReplyToSameThread,
  parseDiscoverBusinessEmail,
  parseEmail,
  parseEmailStep,
  parseSimpleReplyToSameThread,
  parseSimpleSteps,
  parseTreeElement,
  parseTreeForBackend,
  setSelectedNode,
  validateStep,
  validateSteps,
}
