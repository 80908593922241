import "./WhitelabelWidget.scss"

import { DarkThemeSwitch } from "@skylead/component-library"
import classNames from "classnames"
import React from "react"
import { useDispatch } from "react-redux"

import { updateFormField } from "../../../redux/actions"
import { useConfig, useDraggable, useGlobalTranslation } from "../../../utils/hook"
import WhitelabelPageSwitcher from "../WhitelabelPageSwitcher"
import WhitelabelThemeSwitcher from "../WhitelabelThemeSwitcher"

const WhitelabelWidget = () => {
  const dispatch = useDispatch()
  const { tt } = useGlobalTranslation()

  const whitelabelWidgetClass = classNames("whitelabel-widget-wrapper")

  const [ref] = useDraggable()

  return (
    <div ref={ref} className={whitelabelWidgetClass}>
      <WhitelabelThemeSwitcher />
      <WhitelabelPageSwitcher />
      <DarkThemeSwitch
        tt={tt}
        useConfig={useConfig}
        setFieldInRedux={(field, value) => {
          dispatch(updateFormField(field, value))
        }}
      />
    </div>
  )
}

WhitelabelWidget.propTypes = {}

export default WhitelabelWidget
