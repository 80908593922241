import { useEffect } from "react"

import { withRouter } from "../../../utils/with-router"

const ScrollToTop = ({ location, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return children
}

// ScrollToTop.propTypes = {
//   location: PropTypes.instanceOf(Object).isRequired,
//   children: PropTypes.instanceOf(Object).isRequired,
// }

export default withRouter(ScrollToTop)
