import leadFilterConstants from "../constants/leads-filter"
import { store } from "../redux/store"

export function mapLeadForSelectCampaign(lead, nextStep, nextStepId, currentStep) {
  return {
    ...lead,
    step: nextStepId,
    currentStepId: nextStepId,
    currentStep,
    nextStep,
  }
}

export function preparedFiltersQuery(query) {
  let preparedQuery = ""
  const existedParams = Object.keys(query)
  for (let index = 0; index < existedParams.length; index++) {
    preparedQuery += `&${existedParams[index]}=${query[existedParams[index]]}`
  }
  return preparedQuery
}

export function setFiltersToShow(tt, statuses) {
  const { LEADS_FILTERS } = leadFilterConstants(tt)
  const { name, occupation, headline, company } = store.getState().forms.formData
  let filtersString = "?"
  const filtersToShow = []
  const statusFilter = []
  let advancedFiltersCount = 0

  for (let i = 0; i < statuses.length; i++) {
    const element = statuses[i]
    switch (element.name) {
      case "VERIFIED_EMAILS":
        filtersToShow.push(LEADS_FILTERS.VERIFIED_EMAILS)
        filtersString += `&filterByVerifiedEmails=true`
        break

      case "NOT_VERIFIED_EMAILS":
        filtersToShow.push(LEADS_FILTERS.NOT_VERIFIED_EMAILS)
        filtersString += `&filterByNotVerifiedEmails=true`
        break

      case "OUT_OF_OFFICE":
        filtersToShow.push(LEADS_FILTERS.OUT_OF_OFFICE)
        filtersString += `&filterByOutOfOffice=true`
        break

      case "NOT_VERIFIED_EMAIL":
      case "DISCOVERED":
      case "CONNECTED_PENDING":
      case "CONNECTED_NOT_REPLIED":
      case "CONNECTED_REPLIED":
      case "REPLIED_NOT_CONNECTED":
        statusFilter.push(LEADS_FILTERS[element.name].id)
        filtersToShow.push(LEADS_FILTERS[element.name])
        break

      case "COMPLEX_STEPS":
        if (element.stepIds.length >= 1) {
          LEADS_FILTERS.COMPLEX_STEPS.text = tt("utils.complex-steps.text", {
            action: element.stepIds.length,
          })

          filtersToShow.push(LEADS_FILTERS.COMPLEX_STEPS)
        }
        if (element.stepIds.length >= 1) {
          if (element.stepIds.length >= 2) {
            let stepIds
            if (typeof element.stepIds === "string") {
              stepIds = [element.stepIds]
            } else {
              stepIds = element.stepIds.map(id => +id)
            }

            filtersString += `&filterByCurrentStep=[${stepIds}]`
          } else {
            filtersString += `&filterByCurrentStep=[${element.stepIds}]`
          }
        } else {
          filtersString += ``
        }
        break

      case "NAME":
        filtersString += `&filterByName=${name}`
        advancedFiltersCount++
        break

      case "OCCUPATION":
        filtersString += `&filterByOccupation=${occupation}`
        advancedFiltersCount++
        break

      case "COMPANY":
        filtersString += `&filterByCompany=${company}`
        advancedFiltersCount++
        break

      case "HEADLINE":
        filtersString += `&filterByHeadline=${headline}`
        advancedFiltersCount++
        break

      default:
        break
    }
  }

  if (advancedFiltersCount > 0) {
    LEADS_FILTERS.ADVANCED.text = tt("utils.advance-filter-count.text", {
      action: advancedFiltersCount,
    })
    filtersToShow.push(LEADS_FILTERS.ADVANCED)
  }

  if (statusFilter.length) {
    let statusFilters = [...statusFilter]
    let connectionDegree = []
    const includesStatusFour = statusFilter.includes(4)
    const includesStatusFive = statusFilter.includes(5)
    if (includesStatusFour) {
      connectionDegree = [1]
    }
    if (includesStatusFive) {
      statusFilters = statusFilters.filter(status => status !== 5)
      if (!includesStatusFour) {
        statusFilters.push(4)
        connectionDegree.push(2, 3)
      }
    }
    if (
      (includesStatusFour && !includesStatusFive) ||
      (includesStatusFive && !includesStatusFour)
    ) {
      filtersString += `&filterByStatus=${JSON.stringify(
        statusFilters,
      )}&filterByConnectionDegree=${JSON.stringify(connectionDegree)}`
    } else {
      filtersString += `&filterByStatus=${JSON.stringify(statusFilters)}`
    }
  }

  return { filtersString, filtersToShow }
}
