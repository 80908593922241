import axios from "axios"

import config from "../utils/config"

function getEmailAccounts(userID, accountId, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountId}/mailboxes${query}`,
  )
}

function getAllEmailAccounts(userID, accountId, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountId}/mailboxes/all${query}`,
  )
}

function removeEmailAccount(userID, accountId, mailboxId, type) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountId}/mailboxes/${mailboxId}/oauth_email/${type}`,
  )
}

function testCustomDomain(userID, accountID, query) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/mailboxes/test_custom_domain${query}`,
  )
}

function getEmailAccountSettings(userID, accountID, mailboxId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/mailboxes/${mailboxId}`,
  )
}

function editEmailAccountSettings(userID, accountID, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/mailboxes/settings`,
    data,
  )
}

function getTeamEmailCredits(userID, teamID) {
  return axios.get(`${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/credits`)
}

function getEmailSchedules(userID, teamID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/email_schedules`,
  )
}
function getCampaignEmailSchedule(userID, accountID, campaignId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/campaigns/${campaignId}/email_schedule`,
  )
}

function getEmailScheduleCampaigns(userID, teamID, scheduleId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/email_schedules/${scheduleId}/campaigns`,
  )
}

function createEmailSchedule(userID, teamID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/email_schedules`,
    data,
  )
}

function updateEmailSchedule(userID, teamID, scheduleId, data) {
  return axios.patch(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/email_schedules/${scheduleId}`,
    data,
  )
}

function deleteEmailSchedule(userID, teamID, scheduleId) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/email_schedules/${scheduleId}`,
  )
}

function getEmailSchedulesTemplates(userID, teamID) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/email_schedule_templates`,
  )
}

function createEmailScheduleTemplate(userID, teamID, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/email_schedule_templates`,
    data,
  )
}

function deleteEmailScheduleTemplate(userID, teamID, scheduleId) {
  return axios.delete(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/teams/${teamID}/email_schedule_templates/${scheduleId}`,
  )
}

function getSmtpImapCredentials(userID, accountID, mailboxId) {
  return axios.get(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/mailboxes/${mailboxId}/smtp_imap_credentials`,
  )
}

function reconnectImapSmtp(userID, accountID, mailboxId, data) {
  return axios.post(
    `${config.REACT_APP_BACKEND_URL}/users/${userID}/accounts/${accountID}/mailboxes/${mailboxId}/reconnect_imap_smtp`,
    data,
  )
}

export default {
  getEmailAccounts,
  getAllEmailAccounts,
  removeEmailAccount,
  testCustomDomain,
  getEmailAccountSettings,
  editEmailAccountSettings,
  getTeamEmailCredits,
  getEmailSchedules,
  getCampaignEmailSchedule,
  getEmailScheduleCampaigns,
  createEmailSchedule,
  updateEmailSchedule,
  deleteEmailSchedule,
  getEmailSchedulesTemplates,
  createEmailScheduleTemplate,
  deleteEmailScheduleTemplate,
  getSmtpImapCredentials,
  reconnectImapSmtp,
}
