import "./EmailIntegrationBody.scss"

import { Loader, RadioButtonGroup, SvgIcon } from "@skylead/component-library"
import classNames from "classnames"
import React, { useState } from "react"
import { Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { updateFormField } from "../../../redux/actions"
import { useGlobalTranslation } from "../../../utils/hook"
import InputGroup from "../../organisms/InputGroup"
import EditEmailModalSectionWrapper from "../EditEmailModalSectionWrapper"

const EmailIntegrationBody = ({ integrationType }) => {
  const dispatch = useDispatch()
  const { tt } = useGlobalTranslation()

  const { loadingSmtp, loadingImap, smtpTest, imapTest } = useSelector(
    state => state.forms.formData,
  )

  const [multiplePasswordType, setMultiplePasswordType] = useState({
    "smtp-password": "password",
    "imap-password": "password",
  })

  const senderNameClassName = classNames("input-on-surface", {
    "disabled-sender-email": integrationType === "reconnect",
  })

  const enableSSL = value => {
    dispatch(updateFormField("smtpSsl", value))
  }

  const renderActionMessages = (success, type) => {
    return (
      <div className="integration-message">
        <SvgIcon
          icon={success ? "check-16" : "circle-half-diagonal-16"}
          className={
            success ? "integration-message__success-icon" : "integration-message__error-icon"
          }
        />
        <span
          className={
            success ? "integration-message__success-text" : "integration-message__error-text"
          }
        >
          {success
            ? tt("email.integration.body.success.msg", { action: type })
            : tt("email.integration.body.error.msg", { action: type })}
        </span>
      </div>
    )
  }

  return (
    /** TODO PP@Any: Add link here (Visit help center) */
    <div className="integration-body-wrapper">
      {/* <div>link</div> */}
      <EditEmailModalSectionWrapper
        title={tt("email.integration.body.sender-info.label")}
        description={tt("email.integration.body.sender-info.msg")}
      >
        <div className="integration-body-section-right">
          <InputGroup
            field="smtp-sender-email"
            label={tt("sender-email")}
            placeholder={tt("smtp-sender-email.placeholder")}
            inputGroupClassName={senderNameClassName}
            disabled={integrationType === "reconnect"}
          />
          <InputGroup
            field="smtp-sender-name"
            label={tt("sender-name")}
            placeholder={tt("email.integration.body.sender-name.placeholder")}
            inputGroupClassName="input-on-surface"
          />
        </div>
      </EditEmailModalSectionWrapper>
      <div>
        {loadingSmtp ? (
          <Col className="loading">
            <Loader fullWidth />
          </Col>
        ) : (
          <EditEmailModalSectionWrapper
            title={tt("smtp-settings")}
            description={tt("email.integration.body.smtp-settings.msg")}
          >
            <div className="integration-body-section-right">
              <InputGroup
                field="smtp-username"
                label={tt("username")}
                placeholder={tt("smtp-sender-email.placeholder")}
                inputGroupClassName="input-on-surface"
              />
              <InputGroup
                field="smtp-password"
                label={tt("password")}
                inputGroupClassName="input-on-surface"
                type={multiplePasswordType["smtp-password"]}
                multiplePasswordType={multiplePasswordType}
                setMultiplePasswordType={setMultiplePasswordType}
              />
              <InputGroup
                field="smtp-ip"
                label={tt("hostname")}
                placeholder={tt("smtp-ip.placeholder")}
                inputGroupClassName="input-on-surface"
              />
              <InputGroup
                field="smtp-port"
                label={tt("port-number")}
                placeholder={tt("smtp-port.placeholder")}
                inputGroupClassName="input-on-surface"
              />
            </div>
          </EditEmailModalSectionWrapper>
        )}
        {loadingImap ? (
          <Col className="loading">
            <Loader fullWidth />
          </Col>
        ) : (
          <EditEmailModalSectionWrapper
            title={tt("imap-settings")}
            description={tt("email.integration.body.imap-settings.msg")}
          >
            <div className="integration-body-section-right">
              <InputGroup
                field="imap-username"
                label={tt("username")}
                placeholder={tt("smtp-sender-email.placeholder")}
                inputGroupClassName="input-on-surface"
              />
              <InputGroup
                field="imap-password"
                label={tt("password")}
                inputGroupClassName="input-on-surface"
                type={multiplePasswordType["imap-password"]}
                multiplePasswordType={multiplePasswordType}
                setMultiplePasswordType={setMultiplePasswordType}
              />
              <InputGroup
                field="imap-ip"
                label={tt("hostname")}
                placeholder={tt("imap-ip.placeholder")}
                inputGroupClassName="input-on-surface"
              />
              <InputGroup
                field="imap-port"
                label={tt("port-number")}
                placeholder={tt("imap-port.placeholder")}
                inputGroupClassName="input-on-surface"
              />
            </div>
          </EditEmailModalSectionWrapper>
        )}

        <EditEmailModalSectionWrapper
          title={tt("security")}
          description={tt("email.integration.body.security.msg")}
          withoutBottomMargin
        >
          <div className="integration-body-section-right">
            <RadioButtonGroup
              name="smtpSsl"
              onChange={async () => {
                enableSSL(true)
              }}
              label={tt("tls-ssl")}
            />
            <RadioButtonGroup
              name="smtpSsl"
              defaultChecked
              onChange={() => {
                enableSSL(false)
              }}
              label={tt("don-t-use-encryption")}
            />
          </div>
        </EditEmailModalSectionWrapper>
      </div>
      <div className="smtp-errors-class mt-4">
        {smtpTest != null && renderActionMessages(smtpTest, "SMTP")}
        {imapTest != null && renderActionMessages(imapTest, "IMAP")}
      </div>
    </div>
  )
}

// EmailIntegrationBody.propTypes = {
//   integrationType: PropTypes.string.isRequired,
// }

export default EmailIntegrationBody
