/*
 * Created on Thu Sep 08 2022 by Ivan Kesler, and upgraded many times by JD
 *
 * This is an expression tree parsing state machine for LinkedIn's ...interesting query url format
 */

const MACHINE_STATES = {
  INITIAL: "initial",
  OBJECT: "object",
  ARRAY: "array",
  FIELD: "field",
  RAW_FIELD_VALUE: "rawFieldValue",
  LIST: "list",
}

const OBJECT_END_TOKEN = "OBJECT"
const LIST_END_TOKEN = "LIST"
/**
 * Function used to check if a particular token is a token accumulation ender
 * (checks if the character is an unescaped special character)
 * @param char The token to be analysed
 * @returns {boolean} True if the character is an unescaped special character
 */
const isTokenEnder = char => {
  return char === "%3A" || char === "(" || char === ")" || char === "%2C"
}

/**
 * Function used to split an expression string into component tokens.
 * @param query The expression to split
 * @returns {*[]} The list of tokens within the expression, for example if the expression is "(id:123)" the resulting array would be ["(", "id", ":", "123", ")"]
 */
const splitIntoTokens = query => {
  const tokens = []

  let acc = ""
  for (let i = 0; i < query.length; i++) {
    let token = query[i]
    if (token === "\\") {
      token += query[i + 1]
      i++
    }
    if (token === "%") {
      token += query[i + 1]
      token += query[i + 2]
      i += 2
    }
    if (isTokenEnder(token)) {
      if (acc !== "") {
        tokens.push(acc)
        acc = ""
      }
      tokens.push(token)
    } else acc += token
  }
  return tokens
  // const commaSanitizedTokens = []
  // tokens.forEach(x => {
  //   if (x.includes(",")) {
  //     const index = x.lastIndexOf(",")
  //     commaSanitizedTokens.push(x.substring(0, index))
  //     commaSanitizedTokens.push(",")
  //     commaSanitizedTokens.push(x.substring(index + 1))
  //   } else {
  //     commaSanitizedTokens.push(x)
  //   }
  // })
  // return commaSanitizedTokens.filter(x => x !== "")
}
const URL_TYPES = {
  OLD_URL: "OLD_URL",
  NEW_HASH_PARAM_URL: "NEW_HASH_PARAM_URL",
  NEW_QUERY_PARAM_URL: "NEW_QUERY_PARAM_URL",
}

const identifyUrlType = urlString => {
  if (urlString && typeof urlString === "string") {
    const url = new URL(urlString)
    if (!url.searchParams.get("query") && url.hash) {
      return URL_TYPES.NEW_HASH_PARAM_URL
    }
    if (urlString.includes("(")) {
      return URL_TYPES.NEW_QUERY_PARAM_URL
    }
    return URL_TYPES.OLD_URL
  }
}

/**
 * Function used to unmarshal (parse) the expression string at hand.
 * Note to future user:
 * This function is intended to work within the limited ( and completely undocumented) LinkedIn syntax as it
 * is at the time of writing (unquoted strings, etc...) if LinkedIn chooses to add features, the state machine for this
 * function may need to be extended to support that
 * @param query The expression to be parsed into an object.
 * @returns {*} The object equivalent of the expression.
 */
const unmarshal = query => {
  const sanitizedQuery = query?.replaceAll(" ", "")
  const tokens = splitIntoTokens(sanitizedQuery)
  /** This variable holds the state stack (states that the machine is not quite done processing) of the parsing state machine */
  const stateStack = []
  /** This stack is a way to pass an arbitrary number of arguments from one state of the machine to the other */
  const tokenStack = []
  /** temporary variable for assembling object arguments, up here because javascript is a crappy language */
  let subject = {}
  /** temporary variable for assembling array arguments, up here because javascript is a crappy language (technically not needed, but simpler) */
  let lst = []

  let machineState = MACHINE_STATES.INITIAL
  for (let iter = 0; iter < tokens.length; iter++) {
    const token = tokens[iter]
    // console.debug(`Looking at token "${token}"`)
    switch (machineState) {
      case MACHINE_STATES.INITIAL:
        switch (token) {
          case "(":
            // console.debug("found start of object")
            stateStack.push(machineState)
            tokenStack.push(OBJECT_END_TOKEN)
            machineState = MACHINE_STATES.OBJECT
            break
          case "List":
            // console.debug("found start of list")
            machineState = MACHINE_STATES.LIST
            tokenStack.push(LIST_END_TOKEN)
            if (tokens[iter + 1] !== "(") {
              throw new Error(
                `unexpected token encountered after start of list ("${tokens[iter + 1]}")`,
              )
            }
            iter++
            break
        }
        break
      case MACHINE_STATES.OBJECT:
        switch (token) {
          case ")":
            while (tokenStack[tokenStack.length - 1] !== OBJECT_END_TOKEN) {
              const field = tokenStack.pop()
              subject[field.name] = field.value
            }
            tokenStack.pop()
            tokenStack.push(subject)
            // console.debug(`found end of object ${JSON.stringify(subject)}`)

            subject = {}
            machineState = stateStack.pop()
            break
          case "%2C":
            break
          default:
            // console.debug(`found start of field ${token}`)
            tokenStack.push(token)
            stateStack.push(machineState)
            machineState = MACHINE_STATES.FIELD
            iter++
            break
        }
        break
      case MACHINE_STATES.RAW_FIELD_VALUE:
        switch (token) {
          default:
            subject = { value: tokenStack.pop(), name: tokenStack.pop() }
            tokenStack.push(subject)
            // console.debug(`manually ending field (end of field) ${JSON.stringify(subject)}`)
            subject = {}
            machineState = stateStack.pop()
            iter--
            break
        }
        break
      case MACHINE_STATES.FIELD:
        switch (token) {
          case "(":
            // console.debug("found start of object")
            stateStack.push(MACHINE_STATES.RAW_FIELD_VALUE)
            machineState = MACHINE_STATES.OBJECT
            tokenStack.push(OBJECT_END_TOKEN)
            break
          case "List":
            // console.debug("found start of list")
            stateStack.push(MACHINE_STATES.RAW_FIELD_VALUE)
            machineState = MACHINE_STATES.LIST
            tokenStack.push(LIST_END_TOKEN)
            if (tokens[iter + 1] !== "(") {
              throw new Error(
                `unexpected token encountered after start of list ("${tokens[iter + 1]}")`,
              )
            }
            iter++
            break
          default:
            subject = { name: tokenStack.pop(), value: token }
            tokenStack.push(subject)
            // console.debug(`found string value (end of field) ${JSON.stringify(subject)}`)
            subject = {}
            machineState = stateStack.pop()
            break
        }
        break
      case MACHINE_STATES.LIST:
        switch (token) {
          case "(":
            // console.debug("found start of object")
            stateStack.push(machineState)
            tokenStack.push(OBJECT_END_TOKEN)
            machineState = MACHINE_STATES.OBJECT
            break
          case ")":
            while (tokenStack[tokenStack.length - 1] !== LIST_END_TOKEN) {
              const obj = tokenStack.pop()
              lst.push(obj)
            }
            tokenStack.pop()
            tokenStack.push(lst)
            // console.debug(`found end of list (end of field) ${JSON.stringify(lst)}`)
            lst = []
            machineState = stateStack.pop()
            break
          case "%2C":
            break
          default:
            tokenStack.push(token)
          // console.debug(`found raw list value ${token}`)
        }
    }
  }
  return tokenStack[0]
}

const findQueryVal = url => {
  const fullQP = url.search
  const startIndex = fullQP.indexOf("query=") + 6
  const endIndex =
    fullQP.substring(startIndex).indexOf("&") > -1
      ? startIndex + fullQP.substring(startIndex).indexOf("&")
      : fullQP.length

  return fullQP.substring(startIndex, endIndex)
}

const parse = urlString => {
  const sanitizedString = urlString.replaceAll(" ", "")
  const urlType = identifyUrlType(sanitizedString)
  let url
  if (sanitizedString && typeof sanitizedString === "string") {
    url = new URL(sanitizedString)
  }
  if (urlType === URL_TYPES.NEW_HASH_PARAM_URL) {
    url = new URL(sanitizedString.replace(/#/g, "?"))
  }
  let queryString = url?.searchParams?.get("query") ? findQueryVal(url) : null
  if (queryString && !queryString.includes("(")) {
    queryString = decodeURI(queryString)
  }
  const queryObject = queryString !== null ? unmarshal(queryString) : null

  return { type: urlType, queryObject, url }
}

/**
 * Function used to marshall (serialize/stringify) an object into LinkedIn query friendly shape.
 * Note to future user:
 * This function relies pretty heavily on recursion, and will not be very performant for massive objects,
 * if this is the desired use case this function should be rewritten using stacks
 * @param obj The object to be serialized
 * @returns {string} The resulting string
 */
const marshal = obj => {
  let result = ""
  /** if serializing object */
  if (typeof obj === "object" && !Array.isArray(obj) && obj !== null) {
    result += "("
    const keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i++) {
      if (Object.prototype.hasOwnProperty.call(obj, keys[i])) {
        result += keys[i]
        result += ":"
        result += marshal(obj[keys[i]])
        if (i !== keys.length - 1) {
          result += ","
        }
      }
    }
    result += ")"
  } else if (Array.isArray(obj)) {
    /** if serializing array */
    result += "List("
    for (let i = 0; i < obj.length; i++) {
      result += marshal(obj[i])
      if (i !== obj.length - 1) {
        result += ","
      }
    }
    result += ")"
  } else
  /** if serializing raw object */
    result += obj?.includes("%2C") ? obj?.replaceAll("%2C", "%252C") : obj
  return result
}

const stringify = input => {
  const queryString = marshal(input.queryObject)
  input.url.searchParams.delete("query")
  if (input.url.search) {
    input.url.search += `&query=${queryString
      .replaceAll("&", "%26")
      .replaceAll(":", "%3A")
      .replaceAll(",", "%2C")
      .replaceAll(" ", "%20")
      .replaceAll("%20", "%2520")}`
  }
  if (input.type === URL_TYPES.NEW_HASH_PARAM_URL) {
    return input.url.toString().replace(/\?/g, "#")
  }
  return input.url.toString()
}

export { stringify, parse, marshal, URL_TYPES }
