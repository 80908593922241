import { toast } from "@skylead/component-library"

import ACTIONS from "../../constants/ACTIONS"
import settings from "../../constants/settings"
import teamManagementService from "../../services/team-management-service"
import userBehaviourUtils from "../../utils/userBehaviour-utils"
import { getUserData, setFormErrors, updateFormField } from "../actions"

function setSelectedTeam(team) {
  return {
    type: ACTIONS.SET_SELECTED_TEAM,
    payload: team,
  }
}

function getActionSuccess(actionType, payload) {
  return { type: actionType, payload }
}

function clearTeamsForUser() {
  return { type: ACTIONS.CLEAR_USER_TEAMS }
}

function clearCoWorkerTeamsForInvite() {
  return { type: ACTIONS.CLEAN_CO_WORKER_TEAMS_FOR_INVITE }
}

function getTeamsForUser(
  search = "",
  limit = settings.whitelabelAccountsLimit,
  offset = 0,
  navigate,
) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      if (userID) {
        const realSearch = search
          ? `?search=${encodeURIComponent(search)}&limit=${limit}&offset=${offset}`
          : `?limit=${limit}&offset=${offset}`

        const result = await teamManagementService.getTeamsForUser(userID, realSearch)
        const userTeams = result.data.result
        dispatch(
          getActionSuccess(ACTIONS.GET_USER_TEAMS, {
            userTeams,
            limit,
            offset,
            search,
          }),
        )
        const { selectedTeam } = getState().teamManagement
        if (selectedTeam === null || typeof selectedTeam === "undefined") {
          if (userTeams.items[0]) {
            dispatch(setSelectedTeam(userTeams.items[0]))
          } else if (!userTeams.items.length) {
            navigate("/unclaimed", { replace: true })
          }
        } else {
          const foundSelectedTeam = userTeams.items.find(
            team => team.teamId === selectedTeam.teamId,
          )
          if (
            foundSelectedTeam &&
            JSON.stringify(foundSelectedTeam) !== JSON.stringify(selectedTeam)
          ) {
            dispatch(setSelectedTeam(foundSelectedTeam))
          }
        }

        userBehaviourUtils.sessionStartedForVitally()
        return userTeams
      }
    } catch (error) {
      return false
    }
  }
}

function getTeamForUser(teamId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id

      if (userID) {
        const result = await teamManagementService.getTeamForUser(userID, teamId)
        const userTeams = result.data.result

        dispatch(getActionSuccess(ACTIONS.GET_USER_TEAM, { team: userTeams.items[0] }))
        const { selectedTeam } = getState().teamManagement
        if (JSON.stringify(userTeams.items[0]) !== JSON.stringify(selectedTeam)) {
          dispatch(setSelectedTeam(userTeams.items[0]))
        }
        return userTeams
      }
    } catch (error) {
      return false
    }
  }
}

function getTeamAccounts(search = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam?.teamId

      const searchParam = search ? `?search=${encodeURIComponent(search)}` : ""

      const teamAccounts = await teamManagementService.getTeamAccounts(userID, teamID, searchParam)
      dispatch(getActionSuccess(ACTIONS.GET_TEAM_ACCOUNTS, teamAccounts.data.result))
    } catch (error) {
      if (error.response?.data?.error?.code === 400) {
        dispatch(getActionSuccess(ACTIONS.CLEAR_TEAM_ACCOUNTS))
      }
      return false
    }
  }
}

function getTeamMembers(query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { selectedTeam } = getState().teamManagement
      const result = await teamManagementService.getTeamMembers(userID, selectedTeam.teamId, query)
      const teamMembers = result.data.result
      dispatch(getActionSuccess(ACTIONS.GET_TEAM_MEMBERS, teamMembers))
      return true
    } catch (error) {
      return false
    }
  }
}

function getCoWorkers(query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const result = await teamManagementService.getCoWorkers(userID, query)
      const coWorkers = result.data.result
      dispatch(getActionSuccess(ACTIONS.GET_CO_WORKERS, coWorkers))
      return true
    } catch (error) {
      return false
    }
  }
}

function getTeamsForCoWorkerInvite(email = "") {
  const encodedEmail = email && encodeURIComponent(email)
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const params = encodedEmail ? `?email=${encodedEmail}` : ""
      const coworkerTeamsForInvite = await teamManagementService.getTeamsForCoWorkerInvite(
        userID,
        params,
      )
      dispatch(
        getActionSuccess(
          ACTIONS.GET_CO_WORKER_TEAMS_FOR_INVITE,
          coworkerTeamsForInvite.data.result,
        ),
      )
      return true
    } catch (error) {
      return false
    }
  }
}

function getPlatformAdmins(query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const result = await teamManagementService.getPlatformAdmins(userID, query)
      const platformAdmins = result.data
      dispatch(getActionSuccess(ACTIONS.GET_PLATFORM_ADMINS, platformAdmins))
      return true
    } catch (error) {
      return false
    }
  }
}

function getTeamRoles() {
  return async (dispatch, getState) => {
    try {
      const { selectedTeam } = getState().teamManagement
      const userID = getState().user.profile.id
      const result = await teamManagementService.getTeamRoles(userID, selectedTeam.teamId)
      const teamRoles = result.data.result.items
      dispatch(getActionSuccess(ACTIONS.GET_TEAM_ROLES, teamRoles))
      return teamRoles
    } catch (error) {
      return false
    }
  }
}

function getPlatformRoles() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const result = await teamManagementService.getPlatformRoles(userID)
      const platformRoles = result.data.result.items
      dispatch(getActionSuccess(ACTIONS.GET_PLATFORM_ROLES, platformRoles))
      return platformRoles
    } catch (error) {
      return false
    }
  }
}

function getPermissions() {
  return async (dispatch, getState) => {
    try {
      const { selectedTeam } = getState().teamManagement
      const userID = getState().user.profile.id
      const { teamId } = selectedTeam
      if (userID && teamId) {
        const result = await teamManagementService.getPermissions(userID, teamId)
        const permissions = result.data.result.items
        dispatch(getActionSuccess(ACTIONS.GET_PERMISSIONS, permissions))
        return permissions
      }
      return false
    } catch (error) {
      return false
    }
  }
}

function createTeam(tt, navigate) {
  return async (dispatch, getState) => {
    try {
      const { teamName } = getState().forms.formData
      const userID = getState().user.profile.id
      const data = {
        name: teamName,
      }
      await teamManagementService.createTeam(userID, data)
      dispatch(updateFormField("companyName", undefined))
      toast.success(tt("team-successfully-created"))
      dispatch(clearTeamsForUser())
      await dispatch(getUserData(undefined, navigate))
      await dispatch(getTeamsForUser(undefined, undefined, undefined, navigate))
      await dispatch(getTeamAccounts())
      return true
    } catch (error) {
      dispatch(
        setFormErrors({
          teamName: error?.response?.data?.error?.message
            ? error.response.data.error.message
            : tt("something-went-wrong"),
        }),
      )
      return false
    }
  }
}

function changeTeamName(tt, teamID, newTeamName, navigate) {
  return async (dispatch, getState) => {
    try {
      const { teamName } = getState().forms.formData
      const userID = getState().user.profile.id
      const data = newTeamName || teamName
      await teamManagementService.changeTeamName(userID, teamID, data)
      await dispatch(getTeamsForUser(undefined, undefined, undefined, navigate))
      toast.success(tt("changes-successfully-submitted"))
      return true
    } catch (error) {
      dispatch(
        setFormErrors({
          teamName: error?.response?.data?.error?.message
            ? error.response.data.error.message
            : tt("something-went-wrong"),
        }),
      )
      return false
    }
  }
}

function leaveTeam(tt, teamID, navigate) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await teamManagementService.leaveTeam(userID, teamID)
      dispatch(setSelectedTeam(null))
      await dispatch(getUserData(undefined, navigate))
      await dispatch(getTeamsForUser(undefined, undefined, undefined, navigate))
      dispatch(getActionSuccess(ACTIONS.CLEAR_TEAM_ACCOUNTS))
      toast.success(tt("team-successfully-left"))
    } catch (error) {}
  }
}

function deleteTeam(tt, teamID, navigate) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await teamManagementService.deleteTeam(userID, teamID)
      dispatch(setSelectedTeam(null))
      dispatch(clearTeamsForUser())
      await dispatch(getUserData(undefined, navigate))
      await dispatch(getTeamsForUser(undefined, undefined, undefined, navigate))
      toast.success(tt("successfully-deleted"))
      return true
    } catch (error) {
      return false
    }
  }
}

function createTeamRole(customRole) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { selectedTeam } = getState().teamManagement
      const { name, permissions } = customRole
      const permissionsData = permissions.map(permission => {
        return {
          id: permission.id,
          isViewOnly: permission.isViewOnly === undefined ? false : permission.isViewOnly,
        }
      })
      const data = {
        name,
        permissions: permissionsData,
      }
      await teamManagementService.createTeamRole(userID, selectedTeam.teamId, data)
      await dispatch(getTeamRoles())
      await dispatch(getPermissions())
      return true
    } catch (error) {
      return false
    }
  }
}

function createPlatformRole(customRole) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { name, permissions } = customRole
      const permissionsData = permissions.map(permission => {
        return {
          id: permission.id,
          isViewOnly: permission.isViewOnly === undefined ? false : permission.isViewOnly,
        }
      })
      const data = {
        name,
        permissions: permissionsData,
      }
      await teamManagementService.createPlatformRole(userID, data)
      await dispatch(getPlatformRoles())
      await dispatch(getPermissions())
      return true
    } catch (error) {
      return false
    }
  }
}

function updateTeamRole(tt, customRoleId, customRoleData) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { selectedTeam } = getState().teamManagement
      await teamManagementService.updateTeamRole(
        userID,
        selectedTeam.teamId,
        customRoleId,
        customRoleData,
      )
      toast.success(tt("changes-successfully-submitted"))
      await dispatch(getTeamRoles())
      return true
    } catch (error) {
      return false
    }
  }
}

function updatePlatformRole(tt, customRoleId, customRoleData, isTeamScreen) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await teamManagementService.updatePlatformRole(userID, customRoleId, customRoleData)
      toast.success(tt("changes-successfully-submitted"))
      if (isTeamScreen) {
        await dispatch(getTeamRoles())
      } else {
        await dispatch(getPlatformRoles())
      }
      return true
    } catch (error) {
      return false
    }
  }
}

function deleteTeamRole(tt, roleID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { selectedTeam } = getState().teamManagement
      await teamManagementService.deleteTeamRole(userID, selectedTeam.teamId, roleID)
      toast.success(tt("successfully-deleted"))
      await dispatch(getTeamRoles())
      return true
    } catch (error) {
      return false
    }
  }
}

function deletePlatformRole(tt, roleID, isTeamScreen) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await teamManagementService.deletePlatformRole(userID, roleID)
      toast.success(tt("successfully-deleted"))
      if (isTeamScreen) {
        await dispatch(getTeamRoles())
      } else {
        await dispatch(getPlatformRoles())
      }
      return true
    } catch (error) {
      return false
    }
  }
}

function inviteTeamMember(tt, invitee, query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { selectedTeam } = getState().teamManagement
      const { name, email, roles, canManagePayment, canManageGlobalBlacklist } = invitee
      const accountRoles = roles.map(role => {
        const accounts = role.seats?.map(seat => seat.id)
        return { roleId: role.id, accounts }
      })
      const data = {
        name,
        email,
        accountRoles,
        canManagePayment: canManagePayment ? 1 : 0,
        canManageGlobalBlacklist,
      }
      await teamManagementService.inviteTeamMember(userID, selectedTeam.teamId, data)
      toast.success(tt("invitation-successfully-sent"))
      await dispatch(getTeamMembers(query))
      return true
    } catch (error) {
      return false
    }
  }
}

function updateTeamMember(tt, invitee, query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { selectedTeam } = getState().teamManagement
      const { email, roles, canManagePayment, canManageGlobalBlacklist } = invitee
      const data = {
        email,
        canManageGlobalBlacklist,
      }
      let accountRoles
      if (roles) {
        accountRoles = roles.map(role => {
          const accounts = role.seats?.map(seat => seat.id)
          return { roleId: role.id, accounts }
        })
        data.accountRoles = accountRoles
      }
      if (typeof canManagePayment !== "undefined") {
        data.canManagePayment = canManagePayment ? 1 : 0
      }
      await teamManagementService.updateTeamMember(userID, selectedTeam.teamId, data)
      toast.success(tt("changes-successfully-submitted"))
      await dispatch(getTeamMembers(query))
      return true
    } catch (error) {
      return false
    }
  }
}

function deleteTeamMember(tt, invitee, query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamID = getState().teamManagement.selectedTeam.teamId
      const { email } = invitee
      const data = {
        email,
      }
      await teamManagementService.deleteTeamMember(userID, teamID, data)
      toast.success(tt("successfully-deleted"))
      await dispatch(getTeamMembers(query))
      return true
    } catch (error) {
      return false
    }
  }
}

function inviteCoWorker(tt, invitee, query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { name, email, canManagePayment, hasPlatformRoles, roles, canManageGlobalBlacklist } =
        invitee
      const teamRoles = roles.map(role => {
        const teams = role.teams?.map(team => team.teamId)
        return { roleId: role.id, teams }
      })
      const data = {
        name,
        email,
        canManagePayment: canManagePayment ? 1 : 0,
        hasPlatformRoles: hasPlatformRoles ? 1 : 0,
        teamRoles,
        canManageGlobalBlacklist,
      }
      await teamManagementService.inviteCoWorker(userID, data)
      toast.success(tt("invitation-successfully-sent"))
      await dispatch(getCoWorkers(query))
      return true
    } catch (error) {
      return false
    }
  }
}

function updateCoWorker(tt, invitee, query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { email, roles, canManagePayment, hasPlatformRoles, canManageGlobalBlacklist } = invitee
      const data = {
        email,
        canManageGlobalBlacklist,
      }
      let teamRoles
      if (roles) {
        teamRoles = roles.map(role => {
          const teams = role.teams?.map(team => team.teamId)
          return { roleId: role.id, teams }
        })
        data.teamRoles = teamRoles
      }
      if (typeof canManagePayment !== "undefined") {
        data.canManagePayment = canManagePayment ? 1 : 0
      }
      if (typeof hasPlatformRoles !== "undefined") {
        data.hasPlatformRoles = hasPlatformRoles ? 1 : 0
      }
      await teamManagementService.updateCoWorker(userID, data)
      toast.success(tt("changes-successfully-submitted"))
      await dispatch(getCoWorkers(query))
      return true
    } catch (error) {
      return false
    }
  }
}

function deleteCoWorker(tt, invitee, query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { email, teamRoles } = invitee
      const teams = []
      teamRoles.forEach(role => {
        role.teams.forEach(roleTeam => {
          if (!teams.find(team => team.teamId === roleTeam.teamId)) {
            teams.push(roleTeam.teamId)
          }
        })
      })
      const data = {
        email,
        teams,
      }
      await teamManagementService.deleteCoWorker(userID, data)
      toast.success(tt("successfully-deleted"))
      await dispatch(getCoWorkers(query))
      return true
    } catch (error) {
      return false
    }
  }
}

function invitePlatformAdmin(tt, invitee, query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { name, email, canManageGlobalBlacklist } = invitee
      const data = {
        name,
        email,
        canManageGlobalBlacklist,
      }
      await teamManagementService.invitePlatformAdmin(userID, data)
      toast.success(tt("invitation-successfully-sent"))
      await dispatch(getPlatformAdmins(query))
      return true
    } catch (error) {
      return false
    }
  }
}

function deletePlatformAdmin(tt, platformAdminEmail, query = "") {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const data = { email: platformAdminEmail }
      await teamManagementService.deletePlatformAdmin(userID, data)
      toast.success(tt("successfully-deleted"))
      await dispatch(getPlatformAdmins(query))
      return true
    } catch (error) {
      return false
    }
  }
}

function toggleCanManageTeamMembers(tt, team) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { teamId, canManageTeamMembers } = team
      const data = {
        canManageTeamMembers,
      }
      dispatch(setSelectedTeam(team))
      await teamManagementService.toggleCanManageTeamMembers(userID, teamId, data)
      dispatch(getActionSuccess(ACTIONS.TOGGLE_SUCCESS, { teamId, canManageTeamMembers }))
      toast.success(tt("changes-successfully-submitted"))
      return true
    } catch (error) {
      return false
    }
  }
}

function setSelectedTeamAccount(teamAccount) {
  return {
    type: ACTIONS.SET_SELECTED_TEAM_ACCOUNT,
    payload: teamAccount,
  }
}

export {
  setSelectedTeam,
  clearTeamsForUser,
  getTeamsForUser,
  getTeamForUser,
  getTeamAccounts,
  getTeamMembers,
  getCoWorkers,
  getPlatformAdmins,
  getTeamRoles,
  getPlatformRoles,
  getPermissions,
  createTeam,
  changeTeamName,
  leaveTeam,
  deleteTeam,
  createTeamRole,
  createPlatformRole,
  updateTeamRole,
  updatePlatformRole,
  deleteTeamRole,
  deletePlatformRole,
  inviteTeamMember,
  updateTeamMember,
  deleteTeamMember,
  inviteCoWorker,
  updateCoWorker,
  deleteCoWorker,
  invitePlatformAdmin,
  deletePlatformAdmin,
  toggleCanManageTeamMembers,
  setSelectedTeamAccount,
  getTeamsForCoWorkerInvite,
  clearCoWorkerTeamsForInvite,
}
