import ACTIONS from "../../constants/ACTIONS"
import translationService from "../../services/translation-service"

function getActionSuccess(actionType, payload) {
  return { type: actionType, payload }
}

function getWhitelabelTranslations(language) {
  return async dispatch => {
    try {
      const result = await translationService.fetchTranslations(language)

      const whitelabelTranslations = result.data.data
      dispatch(getActionSuccess(ACTIONS.GET_TRANSLATIONS, whitelabelTranslations))
      return whitelabelTranslations
    } catch (error) {
      return false
    }
  }
}

export { getWhitelabelTranslations }
