import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  whitelabelTranslations: {},
}

export default function translationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_TRANSLATIONS: {
      return {
        ...state,
        whitelabelTranslations: action.payload.whitelabelTranslations,
      }
    }

    default:
      return { ...state }
  }
}

export { initialState as initialStateTranslationReducer }
