import "./RemoveDuplicatesCsv.scss"

import {
  FooterVariant,
  InlineNotification,
  InlineNotificationVariant,
  Switch,
} from "@skylead/component-library"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { clearCsvInfo, closeModal } from "../../../redux/actions"
import { useGlobalTranslation } from "../../../utils/hook"
import LangText from "../LangText"
import ModalWrapper from "../ModalWrapper"

const RemoveDuplicatesCsv = ({
  blacklistedItems,
  csvDuplicates,
  teamDuplicates,
  importCsv,
  isCreateFlow = false,
  includeGloballyTargetedLeads,
  totalLeads,
  withoutDuplicatesAndBlacklisted,
  setCSV = () => {},
  setShowImportLeadsModal,
}) => {
  const dispatch = useDispatch()
  const { tt } = useGlobalTranslation()

  const [, setLoadingImportAll] = useState(false)
  const [removeDuplicatesFromCSV, setRemoveDuplicatesFromCSV] = useState(true)

  const duplicatesDetected = totalLeads !== 0 && withoutDuplicatesAndBlacklisted !== totalLeads

  const handleImportCsv = async (removeDuplicates, includeGloballyTargetedLeadsValue) => {
    setLoadingImportAll(true)
    const data = {
      removeDuplicates,
    }
    if (isCreateFlow && typeof includeGloballyTargetedLeadsValue !== "undefined") {
      data.includeGloballyTargetedLeads = includeGloballyTargetedLeadsValue
    }
    await importCsv(data)
    setLoadingImportAll(false)
  }

  const showComplexImportButtons =
    duplicatesDetected && (isCreateFlow || (!isCreateFlow && includeGloballyTargetedLeads))

  const handleRemoveDuplicates = () => {
    setRemoveDuplicatesFromCSV(!removeDuplicatesFromCSV)
  }

  const handleImport = () => {
    if (showComplexImportButtons) {
      if (!removeDuplicatesFromCSV) {
        handleImportCsv(false, true)
      } else {
        handleImportCsv(true, false)
      }
    } else {
      /**
       * Value of removeDuplicates flag should be true in the case when leads are imported
       * after the creation of the campaign, that has Global targeting turned on
       * (includeGloballyTargetedLeads is set to false); in all other cases, it should be false
       */
      handleImportCsv(!isCreateFlow && !includeGloballyTargetedLeads)
    }
  }

  return (
    <ModalWrapper
      footerVariant={FooterVariant.TRANSACTIONAL}
      primaryBtn={{
        label: tt("import"),
        onClick: () => {
          handleImport()
          setCSV(null)
        },
      }}
      secondaryBtn={{
        label: tt("cancel"),
        onClick: () => {
          dispatch(closeModal())
          dispatch(clearCsvInfo())
          setCSV(null)
          setShowImportLeadsModal(true)
        },
      }}
    >
      <div className="remove-duplicates">
        <span className="large">
          <LangText langKey="remove.duplicates.csv.file.label" />
        </span>
        <div className="ml-3">
          <ul className="csv-list">
            <li>
              <span className="list-color small">
                <LangText langKey="remove.duplicates.csv.leads.label" />
              </span>
              <span className="bold-color small-bold"> {totalLeads}</span>
            </li>
            <li>
              <span className="list-color small">
                <LangText langKey="remove.duplicates.csv.blacklists.label" />{" "}
              </span>
              <span className="bold-color small-bold"> {blacklistedItems}</span>
            </li>
            <li>
              <span className="list-color small">
                <LangText langKey="remove.duplicates.csv.campaigns.label" />
              </span>
              <span className="bold-color small-bold"> {teamDuplicates}</span>
            </li>
            <li>
              <span className="list-color small">
                <LangText langKey="remove.duplicates.csv.csv-file.label" />
              </span>
              <span className="bold-color small-bold"> {csvDuplicates}</span>
            </li>

            <li>
              <span className="list-color small">
                <LangText langKey="remove.duplicates.csv.total-leads.label" />
              </span>
              <span className="bold-color small-bold"> {withoutDuplicatesAndBlacklisted}</span>
            </li>
          </ul>
        </div>

        <div>
          {showComplexImportButtons && (
            <div className="duplicates-wrapper">
              <div className="duplicates-wrapper__info">
                <p className="large">
                  <LangText langKey="remove.duplicates.csv.duplicates.label" />
                </p>
                <p className="list-color small">
                  <LangText langKey="remove.duplicates.csv.duplicates.msg" />
                </p>
              </div>
              <div>
                <Switch
                  tt={tt}
                  initChecked={!removeDuplicatesFromCSV}
                  onChange={handleRemoveDuplicates}
                />
              </div>
            </div>
          )}
        </div>
        {duplicatesDetected && (
          <div>
            <div className="mt-3">
              <InlineNotification
                type={InlineNotificationVariant.WARNING}
                label={tt("remove.duplicates.csv.notification.label")}
              />
            </div>
          </div>
        )}
      </div>
    </ModalWrapper>
  )
}

// RemoveDuplicatesCsv.propTypes = {
//   blacklistedItems: PropTypes.number.isRequired,
//   csvDuplicates: PropTypes.number.isRequired,
//   teamDuplicates: PropTypes.number.isRequired,
//   importCsv: PropTypes.func.isRequired,
//   includeGloballyTargetedLeads: PropTypes.bool,
//   isCreateFlow: PropTypes.bool,
//   totalLeads: PropTypes.number.isRequired,
//   withoutDuplicatesAndBlacklisted: PropTypes.number.isRequired,
//   setCSV: PropTypes.func,
// }

export default RemoveDuplicatesCsv
