import { waitFor } from "@testing-library/react"
import _ from "lodash"

import { accountID, userID } from "../../mocks/data"
import { initialStateRedux } from "../../redux/reducers"
import { setInitialReduxStoreState, store } from "../../redux/store"
import { isEmptyObject, isExpectAny, isFile, isObject } from "../../utils/object-utils"

const expectPageRedirectLocation = redirectedURL => {
  expect(window.location.pathname).toBe(redirectedURL)
}

const notExpectPageRedirectLocation = redirectURL => {
  expect(window.location.pathname).not.toBe(redirectURL)
}

const waitForExpectedPageRedirectLocation = async redirectedURL => {
  await waitFor(() => {
    expect(window.location.pathname).toBe(redirectedURL)
  })
}

const expectToBeFalse = value => {
  expect(value).toBe(false)
}

const expectToBeTrue = value => {
  expect(value).toBe(true)
}

const expectToBeEmptyObject = value => {
  expect(value).toEqual({})
}

const expectFunctionHasNotBeenCalled = func => {
  expect(func).not.toHaveBeenCalled()
}

const expectFunctionHasBeenCalled = (func, numberOfCalls) => {
  expect(func).toHaveBeenCalledTimes(numberOfCalls)
}

const expectFunctionHasBeenCalledWithoutArgs = func => {
  expect(func).toHaveBeenCalledWith()
}

const expectFunctionHasBeenCalledWithArgs = (func, ...args) => {
  expect(func).toHaveBeenCalledWith(...args)
}

const expectReduxStateHasNotBeenChanged = () => {
  expect(store.getState()).toEqual(initialStateRedux)
}

const setUserID = userId => {
  setInitialReduxStoreState({ user: { profile: { id: userId } } })
}

const setAccountID = accountId => {
  setInitialReduxStoreState({ account: { activeAccountID: accountId } })
}

const setTeamID = teamId => {
  setInitialReduxStoreState({ teamManagement: { selectedTeam: { teamId } } })
}

const setDefaultUserAndAccountIds = (userId = userID, accountId = accountID) => {
  setUserID(userId)
  setAccountID(accountId)
}

const getNewReduxStateHelper = (newState, currentState = {}) => {
  for (const key in newState) {
    if (!isExpectAny(newState[key]) && !isFile(newState[key]) && isObject(newState[key])) {
      if (!currentState[key] || isEmptyObject(newState[key])) {
        Object.assign(currentState, { [key]: {} })
      }
      getNewReduxStateHelper(newState[key], currentState[key])
    } else {
      Object.assign(currentState, { [key]: newState[key] })
    }
  }
  return currentState
}

const getNewReduxState = (newState, currentStateParam = initialStateRedux) => {
  const currentState = _.cloneDeep(currentStateParam)
  return getNewReduxStateHelper(newState, currentState)
}

const expectReduxStateToBe = (newState = {}) => {
  const expectedState = getNewReduxState(newState)
  expect(store.getState()).toEqual(expectedState)
}

const expectReduxStateWithDefaultUserIdToBe = (newState = {}) => {
  const newStateWithId = {
    ...newState,
    user: { ...newState.user, profile: { ...newState.user?.profile, id: userID } },
  }
  const expectedState = getNewReduxState(newStateWithId)
  expect(store.getState()).toEqual(expectedState)
}

const expectReduxStateWithDefaultUserAndAccountIdsToBe = (
  newState = {},
  userId = userID,
  accountId = accountID,
) => {
  const newStateWithIds = {
    ...newState,
    user: { ...newState.user, profile: { ...newState.user?.profile, id: userId } },
    account: { ...newState.account, activeAccountID: accountId },
  }
  const expectedState = getNewReduxState(newStateWithIds)
  expect(store.getState()).toEqual(expectedState)
}

export {
  expectPageRedirectLocation,
  notExpectPageRedirectLocation,
  waitForExpectedPageRedirectLocation,
  expectToBeFalse,
  expectToBeTrue,
  expectToBeEmptyObject,
  expectFunctionHasNotBeenCalled,
  expectFunctionHasBeenCalled,
  expectFunctionHasBeenCalledWithoutArgs,
  expectFunctionHasBeenCalledWithArgs,
  expectReduxStateHasNotBeenChanged,
  setUserID,
  setAccountID,
  setTeamID,
  setDefaultUserAndAccountIds,
  getNewReduxState,
  expectReduxStateToBe,
  expectReduxStateWithDefaultUserIdToBe,
  expectReduxStateWithDefaultUserAndAccountIdsToBe,
}
