import ACTIONS from "../../constants/ACTIONS"
import statisticsService from "../../services/statistics-service"
import { validateCurves } from "../../utils/validation/validation-utils"

function getStatisticsSuccess(statistics) {
  return { type: ACTIONS.GET_STATISTICS, statistics }
}

function getStatisticsTotalCountSuccess(total) {
  return { type: ACTIONS.GET_STATISTICS_TOTAL_COUNT, total }
}

function getStatistics(from, to, curves, campaignId, timeZone, mailboxes, channels) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const filteredCurves = validateCurves(curves)
      if (filteredCurves.length > 0) {
        let query = `?from=${from}&to=${to}&curves=[${filteredCurves}]&timeZone=${timeZone}`
        if (campaignId !== 0 && campaignId !== "all") {
          query += `&campaignId=${campaignId}`
        }
        const mailboxIds = mailboxes.map(mailbox => mailbox.mailboxId)
        query += `&mailboxIds=${JSON.stringify(mailboxIds)}&channels=${JSON.stringify(channels)}`
        const statistics = await statisticsService.getStatistics(userID, accountID, query)
        dispatch(getStatisticsSuccess(statistics.data.result.dailyStatistic))
        dispatch(getStatisticsTotalCountSuccess(statistics.data.result.total))
      }
    } catch (error) {}
  }
}

function getCampaignStepsStatisticsSuccess(statistics) {
  return { type: ACTIONS.GET_CAMPAIGN_STEPS_STATISTICS, statistics }
}

function getCampaignStepsStatistics(campaignId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const statistics = await statisticsService.getCampaignStepsStatistics(
        userID,
        accountID,
        campaignId,
      )
      dispatch(getCampaignStepsStatisticsSuccess(statistics.data.result))
    } catch (error) {}
  }
}

export { getCampaignStepsStatistics, getStatistics }
