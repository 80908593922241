const DEFAULT_ROLES = ["Owner", "Admin", "Editor"]

const TEAMS_PAGE_TABS = tt => ({
  SEATS: {
    key: "SEATS",
    title: tt("seats"),
  },
  TEAM_MEMBERS: {
    key: "TEAM_MEMBERS",
    title: tt("team-members"),
  },
  ROLES_AND_PERMISSIONS: {
    key: "ROLES_AND_PERMISSIONS",
    title: tt("roles-and-permissions"),
  },
  TEAM_BLACKLIST: {
    key: "TEAM_BLACKLIST",
    title: tt("team-blacklist"),
  },
})

const teamManagementConstants = tt => ({
  TEAMS_PAGE_TABS: TEAMS_PAGE_TABS(tt),
})

export default teamManagementConstants
export { DEFAULT_ROLES }
