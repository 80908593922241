import { LoadingPage } from "@skylead/component-library"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"

import { getUserData } from "../../../redux/actions"

const GuestUserRoute = ({ element, redirectAuthUser = false }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const { id } = useSelector(state => state.user.profile)

  useEffect(() => {
    const initializeData = async () => {
      await dispatch(getUserData(true))
      setLoading(false)
    }
    initializeData()
  }, [dispatch])

  useEffect(() => {
    if (!!id && redirectAuthUser) {
      navigate("/")
    }
  }, [id, redirectAuthUser, navigate])

  if (loading) return <LoadingPage />

  return id && redirectAuthUser ? <Navigate to="/" /> : element
}

// GuestUserRoute.propTypes = {
//   element: PropTypes.element.isRequired,
//   redirectAuthUser: PropTypes.bool,
// }

export default GuestUserRoute
