import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"

import accountReducer, { initialStateAccountReducer } from "./account/accountReducer"
import appReducer, { initialStateAppReducer } from "./app/appReducer"
import blacklistReducer, { initialStateBlacklistReducer } from "./blacklist/blacklistReducer"
import campaignReducer, { initialStateCampaignReducer } from "./campaign/campaignReducer"
import chatReducer, { initialStateChatReducer } from "./chat/chatReducer"
import emailReducer, { initialStateEmailReducer } from "./emails/emailReducer"
import formsReducer, { initialStateFormsReducer } from "./forms/formsReducer"
import imagePersonalizationReducer, {
  initialStatePersinalizationReducer,
} from "./imagePersonalization/imagePersonalizationReducer"
import paymentReducer, { initialStatePaymentReducer } from "./payment/paymentReducer"
import statisticsReducer, { initialStateStatisticsReducer } from "./statistics/statisticsReducer"
import teamManagementReducer, {
  initialStateTeamManagementReducer,
} from "./team-management/teamManagementReducer"
import translationReducer, {
  initialStateTranslationReducer,
} from "./translation/translationReducer"
import userReducer, { initialStateUserReducer } from "./user/userReducer"

export default history =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    user: userReducer,
    account: accountReducer,
    forms: formsReducer,
    payment: paymentReducer,
    campaign: campaignReducer,
    chat: chatReducer,
    imagePersonalization: imagePersonalizationReducer,
    statistics: statisticsReducer,
    teamManagement: teamManagementReducer,
    blacklist: blacklistReducer,
    email: emailReducer,
    translation: translationReducer,
  })

const initialRouterReducer = {
  action: "POP",
  location: {
    hash: "",
    pathname: "/",
    query: {},
    search: "",
    state: undefined,
  },
}

export const initialStateRedux = {
  router: initialRouterReducer, // TODO PP@Any: Check route reducer initial state
  app: initialStateAppReducer,
  user: initialStateUserReducer,
  account: initialStateAccountReducer,
  forms: initialStateFormsReducer,
  payment: initialStatePaymentReducer,
  campaign: initialStateCampaignReducer,
  chat: initialStateChatReducer,
  imagePersonalization: initialStatePersinalizationReducer,
  statistics: initialStateStatisticsReducer,
  teamManagement: initialStateTeamManagementReducer,
  blacklist: initialStateBlacklistReducer,
  email: initialStateEmailReducer,
  translation: initialStateTranslationReducer,
}
