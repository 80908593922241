import "./CardWrapper.scss"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"

import config from "../../../utils/config"

const stripePromise = loadStripe(config.REACT_APP_STRIPE_API_KEY)

const CardWrapper = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>
}

// CardWrapper.propTypes = {
//   children: PropTypes.instanceOf(Array).isRequired,
// }

export default React.memo(CardWrapper)
