import { Prompt as SLPrompt } from "@skylead/component-library"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { closePrompt } from "../../../redux/actions"
import { useGlobalTranslation } from "../../../utils/hook"

const Prompt = () => {
  const dispatch = useDispatch()
  const { tt } = useGlobalTranslation()
  const cancelText = tt("cancel") || "Cancel"
  const {
    show,
    type,
    title,
    text,
    secondaryBtnText = cancelText,
    secondaryBtnOnClick = () => dispatch(closePrompt()),
    primaryBtnText = cancelText,
    primaryBtnOnClick = () => dispatch(closePrompt()),
    noHideOnSubmit = false,
    primaryBtnType = undefined,
    showSecondaryBtn = true,
  } = useSelector(state => state.app.promptData)

  return (
    <SLPrompt
      closePrompt={() => dispatch(closePrompt())}
      tt={tt}
      show={show}
      type={type}
      title={title}
      text={text}
      secondaryBtnText={secondaryBtnText}
      secondaryBtnOnClick={secondaryBtnOnClick}
      primaryBtnText={primaryBtnText}
      primaryBtnOnClick={primaryBtnOnClick}
      noHideOnSubmit={noHideOnSubmit}
      primaryBtnType={primaryBtnType}
      showSecondaryBtn={showSecondaryBtn}
    />
  )
}

// Prompt.propTypes = {}

export default React.memo(Prompt)
