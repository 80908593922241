import ACTIONS from "../../constants/ACTIONS"
import { initialAllMessages, initialSelectedConversation } from "../../constants/chat-constants"

const initialState = {
  conversations: [],
  conversationsCount: 0,
  selectedConversation: initialSelectedConversation,
  allChatLabels: [],
  leadsChatLabels: [],
  lastGotBasicMessagesUpdate: null,
  lastGotSalesMessagesUpdate: null,
  linkedinUserMessages: {},
  campaignID: "all",
  selectedChannel: 1,
  canReplyToEmailThread: null,
}

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.RELOAD_CHAT_STATE: {
      sessionStorage.setItem("lastChatUrl", "")

      return {
        ...initialState,
      }
    }
    case ACTIONS.CLEAR_FILTERED_ITEMS: {
      return {
        ...state,
        conversations: [],
        conversationsCount: 0,
        linkedinUserMessages: {},
      }
    }
    case ACTIONS.SET_CONVERSATIONS: {
      return {
        ...state,
        conversations: action.conversations,
      }
    }

    case ACTIONS.GET_CAMPAIGN_MESSAGES: {
      return {
        ...state,
        conversations: [...state.conversations, ...action.conversations],
        conversationsCount: [...state.conversations, ...action.conversations].length,
        campaignID: action.campaignID,
      }
    }

    case ACTIONS.UPDATE_CAMPAIGN_ID: {
      return {
        ...state,
        campaignID: action.campaignID,
      }
    }

    case ACTIONS.REPLACE_CAMPAIGN_MESSAGES: {
      return {
        ...state,
        conversations: action.conversations,
        conversationsCount: action.conversationsCount,
      }
    }

    case ACTIONS.REPLACE_CHANNEL: {
      return {
        ...state,
        selectedConversation: action.selectedConversation,
      }
    }

    case ACTIONS.GET_BELONGED_LEADS_FOR_THREAD: {
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          belongedLeads: action.belongedLeads,
        },
      }
    }

    case ACTIONS.GET_LINKEDIN_USER_MESSAGES: {
      return {
        ...state,
        linkedinUserMessages: action.linkedinUserMessages,
      }
    }

    case ACTIONS.REMOVE_LINKEDIN_USER_MESSAGE: {
      return {
        ...state,
        linkedinUserMessages: action.linkedinUserMessages,
      }
    }

    case ACTIONS.ADD_LABEL_TO_LEAD: {
      const newSelectedConversationLabels = state.selectedConversation?.labels
      newSelectedConversationLabels.push(action.label)

      const newConversations = state.conversations?.map(conversation => {
        const newConversation = { ...conversation }
        if (conversation.lead.id === state.selectedConversation.lead.id) {
          newConversation.labels = newSelectedConversationLabels
        }
        return newConversation || conversation
      })

      return {
        ...state,
        conversations: newConversations,
        selectedConversation: {
          ...state.selectedConversation,
          labels: newSelectedConversationLabels,
        },
      }
    }

    case ACTIONS.REMOVE_LABEL_FROM_LEAD: {
      const newConversations = state.conversations?.map(conversation => {
        if (conversation.lead.id === state.selectedConversation.lead.id) {
          conversation.labels = [
            ...conversation.labels?.filter(label => label.id !== action.labelId),
          ]
        }
        return conversation
      })

      const newSelectedConversationLabels = state.selectedConversation?.labels?.filter(
        label => label.id !== action.labelId,
      )
      return {
        ...state,
        conversations: newConversations,
        selectedConversation: {
          ...state.selectedConversation,
          labels: newSelectedConversationLabels,
        },
      }
    }

    case ACTIONS.GET_ALL_CHAT_LABELS: {
      return {
        ...state,
        allChatLabels: action.allChatLabels,
      }
    }

    case ACTIONS.GET_LEADS_CHAT_LABELS: {
      return {
        ...state,
        leadsChatLabels: action.leadsChatLabels,
      }
    }

    case ACTIONS.GET_LAST_FETCHED_CONVERSATION_TIMESTAMP: {
      return {
        ...state,
        lastGotBasicMessagesUpdate: action.lastGotBasicMessagesUpdate,
        lastGotSalesMessagesUpdate: action.lastGotSalesMessagesUpdate,
      }
    }

    case ACTIONS.SELECT_CHAT: {
      return {
        ...state,
        selectedConversation: {
          ...action.selectedConversation,
          allMessages: initialAllMessages,
        },
      }
    }

    case ACTIONS.LOADING_MESSAGES: {
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          allMessages: {
            ...state.selectedConversation.allMessages,
            loadingChatMessages: action.loadingChatMessages,
            isFetched: true,
          },
        },
      }
    }

    case ACTIONS.SET_MESSAGES: {
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          allMessages: {
            messages: action.payload.messages,
            channels: !action.payload.keepChannels
              ? []
              : state.selectedConversation.allMessages.channels,
            loadingChatMessages: false,
          },
        },
      }
    }

    case ACTIONS.ADD_NEW_MESSAGE: {
      return action.newMessage.thread === state.selectedChannel.thread
        ? {
            ...state,
            selectedConversation: {
              ...state.selectedConversation,
              allMessages: {
                ...state.selectedConversation.allMessages,
                messages: [...state.selectedConversation.allMessages.messages, action.newMessage],
              },
            },
          }
        : state
    }

    case ACTIONS.DELETE_MESSAGE: {
      const { allMessages } = state.selectedConversation
      return action.payload.thread === state.selectedConversation.thread
        ? {
            ...state,
            selectedConversation: {
              ...state.selectedConversation,
              allMessages: {
                ...allMessages,
                messages: allMessages.messages.filter(msg => msg.id !== action.payload.messageID),
              },
            },
          }
        : state
    }

    case ACTIONS.SET_CHANNELS: {
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          allMessages: { ...state.selectedConversation.allMessages, channels: action.channels },
        },
      }
    }

    case ACTIONS.DESELECT_CHAT: {
      return {
        ...state,
        selectedConversation: initialState.selectedConversation,
      }
    }

    case ACTIONS.SELECT_CHANNEL: {
      return {
        ...state,
        selectedChannel: action.channel,
        selectedConversation: { ...state.selectedConversation, thread: action.channel.thread },
      }
    }

    case ACTIONS.GET_LEAD_INFO: {
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          lead: action.leadInfo,
        },
      }
    }

    case ACTIONS.CLEAR_DELETED_TAG: {
      const newConversations = state.conversations?.map(conversation => {
        const newConversation = { ...conversation }
        newConversation.labels = [
          ...newConversation.labels?.filter(label => label.id !== action.labelId),
        ]
        return newConversation
      })

      const newSelectedConversationLabels = state.selectedConversation?.labels?.filter(
        label => label.id !== action.labelId,
      )

      return {
        ...state,
        conversations: newConversations,
        selectedConversation: {
          ...state.selectedConversation,
          labels: newSelectedConversationLabels,
        },
      }
    }

    case ACTIONS.USER_LOGOUT: {
      return initialState
    }

    case ACTIONS.ADD_CAMPAIGN_ID_TO_LEAD: {
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          lead: {
            ...state.selectedConversation.lead,
            campaignId: action.payload,
          },
        },
      }
    }

    case ACTIONS.SET_LINKEDIN_USER_MESSAGE: {
      const linkedinUserMessages = { ...state.linkedinUserMessages, ...action.linkedinUserMessages }
      return {
        ...state,
        linkedinUserMessages,
      }
    }

    case ACTIONS.CLEAR_CAN_REPLY_TO_EMAIL_THREAD: {
      return {
        ...state,
        canReplyToEmailThread: null,
      }
    }

    case ACTIONS.GET_MESSAGES_FOR_LINKEDIN_USER: {
      return {
        ...state,
        linkedinUserMessages: action.payload,
      }
    }

    case ACTIONS.SET_CAN_REPLY_TO_EMAIL_THREAD: {
      return {
        ...state,
        canReplyToEmailThread: action.payload,
      }
    }

    case ACTIONS.REPLACE_SELECTED_CONVERSATION_LABELS: {
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          labels: action.payload.labels,
        },
      }
    }

    // Use this only for tests
    case ACTIONS.CHANGE_CHAT_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return { ...state }
  }
}

export { initialState as initialStateChatReducer }
