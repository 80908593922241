import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  activeAccountID: null,
  settingsData: {},
  runOnWeekends: false,
  pendingConnections: false,
  allWebhooks: { rows: [], count: 0 },
  accountNotifications: { count: 0, notifications: [], unseen: 0 },
  accountData: {},
  proxyInfo: {},
  globalStatus: null,
  pinTimestamp: null,
  stream: {
    url: "",
    password: "",
    status: "free",
    created: false,
  },
  handleOutOfOfficeEmails: null,
  weekendOptions: {},
}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_ACTIVE_ACCOUNT_ID: {
      return {
        ...state,
        activeAccountID: action.accountID,
      }
    }

    case ACTIONS.GET_SETTING_DATA: {
      return {
        ...state,
        settingsData: action.settingsData,
      }
    }

    case ACTIONS.GET_PENDING_CONNECTIONS: {
      return {
        ...state,
        pendingConnections: action.pendingConnections,
      }
    }

    case ACTIONS.GET_ALL_WEBHOOKS: {
      return {
        ...state,
        allWebhooks: action.allWebhooks,
      }
    }

    case ACTIONS.GET_ACCOUNT_NOTIFICATIONS: {
      return {
        ...state,
        accountNotifications: action.accountNotifications,
      }
    }

    case ACTIONS.SEEN_ACCOUNT_NOTIFICATIONS: {
      const allNewNotifications = {
        count: state.accountNotifications.count,
        notifications: state.accountNotifications.notifications.map(notif => {
          if (action.notificationIds.includes(notif.id)) {
            return { ...notif, seen: true }
          }
          return { ...notif }
        }),
        unseen: state.accountNotifications.unseen - action.notificationIds.length,
      }
      return {
        ...state,
        accountNotifications: allNewNotifications,
      }
    }

    case ACTIONS.GET_ACTIVE_ACCOUNT: {
      return {
        ...state,
        accountData: action.accountData,
      }
    }

    case ACTIONS.GET_ACCOUNT_PROXY_INFO: {
      return {
        ...state,
        proxyInfo: action.proxyInfo,
      }
    }

    case ACTIONS.SET_ACCOUNT_HEALTH: {
      return {
        ...state,
        settingsData: { ...state.settingsData, maxPendingAmount: action.maxPendingAmount },
      }
    }

    case ACTIONS.GET_GLOBAL_STATUS: {
      return {
        ...state,
        globalStatus: action.globalStatus.globalStatusId,
        pinTimestamp: action.globalStatus.pinTimestamp,
      }
    }

    case ACTIONS.START_STREAMING: {
      return {
        ...state,
        stream: {
          ...state.stream,
          status: "starting",
        },
      }
    }

    case ACTIONS.STREAM_CREATED: {
      return {
        ...state,
        stream: {
          ...state.stream,
          ...action.stream,
          created: true,
        },
      }
    }

    case ACTIONS.FREE_STREAM: {
      return {
        ...state,
        stream: {
          url: "",
          password: "",
          status: "free",
          created: false,
        },
      }
    }

    case ACTIONS.STREAM_SUCCESS: {
      return {
        ...state,
        stream: {
          ...state.stream,
          status: "success",
        },
      }
    }
    case ACTIONS.STREAM_ERROR: {
      return {
        ...state,
        stream: {
          ...state.stream,
          status: "error",
        },
      }
    }

    case ACTIONS.GET_OUT_OF_OFFICE_EMAILS: {
      return {
        ...state,
        handleOutOfOfficeEmails: action.handleOutOfOfficeEmails,
      }
    }

    case ACTIONS.USER_LOGOUT: {
      return initialState
    }

    case ACTIONS.GET_RUN_ON_WEEKENDS: {
      return {
        ...state,
        weekendOptions: action.weekendOptions,
      }
    }

    // Use this only for tests
    case ACTIONS.CHANGE_ACCOUNT_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return { ...state }
  }
}

export { initialState as initialStateAccountReducer }
