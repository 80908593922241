import "./WhitelabelThemeSwitcher.scss"

import classNames from "classnames"
import React, { useState } from "react"

import { loadExternalENVUrlOldWay } from "../../../utils/whitelabel-utils"
import Button from "../../atoms/Button"

const WhitelabelThemeSwitcher = () => {
  let initialIndex = 0
  const initialUrl = localStorage.getItem("whitelabelUrl")
  if (initialUrl) {
    const splitedFirst = initialUrl ? initialUrl.split("whitelabel-env/") : []

    if (splitedFirst.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      initialIndex = splitedFirst[1]
    }
  }

  const [index, setIndex] = useState(+initialIndex)
  const whitelabelThemeSwitcherClass = classNames("whitelabel-theme-switcher-wrapper")

  const changeWhitelabel = (newIndex, url) => {
    setIndex(+newIndex)

    const newUrl = url || `http://localhost:3002/api/backoffice/v1/whitelabel-env/${newIndex}`
    loadExternalENVUrlOldWay(newUrl)
  }

  const hasPrevious = index > 0
  const previousWl = async () => {
    if (hasPrevious) {
      const newIndex = index - 1
      const url = undefined

      changeWhitelabel(newIndex, url)
    }
  }

  const hasNext = index < 200
  const nextWl = async () => {
    if (hasNext) {
      const newIndex = index + 1
      const url = undefined

      changeWhitelabel(newIndex, url)
    }
  }

  const clearWhitelabel = () => {
    localStorage.removeItem("whitelabelUrl")
    localStorage.removeItem(`whitelabelUrlTimestamp`)
    window.location.reload()
  }

  return (
    <div className={whitelabelThemeSwitcherClass}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="text-center" style={{ color: "#fff" }}>
          {index}
        </div>
        <Button onClick={clearWhitelabel}>X</Button>
      </div>
      <div className="d-flex">
        <Button onClick={previousWl} disabled={!hasPrevious}>
          {"<"}
        </Button>
        <Button onClick={nextWl} disabled={!hasNext}>
          {">"}
        </Button>
      </div>
    </div>
  )
}

WhitelabelThemeSwitcher.propTypes = {}

export default WhitelabelThemeSwitcher
