import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  pricingPlans: [],
  availableSeats: [],
  accountSpecificPrices: [],
  allSubscriptions: [],
  pricePreview: {},
  savedCard: {},
  paymentCustomer: undefined,
  teamsWithPayment: [],
}

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_PAYMENT_PLANS: {
      return {
        ...state,
        pricingPlans: action.paymentPlans,
      }
    }
    case ACTIONS.GET_AVAILABLE_SEATS_FOR_TEAM: {
      return {
        ...state,
        availableSeats: action.availableSeats,
      }
    }
    case ACTIONS.GET_ALL_SUBSCRIPTIONS: {
      return {
        ...state,
        allSubscriptions: action.allSubscriptions,
      }
    }
    case ACTIONS.GET_LAST_USED_CARD: {
      return {
        ...state,
        savedCard: action.savedCard,
      }
    }

    case ACTIONS.GET_PAYMENT_CUSTOMER: {
      return {
        ...state,
        paymentCustomer: action.customer,
      }
    }

    case ACTIONS.GET_PRICE_PREVIEW: {
      return {
        ...state,
        pricePreview: action.pricePreview,
      }
    }

    case ACTIONS.GET_ACCOUNT_SPECIFIC_PRICES: {
      return {
        ...state,
        accountSpecificPrices: action.accountSpecificPrices,
      }
    }

    case ACTIONS.GET_TEAMS_WITH_PAYMENT: {
      return {
        ...state,
        teamsWithPayment: action.teams,
      }
    }

    // Use this only for tests
    case ACTIONS.CHANGE_PAYMENT_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case ACTIONS.USER_LOGOUT:
      return initialState

    default:
      return { ...state }
  }
}

export { initialState as initialStatePaymentReducer }
