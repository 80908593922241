import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  linkedinBlacklist: {
    blacklists: [],
    count: 0,
    sumCount: 0,
  },
  emailBlacklist: {
    blacklists: [],
    count: 0,
    sumCount: 0,
  },
  globalLinkedinBlacklist: {
    blacklists: [],
    count: 0,
    sumCount: 0,
  },
  globalEmailBlacklist: {
    blacklists: [],
    count: 0,
  },
}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_LINKEDIN_BLACKLIST: {
      return {
        ...state,
        linkedinBlacklist: {
          blacklist: action.payload.blacklists,
          count: action.payload.count,
          sumCount: action.payload.sumCount,
        },
      }
    }

    case ACTIONS.GET_EMAIL_BLACKLIST: {
      return {
        ...state,
        emailBlacklist: {
          blacklist: action.payload.blacklists,
          count: action.payload.count,
          sumCount: action.payload.sumCount,
        },
      }
    }

    case ACTIONS.GET_GLOBAL_LINKEDIN_BLACKLIST: {
      return {
        ...state,
        globalLinkedinBlacklist: {
          blacklist: action.payload.blacklists,
          count: action.payload.count,
          sumCount: action.payload.sumCount,
        },
      }
    }

    case ACTIONS.GET_GLOBAL_EMAIL_BLACKLIST: {
      return {
        ...state,
        globalEmailBlacklist: {
          blacklist: action.payload.blacklists,
          count: action.payload.count,
          sumCount: action.payload.sumCount,
        },
      }
    }

    // Use this only for tests
    case ACTIONS.CHANGE_BLACKLIST_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return { ...state }
  }
}

export { initialState as initialStateBlacklistReducer }
