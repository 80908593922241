function getPropByString(o = {}, s = "") {
  let newString = s
  let newObject = o
  newString = newString.replace(/\[(\w+)\]/g, "__$1") // convert indexes to properties
  newString = newString.replace(/^__/, "") // strip a leading __
  const a = newString.split("__")
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i]
    try {
      if (k in newObject) {
        newObject = newObject[k]
      } else {
        return
      }
    } catch (e) {
      return
    }
  }

  return newObject
}

export const isFile = item => {
  return item instanceof File
}

export const isExpectAny = item => {
  return item?.asymmetricMatch !== undefined
}

export const isEmptyObject = obj => {
  return Object.keys(obj).length === 0
}

export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item)
}

export default { getPropByString }
