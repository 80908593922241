/* eslint-disable import/no-cycle */
import { toast } from "@skylead/component-library"
import { timezones } from "react-timezone"

import ACTIONS from "../../constants/ACTIONS"
import accountService from "../../services/account-service"
import paymentService from "../../services/payment-service"
import { hideInfoModal, setModalData } from "../app/appActions"
import { getEmailAccounts } from "../emails/emailAction"
import { clearForm, updateFormField } from "../forms/formsActions"
import { getAvailableSeatsForTeam } from "../payment/paymentActions"
import { store } from "../store"
import { getTeamAccounts, getTeamForUser } from "../team-management/teamManagementActions"

function getAccountDataSuccess(accountData) {
  return { type: ACTIONS.GET_ACTIVE_ACCOUNT, accountData }
}

function getAccountData() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const accountData = await accountService.getAccountData(userID, accountID)
      dispatch(getAccountDataSuccess(accountData.data))

      return accountData.data
    } catch (error) {}
  }
}

function changeAccountName(tt, name) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.changeAccountName(userID, accountID, name)
      toast.success(tt("account-name-successfully-changed"))
      await dispatch(getTeamAccounts())
    } catch (error) {}
  }
}

function updateLinkedinCredentials(email, password) {
  return async (dispatch, getState) => {
    try {
      const accountID = getState().account.activeAccountID
      const userID = getState().user.profile.id
      await accountService.updateLinkedinCredentials(userID, accountID, email, password)

      dispatch(getTeamAccounts())
      return true
    } catch (error) {
      return false
    }
  }
}

function loginAccount() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.loginAccount(userID, accountID)
    } catch (error) {}
  }
}

function verifyPin(pin) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.verifyLinkedinPin(userID, accountID, pin)
      dispatch(getTeamAccounts())
      return true
    } catch (error) {
      return false
    }
  }
}

function verifyTwoFactorAuth(twoFactorAuth) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.verifyLinkedinTwoFactorAuth(userID, accountID, twoFactorAuth)
      dispatch(getTeamAccounts())
      return true
    } catch (error) {
      return false
    }
  }
}

function resendLinkedinPin(tt, accountID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await accountService.resendLinkedinPin(userID, accountID)
      toast.success(tt("pin-verification-email-successfully-resent"))
      return true
    } catch (error) {
      toast.error(tt("pin-verification-email-resend-error"))
      return false
    }
  }
}

function deleteSeat(tt, reason, callback) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const { teamId } = getState().teamManagement.selectedTeam
      await accountService.deleteSeat(userID, accountID, { reason })
      await paymentService.cancelSubscriptionReason(userID, { reason })
      dispatch(clearForm())
      await callback()
      await dispatch(getTeamForUser(teamId))
      await dispatch(getTeamAccounts())
      toast.success(tt("seat-successfully-deleted"))
    } catch (error) {}
  }
}

function setActiveAccountID(accountID) {
  return { type: ACTIONS.SET_ACTIVE_ACCOUNT_ID, accountID }
}

function changeLinkedinSubscription(tt, linkedinSubscriptionId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.changeLinkedinSubscription(userID, accountID, {
        linkedinSubscriptionId: Number(linkedinSubscriptionId),
      })
      dispatch(getTeamAccounts())
      dispatch(getAccountData())
      toast.success(tt("linkedin-subscription-successfully-changed"))
      return true
    } catch (error) {
      return false
    }
  }
}

function reactivateAccount(tt, accountID, isDefaultProxy, selectedProxy, customProxyInfo) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const data = {}

      if (typeof isDefaultProxy !== "undefined") {
        data.setupProxyType = isDefaultProxy ? "BUY" : "CUSTOM_PROXY"
      }

      if (isDefaultProxy) {
        data.countryCode = selectedProxy
      } else {
        data.proxyInfo = customProxyInfo
      }

      await accountService.reactivateAccount(userID, accountID, data)
      Promise.all([dispatch(getTeamAccounts()), dispatch(getAvailableSeatsForTeam())])
      toast.success(tt("account-successfully-reactivated"))
    } catch (error) {}
  }
}

function getAccountProxySuccess(proxyInfo) {
  return { type: ACTIONS.GET_ACCOUNT_PROXY_INFO, proxyInfo }
}

function getAccountProxy(accountID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const proxyInfo = await accountService.getAccountProxy(userID, accountID)

      dispatch(getAccountProxySuccess(proxyInfo.data))
    } catch (error) {}
  }
}

function authenticateSMTP(
  tt,
  redirectUrl,
  userID = store.getState().user.profile.id,
  accountID = store.getState().account.activeAccountID,
  shouldRefreshAccounts,
) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms

      const data = {
        type: "smtp",
        accountId: accountID,
        senderEmail: formData["smtp-sender-email"].trim(),
        name: formData["smtp-sender-name"].trim(),
        user: formData["smtp-username"].trim(),
        pass: formData["smtp-password"],
        host: formData["smtp-ip"].trim(),
        port: formData["smtp-port"].trim(),
        redirectUrl,
        certificate: formData.smtpSsl ? "tls" : "none",
        isAuthentication: !!formData.smtpSsl,
        userImap: formData["imap-username"].trim(),
        passImap: formData["imap-password"],
        hostImap: formData["imap-ip"].trim(),
        portImap: formData["imap-port"].trim(),
        certificateImap: formData.smtpSsl ? "tls" : "none",
        isAuthenticationImap: !!formData.smtpSsl,
      }
      await accountService.authenticateSMTP(userID, accountID, data)
      dispatch(getEmailAccounts())
      if (shouldRefreshAccounts) {
        dispatch(getTeamAccounts())
      }
      dispatch(clearForm())
      toast.success(tt("smtp-successfully-connected"))
      dispatch(hideInfoModal())
    } catch (error) {}
  }
}

function testSMTP(
  userID = store.getState().user.profile.id,
  accountID = store.getState().account.activeAccountID,
) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms

      const data = {
        userName: formData["smtp-username"],
        password: formData["smtp-password"],
        host: formData["smtp-ip"],
        port: formData["smtp-port"],
        certificate: formData.smtpSsl ? "tls" : "none",
        isAuthentication: !!formData.smtpSsl,
      }

      const { data: responseData } = await accountService.testSMTP(userID, accountID, data)
      const { status } = responseData
      dispatch(updateFormField("smtpTest", status))

      return status
    } catch (error) {
      dispatch(updateFormField("smtpTest", false))
      return false
    }
  }
}

function testImap(
  userID = store.getState().user.profile.id,
  accountID = store.getState().account.activeAccountID,
) {
  return async (dispatch, getState) => {
    try {
      const { formData } = getState().forms

      const data = {
        userName: formData["imap-username"],
        password: formData["imap-password"],
        host: formData["imap-ip"],
        port: formData["imap-port"],
        certificateImap: formData.smtpSsl ? "tls" : "none",
        isAuthenticationImap: !!formData.smtpSsl,
      }

      const { data: responseData } = await accountService.testImap(userID, accountID, data)
      const { status } = responseData

      dispatch(updateFormField("imapTest", status))

      return status
    } catch (error) {
      dispatch(updateFormField("imapTest", false))
      return false
    }
  }
}

function sendEmail(
  tt,
  recipient,
  subject,
  content,
  emailSignature = undefined,
  bccRecipients,
  mailboxId,
) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const data = {
        subject,
        signatureId: emailSignature !== "noSignature" ? emailSignature : undefined,
        recipient,
        content,
        bccRecipients,
        mailboxId: [mailboxId],
      }
      await accountService.sendEmail(userID, accountID, data)
      toast.success(tt("email-sent"))
      return true
    } catch (error) {
      toast.error(tt("email-failed-to-send"))
      return false
    }
  }
}

function connectLinkedinAccount(
  tt,
  accountID,
  isDefaultProxy,
  selectedProxy,
  customProxyInfo,
  linkedinEmail,
  linkedinPassword,
  countryCode,
  setupProxyType,
  proxyInfo,
) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const linkedinSubscriptionId = getState().forms.formData["linkedin-subscription"] || 1

      const data = {
        linkedinEmail,
        linkedinPassword,
        countryCode,
        setupProxyType,
        proxyInfo,
        linkedinSubscriptionId: +linkedinSubscriptionId,
      }

      if (typeof isDefaultProxy !== "undefined") {
        data.setupProxyType = isDefaultProxy ? "BUY" : "CUSTOM_PROXY"
      }

      if (isDefaultProxy) {
        data.countryCode = selectedProxy
      } else {
        data.proxyInfo = customProxyInfo
      }

      await accountService.connectLinkedinAccount(userID, accountID, data)
      await dispatch(getTeamAccounts())

      toast.success(tt("linkedin-account-successfully-integrated"))
      return true
    } catch (error) {
      await dispatch(getTeamAccounts())
      return false
    }
  }
}

function disconnectLinkedinAccount(tt, accountID) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      await accountService.disconnectLinkedinAccount(userID, accountID)
      toast.success(tt("linkedin-account-successfully-disconnected"))
      return true
    } catch (error) {
      return false
    }
  }
}

function getGlobalStatusForAccountSuccess(accountID, globalStatus) {
  return { type: ACTIONS.GET_GLOBAL_STATUS_FOR_ACCOUNT, accountID, globalStatus }
}

function getGlobalStatusSuccess(accountID, globalStatus) {
  return { type: ACTIONS.GET_GLOBAL_STATUS, accountID, globalStatus }
}

function getGlobalStatus() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const response = await accountService.getGlobalStatus(userID, accountID)
      await dispatch(getGlobalStatusSuccess(accountID, response.data))
      await dispatch(getGlobalStatusForAccountSuccess(accountID, response.data))
    } catch (error) {
      return false
    }
  }
}

function startStreaming() {
  return { type: ACTIONS.START_STREAMING }
}

function streamCreated(stream) {
  return { type: ACTIONS.STREAM_CREATED, stream }
}

function freeStream() {
  return { type: ACTIONS.FREE_STREAM }
}

function streamLinkedinOpenProfile(tt) {
  return async (dispatch, getState) => {
    try {
      dispatch(startStreaming())
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const stream = await accountService.streamLinkedinOpenProfile(userID, accountID)
      await dispatch(streamCreated(stream.data))
    } catch (error) {
      toast.error(tt("something-went-wrong-with-stream"))
      await dispatch(freeStream())
    }
  }
}

function endStream(saveCookiesAndLocalStorage) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.endStream(userID, accountID, saveCookiesAndLocalStorage)
      await dispatch(freeStream())
    } catch (error) {
      await dispatch(freeStream())
    }
  }
}

function streamLinkedinLogin(tt) {
  return async (dispatch, getState) => {
    try {
      dispatch(startStreaming())
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const stream = await accountService.streamLinkedinLogin(userID, accountID)
      await dispatch(streamCreated(stream.data))
    } catch (error) {
      toast.error(tt("something-went-wrong-with-stream"))
      await dispatch(setModalData({ previousData: {}, show: false }))
      await dispatch(freeStream())
    }
  }
}

function getTimezone() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const result = await accountService.getTimezone(userID, accountID)
      const foundZone = timezones.find(timezone => timezone.name === result.data.result.timeZone)

      dispatch(updateFormField("selectedTimezone", foundZone))
      return true
    } catch (error) {
      return false
    }
  }
}

function changeTimezone(tt, data) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await accountService.changeTimezone(userID, accountID, data)
      toast.success(tt("time-zone-successfully-changed"))
      return true
    } catch (error) {
      return false
    }
  }
}

function getHandleOutOfOfficeEmailsSuccess(handleOutOfOfficeEmails) {
  return { type: ACTIONS.GET_OUT_OF_OFFICE_EMAILS, handleOutOfOfficeEmails }
}

function getHandleOutOfOfficeEmails() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const { data: responseData } = await accountService.getHandleOutOfOfficeEmails(
        userID,
        accountID,
      )
      await dispatch(getHandleOutOfOfficeEmailsSuccess(responseData.result.handleOutOfOfficeEmails))
      return true
    } catch (error) {
      return false
    }
  }
}

function setHandleOutOfOfficeEmails(tt, isOutOfOffice) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      const data = {
        handleOutOfOfficeEmails: isOutOfOffice,
      }
      await accountService.setHandleOutOfOfficeEmails(userID, accountID, data)
      await dispatch(getHandleOutOfOfficeEmails())
      toast.success(tt("changes-successfully-submitted"))
      return true
    } catch (error) {
      return false
    }
  }
}

export {
  getAccountData,
  changeAccountName,
  updateLinkedinCredentials,
  loginAccount,
  verifyPin,
  verifyTwoFactorAuth,
  resendLinkedinPin,
  deleteSeat,
  setActiveAccountID,
  changeLinkedinSubscription,
  reactivateAccount,
  getAccountProxy,
  authenticateSMTP,
  testSMTP,
  testImap,
  sendEmail,
  connectLinkedinAccount,
  disconnectLinkedinAccount,
  getGlobalStatus,
  streamLinkedinOpenProfile,
  endStream,
  streamLinkedinLogin,
  getTimezone,
  changeTimezone,
  getHandleOutOfOfficeEmails,
  setHandleOutOfOfficeEmails,
}
