import { Button as SLButton, ButtonVariant } from "@skylead/component-library"
import React from "react"
import { useSelector } from "react-redux"

import userBehaviourUtils from "../../../utils/userBehaviour-utils"

const Button = ({
  variant = ButtonVariant.PRIMARY,
  type = "submit",
  onClick = () => {},
  children,
  disabled = false,
  icon = "",
  hasMenuIcon = false,
  margin = "",
  center = false,
  loading = false,
  hasLoading = false,
  tabIndex = "0",
  buttonProps = {},
  hasMenuOpened = false,
  tooltipText = "",
  tooltipTop = undefined,
  tooltipLeft = undefined,
  customStyle = undefined,
  caretBottom = undefined,
  customClassName = {},
  replaceTopWithBottom = undefined,
  caretTop = undefined,
  alignItemAbove = false,
  blackSmallLoader = false,
  className = "",
}) => {
  const reduxLoading = useSelector(({ forms }) => forms.formData.loadingButton)
  const loadingValue = loading || (hasLoading && reduxLoading)

  return (
    <SLButton
      variant={variant}
      type={type}
      onClick={onClick}
      disabled={disabled}
      icon={icon}
      hasMenuIcon={hasMenuIcon}
      margin={margin}
      center={center}
      loading={loadingValue}
      tabIndex={tabIndex}
      buttonProps={buttonProps}
      hasMenuOpened={hasMenuOpened}
      tooltipText={tooltipText}
      tooltipTop={tooltipTop}
      tooltipLeft={tooltipLeft}
      customStyle={customStyle}
      caretBottom={caretBottom}
      customClassName={customClassName}
      replaceTopWithBottom={replaceTopWithBottom}
      caretTop={caretTop}
      alignItemAbove={alignItemAbove}
      blackSmallLoader={blackSmallLoader}
      className={className}
      onButtonPressed={getButtonAriaLabel => userBehaviourUtils.buttonPressed(getButtonAriaLabel)}
    >
      {children}
    </SLButton>
  )
}

// Button.propTypes = {
//   variant: PropTypes.oneOf(Object.values(ButtonVariant)),
//   type: PropTypes.string,
//   onClick: PropTypes.func,
//   children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]).isRequired,
//   disabled: PropTypes.bool,
//   icon: PropTypes.string,
//   hasMenuIcon: PropTypes.bool,
//   margin: PropTypes.string,
//   center: PropTypes.bool,
//   loading: PropTypes.bool,
//   hasLoading: PropTypes.bool,
//   tabIndex: PropTypes.string,
//   buttonProps: PropTypes.instanceOf(Object),
//   hasMenuOpened: PropTypes.bool,
//   tooltipText: PropTypes.string,
//   tooltipTop: PropTypes.number,
//   tooltipLeft: PropTypes.number,
//   customStyle: PropTypes.instanceOf(Object),
//   caretBottom: PropTypes.bool,
//   customClassName: PropTypes.instanceOf(Object),
//   replaceTopWithBottom: PropTypes.bool,
//   caretTop: PropTypes.bool,
//   alignItemAbove: PropTypes.bool,
//   blackSmallLoader: PropTypes.bool,
//   className: PropTypes.string,
// }

export default Button
export { ButtonVariant }
