import "overlayscrollbars/styles/overlayscrollbars.css"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import "react-toastify/dist/ReactToastify.css"
import "./global.scss"
import "./primitiveColours.css"
import "./sizing.scss"
import "./theme.css"
import "./whitelabeltheme.css"

import { LoadingPage, ToastContainerWrapper } from "@skylead/component-library"
import axios from "axios"
import React, { Suspense } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import App from "./js/components/App"
import ScrollToTop from "./js/components/atoms/ScrollToTop"
import InfoModal from "./js/components/organisms/InfoModal"
import { store } from "./js/redux/store"
import { setTheme } from "./js/utils/app-utils"
import I18nInitializer from "./js/utils/i18n"
import userBehaviourUtils from "./js/utils/userBehaviour-utils"

axios.defaults.withCredentials = true
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest"

setTheme()

userBehaviourUtils.initializeEventListeners()

const root = createRoot(document.getElementById("root"))

root.render(
  <Suspense fallback={<LoadingPage />}>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <I18nInitializer language="en" />
          <App />
          <ToastContainerWrapper />
          <InfoModal />
          <div id="portal" />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </Suspense>,
)
