const lettersArray = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
]

const AB_TESTING_TABS_COUNT = 5

const AB_TESTING_ELEMENTS = ["connect", "message", "email", "inMail"]

const specificLinkedInSettingsConstants = tt => [
  {
    name: tt("specific.linkedin.settings.premium-account.name"),
    field: "onlyPremium",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline"],
    subtitle: tt("specific.linkedin.settings.premium-account.msg"),
  },
  {
    name: tt("specific.linkedin.settings.lead-profile-status.name"),
    field: "onlyOpenInmail",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline"],
    subtitle: tt("specific.linkedin.settings.lead-profile-status.msg"),
  },
  {
    name: tt("collect-contact-information"),
    field: "getPersonalInfo",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
    subtitle: tt("specific.linkedin.settings.contact-information.name"),
  },
  {
    name: tt("specific.linkedin.settings.remove-lead.name"),
    field: "noPendingConnections",
    showFor: ["1", "2"],
    subtitle: tt("specific.linkedin.settings.remove-lead.msg"),
  },
  {
    name: tt("specific.linkedin.settings.refresh-search.name"),
    field: "autoReuse",
    showFor: ["1", "2", "recruiter", "post-engagement", "recruiter-pipeline", "leads-list"],
    subtitle: tt("specific.linkedin.settings.refresh-search.msg"),
  },
]

const targetingOptionsConstants = tt => [
  {
    name: tt("targeting-options-constants.include-replied-lead.name"),
    field: "sendPrevious",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline", "import", "csv", "leads-list"],
    subtitle: tt("targeting-options-constants.include-replied-lead.msg"),
  },
  {
    name: tt("targeting-options-constants.other-campaign-lead.name"),
    field: "includeGloballyTargetedLeads",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
    subtitle: tt("targeting-options-constants.other-campaign-lead.msg"),
  },
  {
    name: tt("targeting-options-constants.new-lead.name"),
    field: "onlyUniqueLeads",
    showFor: ["1", "2", "recruiter", "recruiter-pipeline", "post-engagement", "leads-list"],
    subtitle: tt("targeting-options-constants.new-lead.msg"),
  },
]

const emailTrackingPreferenceConstants = tt => [
  {
    name: tt("track-email-link-clicks"),
    field: "trackEmailsClicks",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
    subtitle: tt("email.tracking.preference.constants.track.subtitle"),
  },
  {
    name: tt("track-opened-emails"),
    field: "trackEmailsOpens",
    showFor: [
      "1",
      "2",
      "recruiter",
      "recruiter-pipeline",
      "import",
      "csv",
      "post-engagement",
      "leads-list",
    ],
    subtitle: tt("email.tracking.preference.constants.email-open.subtitle"),
  },
  {
    name: tt("send-text-only-emails"),
    field: "textOnlyEmails",
    showFor: [
      "1",
      "2",
      "post-engagement",
      "recruiter",
      "leads-list",
      "recruiter-pipeline",
      "import",
      "csv",
    ],
    subtitle: tt("email.tracking.preference.constants.only-emails.subtitle"),
  },
]

const defaultAutoReuseInterval = 30

const maxFileSize = 6 * 1024 * 1024

const CAMPAIGN_TYPE = {
  BASIC: "BASIC",
  SALES_NAVIGATOR: "SALES_NAVIGATOR",
  CSV: "CSV",
  POST_ENGAGEMENT: "POST_ENGAGEMENT",
  LEADS_LIST: "LEADS_LIST",
  RECRUITER: "RECRUITER",
}

const CAMPAIGN_DASHBOARD = {
  BASIC: "1",
  SALES_NAVIGATOR: "2",
  CSV: "import",
  POST_ENGAGEMENT: "post-engagement",
  LEADS_LIST: "leads-list",
  RECRUITER: ["recruiter", "recruiter-pipeline"],
}

const defaultHoursIfEmailOpened = 8

const campaignConstants = tt => ({
  emailTrackingPreferenceConstants: emailTrackingPreferenceConstants(tt),
  targetingOptionsConstants: targetingOptionsConstants(tt),
  specificLinkedInSettingsConstants: specificLinkedInSettingsConstants(tt),
})

export default campaignConstants

export {
  CAMPAIGN_TYPE,
  lettersArray,
  AB_TESTING_TABS_COUNT,
  AB_TESTING_ELEMENTS,
  defaultAutoReuseInterval,
  maxFileSize,
  CAMPAIGN_DASHBOARD,
  defaultHoursIfEmailOpened,
}
