import queryString from "query-string"
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import userService from "../../services/user-service"
import config from "../../utils/config"
import userBehaviourUtils from "../../utils/userBehaviour-utils"
import { clearForm, clearFormErrors, setFormErrors } from "../forms/formsActions"

function userLogin(tt, email, password, recaptcha, hash, navigateTo) {
  return async dispatch => {
    try {
      dispatch(setFormErrors({ msg: "" }))
      const iframe = localStorage.getItem("iframe") === "true"
      const login = await userService.loginUser(email, password, recaptcha, hash, iframe)
      const loginResult = login.data.result
      if (loginResult.is2FAActive) {
        return loginResult
      }
      userBehaviourUtils.userLogin({ email })
      dispatch(clearForm())
      dispatch(clearFormErrors())
      const params = queryString.parse(window.location.search)
      navigateTo(params.next || "/")
    } catch (error) {
      const errorMessages = []
      if (error?.response?.status === 401) {
        dispatch(setFormErrors({ msg: tt("wrong-credentials") }))
      } else if (error?.response && error.response?.data && error.response?.data?.errors) {
        Object.values(error.response.data.errors).map(err => errorMessages.push(err.msg))
        dispatch(setFormErrors({ msg: errorMessages }))
      } else {
        dispatch(setFormErrors({ msg: tt("something-went-wrong") }))
      }
    }
  }
}

function registerUserSuccess(userData) {
  return { type: ACTIONS.USER_REGISTER, userData }
}

function registerUser(tt, email, password, fullName, companyName, phone, hash, navigate) {
  return async dispatch => {
    try {
      dispatch(setFormErrors({ msg: "" }))
      const invitationID = config.REACT_APP_SECURED_REGISTRATION
        ? localStorage.getItem("invitationID") || undefined
        : undefined

      const userData = await userService.registerUser(
        email,
        password,
        fullName,
        companyName,
        phone,
        invitationID,
        hash,
      )
      if (invitationID) {
        localStorage.removeItem("invitationID")
      }
      await dispatch(registerUserSuccess(userData.data.result))
      userBehaviourUtils.registrationCompleted(userData.data.result)
      dispatch(clearForm())
      dispatch(clearFormErrors())
      navigate("/", { replace: true })
    } catch (error) {
      if (error.response.status === 409) {
        dispatch(
          setFormErrors({
            msg: tt("register-user.error.msg"),
          }),
        )
      }
      const errorMessages = []
      if (error.response && error.response.data && error.response.data.errors) {
        Object.values(error.response.data.errors).map(err => errorMessages.push(err.msg))
        dispatch(setFormErrors({ msg: errorMessages }))
      }
    }
  }
}

function logoutUserSuccess() {
  return { type: ACTIONS.USER_LOGOUT }
}

function logoutUser(navigate) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const selectedTheme = localStorage.getItem("theme")
      localStorage.clear()
      localStorage.setItem("theme", selectedTheme)
      navigate("/login", { replace: true })
      await userService.logoutUser(userID)
      dispatch(logoutUserSuccess())
      userBehaviourUtils.sessionEnded()
    } catch (err) {
      if (err.code === 401) {
        dispatch(logoutUserSuccess())
        localStorage.clear()
        navigate("/login", { replace: true })
        userBehaviourUtils.sessionEnded()
      }
    }
  }
}

function changePassword(tt, data) {
  return async (_, getState) => {
    try {
      const userID = getState().user.profile.id

      await userService.changePassword(userID, data)
      toast.success(tt("password-successfully-changed"))
    } catch (error) {}
  }
}

function resetPassword(tt, email) {
  return async () => {
    try {
      await userService.resetPassword(email)
      toast.success(tt("email-successfully-sent"))
      return true
    } catch (error) {
      return false
    }
  }
}

function setPassword(tt, password, passwordResetToken) {
  return async () => {
    try {
      await userService.setPassword({ password, passwordResetToken })
      toast.success(tt("set-password.msg"))
      return true
    } catch (error) {
      return false
    }
  }
}

export {
  userLogin,
  registerUser,
  logoutUser,
  logoutUserSuccess,
  changePassword,
  resetPassword,
  setPassword,
}
