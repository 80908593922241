import * as actions from "../redux/actions"
import { store } from "../redux/store"
import config from "./config"
import { getUserType, USER_TYPES } from "./team-management/team-management-utils"

const isNotSkylead = () => {
  const { whitelabelId } = store.getState().user.profile

  if (typeof whitelabelId !== "undefined") {
    return whitelabelId !== 1
  }

  return true
}

const isNotSkyleadFromENV = () => {
  const whitelabelId = config.REACT_APP_PRODUCT_ID
  if (typeof whitelabelId !== "undefined") {
    return whitelabelId !== "1"
  }

  return true
}

const isAdmin = () => {
  const { userTypeId } = store.getState().user.profile

  return [USER_TYPES.SUPER_ADMIN, USER_TYPES.PLATFORM_CO_OWNER].includes(getUserType(userTypeId))
}

const isWhiteLabelNoAdmin = () => {
  const { whitelabelId } = store.getState().user.profile

  if (isAdmin()) {
    return false
  }

  return whitelabelId !== 1
}

const hideBilling = (userId = store.getState().user?.profile?.id) => {
  try {
    return (
      JSON.parse(config.REACT_APP_HIDE_BILLING_ACCOUNT_IDS).includes(userId) ||
      store.getState().user?.profile.isSubadmin
    )
  } catch (error) {}
}

const addThemeCss = url => {
  return new Promise(resolve => {
    const { head } = document
    const link = document.createElement("link")

    link.type = "text/css"
    link.rel = "stylesheet"
    link.href = url

    head.appendChild(link)

    link.onload = () => {
      resolve()
    }
  })
}

const addWhitelabelENV = envUrl => {
  return new Promise(resolve => {
    const { head } = document
    const script = document.createElement("script")
    script.src = envUrl
    script.id = "wl-env"

    head.appendChild(script)

    script.onload = () => {
      resolve()
    }
  })
}

const gitHubHeaders = {
  headers: {
    Authorization: "token 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",
    Accept: "application/vnd.github+json",
  },
}

const getWhitelabelFileFromGitHub = async (whitelabelFolderUrl, fileName) => {
  const selectedWhitelabel = await fetch(
    `${whitelabelFolderUrl}/${fileName}?ref=redesign`,
    gitHubHeaders,
  ).then(res => res.json())

  return selectedWhitelabel.download_url
}

const loadExternalENVUrl = async (url, identifier = "whitelabelUrl") => {
  if (url) {
    localStorage.setItem(identifier, url)
    localStorage.setItem(`${identifier}Timestamp`, Date.now())
    const cssUrl = await getWhitelabelFileFromGitHub(url, "whitelabeltheme.css")
    const envUrl = await getWhitelabelFileFromGitHub(url, "react-env.js")
    await addThemeCss(cssUrl)
    await addWhitelabelENV(envUrl)
  } else {
    const savedThemeUrlTimestamp = localStorage.getItem(`${identifier}Timestamp`)
    if (savedThemeUrlTimestamp) {
      const now = new Date()
      const msBetweenDates = Math.abs(savedThemeUrlTimestamp - now.getTime())

      const minutesBetweenDates = msBetweenDates / (60 * 1000)
      if (minutesBetweenDates < 30) {
        const savedThemeUrl = localStorage.getItem("savedThemeUrl")
        await addThemeCss(savedThemeUrl)
        await addWhitelabelENV(`${url}/react-env.js`)
      } else {
        localStorage.removeItem(identifier)
        localStorage.removeItem(`${identifier}Timestamp`)
      }
    }
  }

  await store.dispatch(actions.changeWhitelabelEnv(url))
}

const loadExternalENVUrlOldWay = async (url, identifier = "whitelabelUrl") => {
  if (url) {
    localStorage.setItem(identifier, url)
    localStorage.setItem(`${identifier}Timestamp`, Date.now())
    await addThemeCss(`${url}/whitelabeltheme.css`)
    await addWhitelabelENV(`${url}/react-env.js`)
  } else {
    const savedThemeUrlTimestamp = localStorage.getItem(`${identifier}Timestamp`)
    if (savedThemeUrlTimestamp) {
      const now = new Date()
      const msBetweenDates = Math.abs(savedThemeUrlTimestamp - now.getTime())

      const minutesBetweenDates = msBetweenDates / (60 * 1000)
      if (minutesBetweenDates < 30) {
        const savedThemeUrl = localStorage.getItem("savedThemeUrl")
        await addThemeCss(savedThemeUrl)
        await addWhitelabelENV(`${url}/react-env.js`)
      } else {
        localStorage.removeItem(identifier)
        localStorage.removeItem(`${identifier}Timestamp`)
      }
    }
  }

  await store.dispatch(actions.changeWhitelabelEnv(url))
}

const WHITELABEL_PERMISSIONS = {
  INBOX: { key: "INBOX", featureName: "INBOX" },
  ANALYTICS: { key: "ANALYTICS", featureName: "ANALYTICS" },
  WEBHOOKS: { key: "WEBHOOKS", featureName: "WEBHOOKS" },
  CAMPAIGN_SALES: { key: "CAMPAIGN_SALES", featureName: "CAMPAIGN_SALES" },
  CAMPAIGN_CSV: { key: "CAMPAIGN_CSV", featureName: "CAMPAIGN_CSV" },
  CAMPAIGN_POST_ENGAGEMENT: {
    key: "CAMPAIGN_POST_ENGAGEMENT",
    featureName: "CAMPAIGN_POST_ENGAGEMENT",
  },
  CAMPAIGN_POST_LEAD_LIST: {
    key: "CAMPAIGN_POST_LEAD_LIST",
    featureName: "CAMPAIGN_POST_LEAD_LIST",
  },
  BLACKLIST: { key: "BLACKLIST", featureName: "BLACKLIST" },
  CAMPAIGN_RECRUITER: { key: "CAMPAIGN_RECRUITER", featureName: "CAMPAIGN_RECRUITER" },
  LINKEDIN_STREAM: { key: "LINKEDIN_STREAM", featureName: "LINKEDIN_STREAM" },
  FEATURE_SUGGESTIONS: { key: "FEATURE_SUGGESTIONS", featureName: "FEATURE_SUGGESTIONS" },
  TEAM_MANAGEMENT: { key: "TEAM_MANAGEMENT", featureName: "TEAM_MANAGEMENT" },
  CANCEL_SUBSCRIPTION: { key: "CANCEL_SUBSCRIPTION", featureName: "CANCEL_SUBSCRIPTION" },
  DELETE_SEAT: { key: "DELETE_SEAT", featureName: "DELETE_SEAT" },
  EMAIL_WARMUP: { key: "EMAIL_WARMUP", featureName: "EMAIL_WARMUP" },
}

const getUserWhitelabelPermissions = () => {
  const { userTypeId } = store.getState().user.profile
  const userType = getUserType(userTypeId)
  const isUserAdmin = [USER_TYPES.PLATFORM_CO_OWNER, USER_TYPES.SUPER_ADMIN].includes(userType)
  let userWhitelabelPermissions = [...Object.keys(WHITELABEL_PERMISSIONS)]
  const { whitelabelPermissions } = store.getState().app
  whitelabelPermissions.forEach(whitelabelPermission => {
    if (
      !whitelabelPermission.active ||
      (whitelabelPermission.active && whitelabelPermission.adminOnly && !isUserAdmin)
    ) {
      const foundPermission = Object.values(WHITELABEL_PERMISSIONS).find(
        permission => permission.key === whitelabelPermission.featureName,
      )
      userWhitelabelPermissions = userWhitelabelPermissions.filter(
        userWhitelabelPermission => userWhitelabelPermission !== foundPermission?.key,
      )
    }
  })

  return userWhitelabelPermissions
}

const hasWhitelabelPermission = whitelabelPermission => {
  const userWhitelabelPermissions = getUserWhitelabelPermissions()
  // TODO Any@Any: Mutation testing issue here!
  return !!userWhitelabelPermissions.find(
    userWhitelabelPermission => userWhitelabelPermission === whitelabelPermission.key,
  )

  // When do tests pass!
  // return !!userWhitelabelPermissions.find(
  //   userWhitelabelPermission => userWhitelabelPermission !== whitelabelPermission.key,
  // )

  // Potencial fix
  // return userWhitelabelPermissions.includes(whitelabelPermission.key)
}

export {
  isNotSkylead,
  isNotSkyleadFromENV,
  isWhiteLabelNoAdmin,
  isAdmin,
  hideBilling,
  addThemeCss,
  addWhitelabelENV,
  loadExternalENVUrl,
  getWhitelabelFileFromGitHub,
  loadExternalENVUrlOldWay,
  WHITELABEL_PERMISSIONS,
  hasWhitelabelPermission,
  getUserWhitelabelPermissions,
}
