import { createBrowserHistory } from "history"
import { applyMiddleware, compose, createStore } from "redux"
import thunk from "redux-thunk"

import ACTIONS from "../constants/ACTIONS"
import { initialState as mockedInitialState } from "../mocks/data"
import { getNewReduxState } from "../tests/helpers/global-helpers"
import reducer from "./reducers"

export const history = createBrowserHistory()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const allActions = []

const actionLogger = () => next => action => {
  allActions.push(action)
  return next(action)
}
export function configureStore(initialState) {
  const store = createStore(
    reducer(history),
    initialState,
    composeEnhancers(applyMiddleware(thunk, actionLogger)),
  )
  return store
}

export function getActionHistory() {
  return allActions
}

export const store = configureStore()

// TODO PP@Any: Delete this function after it is replaced by setInitialReduxStoreState()
export const setInitialStoreState = initialStateFromTest => {
  store.dispatch({
    type: ACTIONS.CHANGE_ACCOUNT_STATE,
    payload: {
      ...mockedInitialState.account,
      ...(initialStateFromTest?.account || {}),
    },
  })

  store.dispatch({
    type: ACTIONS.CHANGE_USER_STATE,
    payload: {
      ...mockedInitialState.user,
      ...(initialStateFromTest?.user || {}),
    },
  })

  store.dispatch({
    type: ACTIONS.CHANGE_APP_STATE,
    payload: {
      ...mockedInitialState.app,
      ...(initialStateFromTest?.app || {}),
    },
  })

  store.dispatch({
    type: ACTIONS.CHANGE_CAMPAIGN_STATE,
    payload: {
      ...mockedInitialState.campaign,
      ...(initialStateFromTest?.campaign || {}),
    },
  })

  store.dispatch({
    type: ACTIONS.CHANGE_EMAIL_STATE,
    payload: {
      ...mockedInitialState.email,
      ...(initialStateFromTest?.email || {}),
    },
  })

  store.dispatch({
    type: ACTIONS.CHANGE_TEAM_MANAGEMENT_STATE,
    payload: {
      ...mockedInitialState.teamManagement,
      ...(initialStateFromTest?.teamManagement || {}),
    },
  })

  store.dispatch({
    type: ACTIONS.CHANGE_FORMS_STATE,
    payload: {
      ...mockedInitialState.forms,
      ...(initialStateFromTest?.forms || {}),
    },
  })

  store.dispatch({
    type: ACTIONS.CHANGE_IMAGE_PERSONALIZATION_STATE,
    payload: {
      ...mockedInitialState.imagePersonalization,
      ...(initialStateFromTest?.imagePersonalization || {}),
    },
  })
}

const CHANGE_REDUCER_STATE_ACTIONS = {
  app: ACTIONS.CHANGE_APP_STATE,
  user: ACTIONS.CHANGE_USER_STATE,
  account: ACTIONS.CHANGE_ACCOUNT_STATE,
  forms: ACTIONS.CHANGE_FORMS_STATE,
  payment: ACTIONS.CHANGE_PAYMENT_STATE,
  campaign: ACTIONS.CHANGE_CAMPAIGN_STATE,
  chat: ACTIONS.CHANGE_CHAT_STATE,
  imagePersonalization: ACTIONS.CHANGE_IMAGE_PERSONALIZATION_STATE,
  statistics: ACTIONS.CHANGE_STATISTICS_STATE,
  teamManagement: ACTIONS.CHANGE_TEAM_MANAGEMENT_STATE,
  blacklist: ACTIONS.CHANGE_BLACKLIST_STATE,
  email: ACTIONS.CHANGE_EMAIL_STATE,
}

const updateReducer = (currentState, newState, reducerName) => {
  store.dispatch({
    type: CHANGE_REDUCER_STATE_ACTIONS[reducerName],
    payload: {
      ...currentState[reducerName],
      ...newState[reducerName],
    },
  })
}

export const setInitialReduxStoreState = (initialStateFromTest = {}) => {
  const currentState = store.getState()
  const newState = getNewReduxState(initialStateFromTest, currentState)

  for (const key of Object.keys(initialStateFromTest)) {
    updateReducer(currentState, newState, key)
  }
}
